<h2 class="top-heading" [class.ta-r]="isArabic">{{'Create_Emp_CSV' | translate}}</h2>

<!-- Download/Upload Section -->
<mat-card *ngIf="pagination.count == 0" class="card-margin" [class.ta-r]="isArabic">
    <h2 class="top-heading-card">{{'Upload_Emp_Onb_CSV' | translate}}</h2>
    <h4 style="color: #213060; font-weight: bold;">{{'This utility function enables users to quickly add new employees by uploading a CSV with the minimum most essential details of Employees' | translate}}</h4>
    <h4>{{'Important Notes' | translate}}</h4>

    <p>{{'Emp_Onb_Ins_1' | translate}}</p>
    <p>{{'Emp_Onb_Ins_2' | translate}}</p>
    <p>{{'Emp_Onb_Ins_3' | translate}}</p>

    <div fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="10px" fxLayoutGap.xs="0">
        <a href="assets/employee_csv.csv" target="_blank" download="Download Sample CSV.csv">
            <!-- (click)="downloadCSV()" -->
            <div fxFlex.xs="100" class="btn-big" fxLayout="column" fxLayoutAlign="none center">
                <img src="../../../../../../assets/download.svg">
                <h4 class="c-white m-0 t-center">{{'Download_Sample_CSV' | translate}}</h4>
            </div>
        </a>
        <div (click)="uploadCSV()" fxFlex.xs="100" class="btn-big" fxLayout="column" fxLayoutAlign="none center">
            <input #attach type="file" style="display: none;" (change)="getFile($event)">
            <img src="../../../../../../assets/upload.svg">
            <h4 class="c-white m-0 t-center">{{'Upload_Employees_CSV' | translate}}</h4>
        </div>
    </div>
</mat-card>

<!-- Listing Section -->
<ng-container *ngIf="pagination.count > 0">

    <!-- Validation Section -->
    <mat-card [class.ta-r]="isArabic" class="card-margin" fxLayout="row wrap">
        <h2 fxFlex="53" fxFlex.xs="100" style="margin: 0; font-weight: bold; color: #213060;">{{'Emp_Onb_Validation' | translate}}</h2>
        <div fxFlex="47" fxFlex.sm="48" fxFlex.xs="100" fxLayoutAlign="end center">
            <button (click)="getEmployeesForDownload()" mat-raised-button style="color: white; border-radius: 18px; cursor: pointer;
            font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none; background-color: #213060;">
                {{'Download_as_CSV' | translate}}
            </button>
            <button (click)="discardContributions()" mat-raised-button style="background-color: #213060;
            color: #213060; border-radius: 18px; cursor: pointer;
            font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none;
            background-color: #E9EAEF;">
                {{'Cancel_Onb' | translate}}
            </button>
            <button [ngStyle]="{'background-color': hasError ? '#E9EAEF' : '#213060'}" [style.color]="hasError ? '#213060' : 'white'" [disabled]="hasError" (click)="submitEmployees()" mat-raised-button 
                style="border-radius: 18px; cursor: pointer;
                font-size: 12px; border: none; line-height: 25px; padding: 0 10px; margin-inline: 10px; outline: none;">
                {{'Create_Emps' | translate}}
            </button>
        </div>
    
        <h4 fxFlex.xs="100" fxFlex.sm="100" fxFlex="37" style="margin: 0; font-weight: bold; color: #213060; margin-top: 10px; margin-right: 15px;">{{'Please_review_employee_data' | translate}}</h4>
            <app-card fxFlex="20" fxFlex.sm="48" fxFlex.xs="100" img="assets/images/person-arrows.png" topText="{{'Total_Employees' | translate}}"
                    middleText="{{summary.total}} {{summary.total > 1 ? ('Employees' | translate) : ('Employee' | translate)}}" bottomText="" [error]="false">
            </app-card>
            <app-card fxFlex="20" fxFlex.sm="48" fxFlex.xs="100" img="assets/images/person-arrows.png" topText="{{'Records_with_Errors' | translate}}"
                    middleText="{{summary.errors ? summary.errors : '0'}} {{'Records' | translate}}" bottomText="" [error]="summary.errors > 0 ? true : false">
            </app-card>
            <app-card fxFlex="21" fxFlex.sm="48" fxFlex.xs="100" img="assets/images/person-arrows.png" topText="{{'Employee_File_Status' | translate}}"
                    middleText="{{summary.ok == summary.total ? ('No Errors Detected' | translate) : ('Errors Detected' | translate)}}" bottomText="" [error]="summary.errors > 0 ? true : false">
            </app-card>
    </mat-card>
    
    <!-- Table -->
    <!-- <mat-card style="margin: 20px 10px;" fxLayout="row wrap">
        <div fxFlex="40" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="start center">
            <h3 style="margin: 0; font-weight: bold; color: #213060;">Manage Employee</h3>
        </div>
        <div fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign="end center" fxLayoutAlign.sm="start center" fxLayoutAlign.xs="start center">
            <button (click)="deleteSelected()" *ngIf="checkBoxCount" style="background-color: #213060; color: white; border-radius: 10px; outline: none;
                line-height: 35px; font-size: 12px;" mat-raised-button>Delete Selected
            </button>
            <button (click)="expandAll()"
                [class.error-selected]="!allExpanded"
                [class.error-unselected]="allExpanded"
                style="border-radius: 10px; outline: none;
                line-height: 35px; font-size: 12px;" mat-raised-button>Expand All Rows
            </button>
            <button (click)="filterErrors()"
                [class.error-selected]="!showErrorSelected"
                [class.error-unselected]="showErrorSelected"
                style="border-radius: 10px; outline: none;
                line-height: 35px; font-size: 12px;" mat-raised-button>Show rows with errors only
            </button>
            <button (click)="openDialog()" style="background-color: #F7F8F9; border-radius: 10px; outline: none;
                line-height: 35px; font-size: 12px;" mat-raised-button><mat-icon style="font-family: 'Material Icons' !important;">add</mat-icon> New Row
            </button>
        </div>
        <app-table #table fxFlex="100" 
            [tableProps]="tablePropsEmp"
            [dataSource]="employees"
            [pagination]="pagination"
            isExpandable="true"
            (setPage)="setPage($event)"
            (actionClicked)="actionClicked($event)"
            (emitCheckBox)="checkBox($event)">
        </app-table>
    </mat-card> -->
    <div class="card-margin" style="margin-top: 20px; margin-bottom: 20px;">
        <app-table #table
            [tableProps]="tablePropsEmp"
            [dataSource]="employees"
            [pagination]="pagination"
            isExpandable="true"
            (setPage)="setPage($event)"
            (actionClicked)="actionClicked($event)"
            (outPutHeaders)="headerButton($event)"
            (emitCheckBox)="checkBox($event)">
        </app-table>
    </div>
</ng-container>