import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { FormGroup, Validators, FormBuilder, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { MainService } from 'src/app/services/main.service';
import { DecEncService } from 'src/app/services/dec-enc';
import * as moment from 'moment';
import * as printJS from 'print-js';
import { appConfig } from 'src/config';
@Component({
    selector: 'app-manage-organization-form',
    templateUrl: './manage-organization-form.component.html',
    styleUrls: ['./manage-organization-form.component.scss']
})
export class ManageOrganizationFormComponent implements OnInit {
    empIdentificationForm: FormGroup;
    adminForm: FormGroup;
    comercialRegistrationForm: FormGroup;
    addressDetailsForm: FormGroup;
    bankDetailForm: FormGroup;
    empRepresentativeDetailForm: FormGroup;
    investPolicyForm: FormGroup;
    investAdvisorForm: FormGroup;
    genrelWriteUpForm: FormGroup;
    documentListForm: FormGroup;
    outlookForm: FormGroup;
    billingForm: FormGroup;
    images: any = []
    comment: any;
    imageA: any = '';
    imageB: any = '';
    imageC: any = '';
    emp_id: any;
    visible: boolean = true;
    selectable: boolean = true;
    removable: boolean = true;
    addOnBlur: boolean = true;
    emp: any = true;
    selected: any = '1';
    listcon: any;
    listnaic: any;
    merchantsCount: any;
    Merchants: any;
    image: any;
    logo_image = `assets/images/dummy_cl.svg`;
    tooltipOne = `You're billed monthly for each user account. You can add and remove accounts at any time and pay only for the accounts you use during that month.`;
    tooltipTwo = `You will be charged for all users that have been assigned a license. Monthly charges will be prorated based on when a user is assigned a license. This may result in the charges on your invoice varying month to month.`;
    tooltipThree = `This is the estimated amount that you will be charged  on your next billing date.`;
    tooltipFour = `Lorem ipsum dolor sit amet consectetur. Sed pharetra at laoreet sed arcu a pharetra sed iaculis.`;

    id: any;
    hed1: any;
    currentIndexOfStepper: number = 0;
    companyName = new FormControl();
    industry = new FormControl();
    cr_license = new FormControl();
    cr_license_expiry = new FormControl();

    new_id = null;
    constructor(public tr: TranslationService,  protected router: Router, protected formbuilder: FormBuilder, protected _route: ActivatedRoute, public mainService: MainService, public dec_enc: DecEncService, protected dialog: MatDialog) {

        this._route.params.subscribe(params => {
            this.id = Number(params['id']);
            //console.log(this.id);
            if (this.id) {
                this.hed1 = "Update Employers";
                this.emp_id = this.id;
                this.emp = false;
                this.getdata();
            }
            else {
                this.hed1 = "Create Employers"
                this.getdata();

            }

        });

        this.empIdentificationForm = this.formbuilder.group({
            company_id: [''],
            company_name: [''],
            company_name_arabic: [''],

            preferred_language: [''],
            no_of_employees: [''],

            industry_classification: [''],

            type_of_activity: [''],
            country_of_activity: [''],
            other_country_of_activities: [''],

            name_of_other_countries: [''],
            portfolio_currency: [''],

            is_this_listed_company: [''],
            address: [''],
            active: ['0'],

        });
        this.billingForm = this.formbuilder.group({
            
        });
        this.adminForm = this.formbuilder.group({
            name: [''],
            phone: [''],
            email: [''],

            iqama_id: [''],
            iqama_expiry: [''],

            role: ['employer'],

            password: [''],
            confirm_password: [''],
            additional_notes: [],
            active: ['0'],

        });

        this.comercialRegistrationForm = this.formbuilder.group({
            cr_no: [''],
            cr_issue_place: [''],
            cr_issue_date: [''],

            cr_expiry_date: [''],
            cr_date_of_incorporation: [''],

            cr_country_of_first_registration: [''],

            cr_taxation_identification_number: [''],
            cr_global_intermediary_identification_number: [''],
            cr_unified_no: [''],

            active: ['0'],

        });

        this.empRepresentativeDetailForm = this.formbuilder.group({
            // name: ['', [Validators.required]],
            first_name: [''],
            middle_name: [''],
            last_name: [''],
            contact_phone: [''],
            contact_email: [''],
            position_in_company: [''],
            investment_bank_authorization: [''],
            preferred_mode_of_communication: [''],
            backup_contact_name: [''],
            backup_contact_phone: [''],
            backup_contact_email: [''],
            backup_contact_position: [''],
            active: ['0'],

        });
        this.bankDetailForm = this.formbuilder.group({
            // name: ['', [Validators.required]],
            beneficiary_name: [''],
            account_number: [''],
            iban_number: [''],
            swift_number: [''],
            bank_name: [''],
            cont_beneficiary_name: [''],
            cont_account_number: [''],
            cont_iban_number: [''],
            cont_swift_number: [''],
            cont_bank_name: [''],
            bank_address: [''],
            active: ['0'],

        });
        this.documentListForm = this.formbuilder.group({
            documents: this.formbuilder.array([]),

        });
        this.outlookForm = this.formbuilder.group({

            global_market_overview: [''],
            global_market_key_drivers: [''],
            global_market_threats: [''],
            ksa_market_overview: [''],
            ksa_market_key_drivers: [''],
            ksa_market_threats: [''],
            active: ['0'],

        });


    }

    ngOnInit(): void {
        this.getPackage();
        this.getInvoices();
    }


    fornext(nav) {

        this.selected = nav;

    }
    next(id: any) {

    }

    getdata() {
        let url = 'home/manage-organization';
        let employer_id = this.id;
        console.log('this.id', this.id)
        let enc_payload = this.dec_enc.encryption({ employer_id });
        this.mainService.postData(url, { enc_payload }).then(result => {
            if (result.statusCode === 200) {

                let data = this.dec_enc.decryption(result.data);
                this.companyName.setValue(data.employer.company_name);
                this.industry.setValue(data.employer.industry_classification);
                if (data.commercial_registration) {
                    this.cr_license.setValue(data.commercial_registration.cr_no);
                    this.cr_license_expiry.setValue(data.commercial_registration.cr_expiry_date);
                }
                this.new_id = data.employer.id;
                console.log(data);
                let countries
                if (data.employer.name_of_other_countries != null) {
                    countries = data.employer.name_of_other_countries.split(",");

                }
                if (data.employer.self_onboard == '1' || data.employer.self_onboard == '2') {
                    if (data.commercial_registration) {
                        this.empIdentificationForm.get('company_id').setValue(data.commercial_registration.cr_no)
                    }
                }
                else {
                    this.empIdentificationForm.get('company_id').setValue(data.employer.company_id)
                }
                this.empIdentificationForm.get('company_name').setValue(data.employer.company_name)
                this.empIdentificationForm.get('company_name_arabic').setValue(data.employer.company_name_arabic)

                this.empIdentificationForm.get('preferred_language').setValue(data.employer.preferred_language)
                this.empIdentificationForm.get('no_of_employees').setValue(data.employer.no_of_employees)

                this.empIdentificationForm.get('industry_classification').setValue(data.employer.industry_classification)

                this.empIdentificationForm.get('type_of_activity').setValue(data.employer.type_of_activity)
                this.empIdentificationForm.get('country_of_activity').setValue(data.employer.country_of_activity)
                this.empIdentificationForm.get('other_country_of_activities').setValue(data.employer.other_country_of_activities)
                this.empIdentificationForm.get('name_of_other_countries').setValue(countries)
                this.empIdentificationForm.get('portfolio_currency').setValue(data.employer.portfolio_currency)

                this.empIdentificationForm.get('is_this_listed_company').setValue(data.employer.is_this_listed_company)
                this.empIdentificationForm.get('address').setValue(data.employer.address)
                this.empIdentificationForm.get('active').setValue(data.employer.active)
                this.comment = data.employer.additional_notes

                if (data.employer.company_logo)
                {
                    this.logo_image = this.mainService.file_url + data.employer.company_logo;
                }

                if (data.admin != null) {
                    this.adminForm.patchValue(data.admin);
                    if (data.admin.iqama_image)
                    {
                        this.image = this.mainService.file_url + data.admin.iqama_image
                    }
                }
                if (data.commercial_registration != null) {
                    this.comercialRegistrationForm.patchValue(data.commercial_registration);
                }

                if (data.bank_details != null) {
                    this.bankDetailForm.patchValue(data.bank_details);
                }
                if (data.representative != null) {
                    this.empRepresentativeDetailForm.patchValue(data.representative);
                }
                if (data.documents_check_list != null) {
                    this.images = data.documents_check_list;
                    //console.log('images = ', this.images);

                }
            }

            else if (result.status == 422) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = result.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (result.status == 412) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = result.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (result.status == 404) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = result.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (result.status == 406) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = result.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = result.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
        },
            error => {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = 'Internal Server error';
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            });
    }

    stepperNavigator(index, key)
    {
        // for (const key in this.stepperControl) {
        //     this.stepperControl[key] = false;
        // }
        this.currentIndexOfStepper = Number(index);
        // this.stepperControl[key] = true;
    }

    getnaic() {

        let url = 'lovs/list_naic'
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {

                let result;
                let data = res.data
                result = this.dec_enc.decryption(data);
                //console.log("result lovs/list_naic",result)
                this.listnaic = result.lovs_naic;


            }

            else if (res.statusCode == 422) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (res.error.statusCode == 412) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (res.statusCode == 404) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (res.statusCode == 406) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
        },
            error => {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = 'Internal Server error';
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            })
    }

    getcountries() {

        let url = 'lovs/list_countries'
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {

                let result;
                let data = res.data
                result = this.dec_enc.decryption(data);
                //console.log(result);

                this.listcon = result.lovs_countries;


            }

            else if (res.statusCode == 422) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (res.error.statusCode == 412) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (res.statusCode == 404) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else if (res.statusCode == 406) {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
            else {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = res.error.message;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            }
        },
            error => {
                let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                let cm = dialogRef.componentInstance;
                cm.heading = this.tr.translation.error;
                cm.message = this.tr.translation.serverError;
                cm.cancelButtonText = this.tr.translation.okay;
                cm.type = 'error';
            })
    }

    package = {
        number_of_employees: 0,
        payment_method: '',
        next_charge_amount: 0,
        frequency_rate: 0
    }
    getPackage()
    {
        let url = 'billing/package';
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200) {
                let result = this.dec_enc.decryption(response.data);
                if (result.package) {
                    this.package = result.package;
                }
                console.log('package', result);
            }
        })
    }

    invoices = [];
    invoicePagination = {
        page: 1
    };
    getInvoices()
    {
        let url = `billing/invoices?per_page=10&page=${this.invoicePagination.page}`;
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200) {
                let result = this.dec_enc.decryption(response.data);
                this.invoicePagination = result.pagination;
                if (result.invoices.length > 0) {
                    this.invoices = result.invoices;
                    this.invoices.forEach(item => {
                        
                        item['month'] = item['created_at'] ? moment(item['created_at']).format('MMMM') : 'N/A';
                        item['year'] = item['created_at'] ? moment(item['created_at']).format('YYYY') : 'N/A';
                        item['date'] = `${item['month']} ${item['year']}`;

                    })
                }
                console.log('invoices', result);
            }
        })
    }

    invoice: any = {
        employers_subscriptions: []
    };
    getInvoice(id)
    {
        let url = 'billing/invoice';
        let body = {
            invoice_id: id
        }
        let enc_payload = this.dec_enc.encryption(body);
        this.mainService.postData(url, {enc_payload}).then(response => {
            if (response.statusCode == 200) {
                let result = this.dec_enc.decryption(response.data);
                this.invoice = result.invoice;
                if (this.invoice.created_at) {
                    this.invoice.created_at = moment(this.invoice.created_at).format(appConfig.dateformatCMS);
                }
                if (this.invoice?.employers_subscriptions[0]?.next_invoice_date) {
                    this.invoice.employers_subscriptions[0].next_invoice_date = moment(this.invoice?.employers_subscriptions[0]?.next_invoice_date).format(appConfig.dateformatCMS);
                }
                setTimeout(() => {
                    this.onPrint();
                }, 2000);
                console.log('billing/invoice', result);
            }
        })
    }

    setPagination(page)
    {
        this.invoicePagination.page = page;
        this.getInvoices();
    }

    back() {
        window.history.back();
    }

    openImage(path) {
        if (path != null && path != '' && path != 'null') {
            window.open(this.mainService.file_url + path, '_blank');

        }
        else {
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = this.tr.translation.error;
            cm.message = "There is no image";
            cm.cancelButtonText = this.tr.translation.okay;
            cm.type = 'error';
        }
    }

    onStepChange(event) {
        this.currentIndexOfStepper = event.selectedIndex;
    }

    onNext(){
        if(this.currentIndexOfStepper < 5){
            this.currentIndexOfStepper++;
        }
        
    }

    onPrint(): void
    {
        const printContent = document.getElementById('print');
        // printContent.style.fontFamily = 'raleway';
        printJS({
            printable: printContent.innerHTML,
            type: 'raw-html',
            style: `@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap');`,
            font: `raleway;`
        });
    }

    onCancel(){
        this.router.navigate(['main/home']);
    }
}
