import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { appConfig } from 'src/config';
import * as printJS from 'print-js';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {


  isDateDisabled: boolean = true;
  list: any = [];
  Form: FormGroup;
  index: any = 1;
  searchTimer: any;
  search: any = '';
  role: any;
  minDate: any;
  currentDate = new Date();
  start_date: any;
  end_date: any;
  startForPrint: any;
  endForPrint: any;
  report_of: any = 'A';
  employee_id: any = null;
  report_of_type = 'All';

  tableProps = {
    heading: '',
    hasSearch: false,
    searchLabel: 'Search',
    hasButton: false,
    headerActions: [
      {
        label: 'New Report',
        type: 'output',
        visibility: true,
        isCustom: false,
        icon: 'add',
      },
    ],
    filterArray: [
      {
        label: 'Filter by Type',
        type: 'filter_list',
        key: 'report_of',
        visibility: true,
        selected: 'A',
        options: [
          {
            value: 'A',
            label: 'All',
            key: 'report_of',
          },
          {
            value: 'C',
            label: 'Contribution',
            key: 'report_of',

          },
          {
            value: 'W',
            label: 'Withdrawal',
            key: 'report_of',
          },
        ],
      },
      {
        label: 'Filter by Employee',
        toolTip: true,
        type: 'employee_list',
        key: 'employee',
        visibility: false,
        selected: 'all',
        options: [
          {
            value: 'all',
            label: 'All',
            key: 'employee',
          },
        ]
      },
    ],
    rowActions: [
      { label: 'Generate Summary', type: 'generateReport', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg' },
    ],
    colHeader: {
      id: 'ID',
      name: 'Name',
      request_from: 'Request From',
      created_at: 'Date',
      typeToDisplay: 'Type',
      amount: 'Amount',
    },
    columnTypes: {
      created_at: 'date',
    },
    dateFilter: {
      hasDatePicker: true,
      type: "dateRange",  // dateRange, date
      startLable: "Start_Date",
      endLable: "End_Date",
    }
  };

  pagination = {
    page: null,
    pages: null,
    per_page: null,
    count: 0
  }

  headerPrint = [
    { colHeader: 'ID', colKey: 'id', colType: '' },
    { colHeader: 'Name', colKey: 'name', colType: '' },
    { colHeader: 'Request From', colKey: 'request_from', colType: '' },
    { colHeader: 'Date', colKey: 'created_at', colType: '' },
    { colHeader: 'Type', colKey: 'typeToDisplay', colType: '' },
    { colHeader: 'Amount', colKey: 'amount', colType: 'number-4' },
  ];

  employee = 'All';
  iBnNo: any;
  accountNo: any;
  companyListing = 'all';
  showWalletDetails = false;

  constructor(public tr: TranslationService,
    public route: Router,
    protected mainService: MainService,
    public dec_enc: DecEncService,
    protected paginationService: PaginationService,
    public dialog: MatDialog,
    protected alertService: AlertService,) {
    this.Form = new FormGroup({
      start: new FormControl(),
      end: new FormControl()
    });

    this.start_date = moment(new Date()).startOf('month');
    this.start_date = new Date(this.start_date);
    this.startForPrint = moment(this.start_date).format(appConfig.dateformatCMS);
    this.end_date = moment(new Date()).endOf('month');
    this.end_date = new Date(this.end_date);
    this.endForPrint = moment(this.end_date).format(appConfig.dateformatCMS);

  }

  ngOnInit(): void {
    this.getDropdownData('employees?per_page=1000&page=1&sort_order=DESC');
    this.Form.controls['start'].valueChanges.subscribe(value => {
      if (value != null) {
        this.isDateDisabled = false
      }
    })
    if (this.companyListing != 'employees') {
      this.tableProps.filterArray[1].visibility = false;
      this.employee_id = null;
    }
    this.getall();
    this.getBankDetail();
  }

  setPage(page) {
    this.pagination.page = page;
    this.getall();
  }

  getall() {
    let data = {
      search: this.search ? this.search : null,
      start_date: moment(this.start_date).format(appConfig.dateformatServer),
      end_date: moment(this.end_date).format(appConfig.dateformatServer),
      report_of: this.report_of ? this.report_of : null,
      employee_id: this.employee_id ? this.employee_id : null,
      report_for: this.companyListing == 'company' ? 'C' : this.companyListing == 'employees' ? 'E' : 'A',
    };
    // console.log("data", data);
    let enc_payload = this.dec_enc.encryption(data);
    let url = 'reports/ucr';
    this.mainService.postData(url, { enc_payload }).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        // console.log("result", result);
        this.pagination = result.data.pagination;
        this.list = result.data;
        this.afterList();
      }
    },
      error => {
        this.alertService.alert('Error', 'Server Error');
      }
    )
  }

  afterList() {
    this.list.forEach(item => {
      item['generateReport'] = item.status == 0 ? true : false;
      item['created_at'] = moment(item['created_at']).format('DD-MM-YYYY');
      item['typeToDisplay'] = item.type == "widthdrawal" ? "Withdrawal" : "Contribution";

    });
  }

  actionClicked(event) {
    switch (event.action.label) {

      case 'Generate Summary':
        {
          break;
        }
    }
  }

  searchData(event) {
    this.search = event;
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.getall();
    }, 800);
  }

  onDateFilters(event) {
    if (event.type == "dateRange") {
      this.start_date = event['start'];
      this.end_date = event['end'];
      this.getall();
    }
    this.startForPrint = moment(this.start_date).format(appConfig.dateformatCMS);
    this.endForPrint = moment(this.end_date).format(appConfig.dateformatCMS);
  }

  getDropdownData(url) {
    this.tableProps.filterArray[1].options = [];
    let df = {
      value: 'all',
      label: 'All',
      key: 'employee',
    }
    this.tableProps.filterArray[1].options.push(df);
    this.mainService.postData(url, {}).then(res => {
      if (res.statusCode == 200) {
        let result = this.dec_enc.decryption(res.data);
        // console.log("getDropdownData", result);
        result.employees.forEach(item => {
          let dt = {
            value: item.id,
            label: `${item.name} (${item.email}/${item.iqama_id})`,
            key: 'employee',
          }
          this.tableProps.filterArray[1].options.push(dt);
        })
        return;
      }
      this.alertService.alertAr('Error', res.error);
    },
      error => {
        this.alertService.alertAr('Error', this.tr.translation.serverError);
      })
  }

  onPfd() {
    document.getElementById('print1').style.display = 'block';
    document.getElementById('print1').style.display = 'none';
    const printContent = document.getElementById("print1")
    printJS({ printable: printContent.innerHTML, type: 'raw-html' });
  }


  getBankDetail() {
    let url = 'home/manage-organization';
    let employer_id = localStorage.getItem('employer_id');
    let enc_payload = this.dec_enc.encryption({ employer_id });
    this.mainService.postData(url, { enc_payload }).then(result => {
      if (result.statusCode === 200) {
        let data = this.dec_enc.decryption(result.data);
        this.showWalletDetails = data.bank_details ? true : false;
        if (data.bank_details != null) {
          this.accountNo = data.bank_details.account_number;
          this.iBnNo = data.bank_details.iban_number;
        }
        return;
      }
      this.alertService.alertAr('Error', result.error);
    },
      error => {
        this.alertService.alertAr('Error', this.tr.translation.serverError);
      });
  }

  onChangeList(item) {
    this.companyListing = item;
    this.tableProps.filterArray[1].visibility = this.companyListing != 'employees' ? false : true;
    this.employee_id = this.companyListing != 'employees' ? null : this.employee_id;
    this.getall();
  }

  onFilters(event) {
    // console.log(event);
    if (event.key == 'report_of') {
      this.report_of = event.selected;
      if (event.selected == 'All') {
        this.report_of_type = 'All';
      }
      if (event.selected == 'W') {
        this.report_of_type = 'Withdrawal';
      }
      else {
        this.report_of_type = 'Contribution';
      }
    }
    else if (event.key == 'employee') {
      if (event.selected == 'all') {
        this.employee_id = null;
        this.employee = 'All';
      } else {
        event.options.forEach(element => {
          if (event.selected == element.value) {
            this.employee = element.label;
          }
        });
        this.employee_id = event.selected;
      }
    }
    this.getall();
  }
}
