import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { Label, Color } from 'ng2-charts';
import { ChartDataSets } from 'chart.js';
import * as moment from 'moment';
import { appConfig } from 'src/config';
import { TranslateService } from '@ngx-translate/core';
@Component({
    selector: 'app-employee-form-new',
    templateUrl: './employee-form-new.component.html',
    styleUrls: ['./employee-form-new.component.scss']
})
export class EmployeeFormNewComponent implements OnInit {

    Form: FormGroup;
    kinForm: FormGroup;
    planForm: FormGroup;
    taxForm: FormGroup;
    currentDate = new Date();
    currentIndexOfStepper: number = 0;
    id = null;
    routeType = null;
    imgToShow = null;
    iqama_image = null;
    stopStepper = false;
    @ViewChild('picker1') datePicker1: MatDatepicker<Date>;
    @ViewChild('picker2') datePicker2: MatDatepicker<Date>;
    @ViewChild('picker3') datePicker3: MatDatepicker<Date>;
    @ViewChild('picker4') datePicker4: MatDatepicker<Date>;

    messageStep = 'saved';
    messageEmp = 'created';
    fixedMatching = '';
    employeeSalary = null;

    public lineChartData: ChartDataSets[] = [
        { data: [12, 34 , 56], label: '2022-12-09, 2023-01-09, 2023-02-09' }
    ];
    lineChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    public lineChartOptions = {
        responsive: true,
        scales: {
            xAxes: [{
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                gridLines: {
                    display:false
                },
                ticks: {
                    userCallback: function(value, index, values) {
                        return  Number(value).toFixed(2);
                    }
                }   
            }]
        },        
        plugins: {
            tooltip: {
                backgroundColor: '#213060'
            },
        }
    };
    public lineChartLegend = false;
    lineChartColors: Color[] = [
        {
            borderColor: '#213060',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
        {
            borderColor: '#79BBBF',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
        {
            borderColor: '#CCCCCC',
            backgroundColor: 'rgba(43, 84, 123, 0.1)',
        },
    ];
    isArabic = false;
    calculationVars = {
        salary: '0',
        contribution_amount: '0',
        contribution_percent: '0'
    }
    isChecked = true;
    comments = new FormControl();

    constructor(protected formbuilder: FormBuilder,
        protected route: ActivatedRoute,
        protected router: Router,
        protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected alertService: AlertService,
        protected translateService: TranslateService) 
    {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
        this.initForm();
        this.initKinForm();
        this.initPlanForm();
        // this.initTaxForm();
    }

    ngOnInit(): void {
        this.paramType();
        this.getMatchingGraphData();
    }

    paramType() {
        this.routeType = this.route.snapshot.paramMap.get('type');
        if (this.router.url.includes('add')) {
            this.routeType = 'add';
            this.stopStepper = true;
        }
        else if (this.router.url.includes('view')) {
            this.routeType = 'view';
            this.editViewCommon();
            this.Form.disable();
            this.kinForm.disable();
            this.planForm.disable();
            // this.taxForm.disable();
        }
        else if (this.router.url.includes('update')) {
            this.routeType = 'update';
            this.messageStep = 'updated';
            this.messageEmp = 'updated';
            this.matchingType();
            this.editViewCommon();
        }
    }

    editViewCommon() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.kinForm.get('employee_id').setValue(this.id);
        this.planForm.get('employee_id').setValue(this.id);
        // this.taxForm.get('employee_id').setValue(this.id);
        this.getDetail();
        // this.getMatching();
    }

    getDetail() {
        let url = 'employees/details';
        let employee_id = this.id;
        let enc_payload = this.cryptoService.encryption({ employee_id });
        this.mainService.postData(url, { enc_payload }).then(result => {
            if (result.statusCode === 200) {
                let data = this.cryptoService.decryption(result.data);
                if (data.employee !== null) {
                    if (data.employee.additional_notes) {
                        this.comments.setValue(data.employee.additional_notes);
                    }
                    if (data.employee.phone && data.employee.phone.length == 12 && data.employee.phone.startsWith('966'))
                    {
                        data.employee.phone = data.employee.phone.slice(3);
                    }
                    // if (!data.plan_specific_details)
                    // {
                    //     let plan_specific_details = {
                    //         salary: data.employee.salary
                    //     }
                    //     data.plan_specific_details = plan_specific_details;
                    // }
                    this.employeeSalary = data.employee.salary;
                    this.getMatching();
                    this.Form.patchValue(data.employee);
                    this.Form.get('nationality').setValue(data.employee.nationality);
                    this.Form.get('employee_since').setValue(data.employee.employee_since);
                    this.Form.get('position').setValue(data.employee.position);
                    this.Form.get('are_you_dual_nationality_holder').setValue(data.employee.are_you_dual_nationality_holder);
                    this.Form.get('basic_salary').setValue(data.employee.basic_salary);
                    if (data.employee.iqama_image)
                    {
                        this.imgToShow = this.mainService.file_url + data.employee.iqama_image;
                    }
                }
                if (data.next_of_kin !== null) {
                    this.kinForm.patchValue(data.next_of_kin);
                }
                // console.log('plan', data)
                // if (data.plan_specific_details !== null) {
                //     this.planForm.patchValue(data.plan_specific_details);
                // }
                if (data.tax_residency_information !== null) {
                    // this.taxForm.patchValue(data.tax_residency_information);
                }
                console.log('getDetail', data);
            }
        });
    }

    getMatching()
    {
        let url = `employees/get_matching`;
        let payload = {
            employee_id: this.id
        }
        let encPayload = this.cryptoService.encryption(payload);
        let body = {
            enc_payload: encPayload
        }
        this.mainService.postData(url, body).then(response => {
            if (response.statusCode == 200)
            {
                let decData = this.cryptoService.decryption(response.data);
                console.log('getmatching', decData)
                decData.address.contribution_amount = decData?.address?.contribution_amount ? +decData?.address?.contribution_amount : 0;
                decData.address.contribution_percent = decData?.address?.contribution_percent ? +decData?.address?.contribution_percent : 0;
                decData.address.initial_matching = decData?.address?.initial_matching ? +decData?.address?.initial_matching : 0;
                decData.address.salary = decData?.address?.salary ? +decData?.address?.salary : 0;
                this.planForm.patchValue(decData.address);
                if (!decData.address.salary && this.employeeSalary)
                {
                    this.planForm.get('salary').setValue(this.employeeSalary);
                }
                if (!decData.address.type)
                {
                    this.isChecked = true;
                    this.planForm.get('type').clearValidators();
                    this.planForm.get('type').setValue(null);
                    this.planForm.get('matching').setValue(null);
                    this.planForm.get('matching').setErrors(null);
                    this.planForm.get('matching').clearValidators();
                    this.planForm.get('initial_matching').setValue(null);
                    this.planForm.get('initial_matching').clearValidators();
                    this.planForm.get('months').setValue(null);
                    this.planForm.get('months').clearValidators();
                }
                else
                {
                    this.isChecked = false;
                    this.planForm.get('type').setValidators([Validators.required]);
                    this.planForm.get('type').updateValueAndValidity();
                }
                this.getGraphDataOnFieldChange();
            }
            else
            {

            }
        })
    }

    initForm() {
        this.Form = this.formbuilder.group({
            id: [null],
            name: ['', [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]],
            phone: ['', [Validators.required, Validators.pattern('[1-9]+[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
            email: ['', [Validators.required, Validators.email]],
            gender: ['', [Validators.required]],
            date_of_birth: ['', [Validators.required]],
            address: ['', [Validators.required]],
            iqama_id: ['', [Validators.required, Validators.pattern('[0-9]+[0-9]*'), Validators.maxLength(10)]],
            iqama_expiry: ['', [Validators.required]],
            role: ['employee', [Validators.required]],
            nationality: ['', [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]],
            employee_since: ['', [Validators.required]],
            position: ['', [Validators.required]],
            are_you_dual_nationality_holder: ['', [Validators.required]],
            basic_salary: [null],
        });
    }

    initKinForm() {
        this.kinForm = this.formbuilder.group({
            employee_id: [null],
            first_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]],
            last_name: ['', [Validators.required, Validators.pattern('[a-zA-Z]+[a-zA-Z ]*')]],
            relation_to_nok: ['', [Validators.required]],
            contact_details: ['', [Validators.required, Validators.pattern('[1-9]+[0-9]*'), Validators.minLength(9), Validators.maxLength(9)]],
            power_of_attorney: ['', [Validators.required]],
        });
    }

    initPlanForm() {
        this.planForm = this.formbuilder.group({
            employee_id: [null],
            contribution_amount: [this.calculationVars.contribution_amount],
            contribution_percent: [this.calculationVars.contribution_percent],
            salary: [this.calculationVars.salary, [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)]],
            matching: ['', [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/), Validators.min(0), Validators.max(100)]],
            initial_matching: ['', [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/), Validators.min(0), Validators.max(100)]],
            type: [''],
            start_date: [moment(new Date()).format(appConfig.dateformatServer), [Validators.required]],
            months: ['', [Validators.required, Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)]],
        });
    }

    // initTaxForm() {
    //     this.taxForm = this.formbuilder.group({
    //         employee_id: [null],
    //         tax: ['', [Validators.required]],
    //         tax_ssn_no: ['', [Validators.required, Validators.pattern('[0-9]+[0-9]*'), Validators.minLength(8), Validators.maxLength(12)]],
    //         tax_itn_no: ['', [Validators.required, Validators.pattern('[0-9]+[0-9]*'), Validators.minLength(8), Validators.maxLength(12)]],
    //     });
    // }

    basicDetailSubmit() {
        if (!this.checkValidations(this.Form)) {
            return;
        }
        this.Form.get('date_of_birth').setValue(moment(this.Form.get('date_of_birth').value).format(appConfig.dateformatServer))
        this.Form.get('employee_since').setValue(moment(this.Form.get('employee_since').value).format(appConfig.dateformatServer))
        this.Form.get('iqama_expiry').setValue(moment(this.Form.get('iqama_expiry').value).format(appConfig.dateformatServer))
        this.stopStepper = false;
        let payload = this.cryptoService.encryption(this.Form.value);
        const formdata = new FormData();
        formdata.append('enc_payload', payload)
        formdata.append('iqama_image', this.iqama_image);
        let url = this.id ? 'employees/update' : 'employees/create';
        let message = `Employee basic detail has been ${this.messageStep} successfully`;
        this.submit(url, formdata, message);
    }

    kinSubmit() {
        if (!this.checkValidations(this.kinForm)) {
            return;
        }
        let url = 'employees/next_of_kin';
        let payload = this.cryptoService.encryption(this.kinForm.value);
        let body = { enc_payload: payload }
        let message = `Next of kin details ${this.messageStep} successfully`;
        this.submit(url, body, message);
    }

    planSubmit() {
        this.planForm.get('employee_id').setValue(this.id);
        if (!this.checkValidations(this.planForm)) {
            return;
        }
        let url = 'employees/matching';
        console.log('plansubmit', this.planForm.value)
        let payload = this.cryptoService.encryption(this.planForm.value);
        let body = {
            enc_payload: payload
        }
        let message = `Plan specific details ${this.messageStep} successfully`;
        this.submit(url, body, message);
    }

    // taxSubmit() {
    //     if (!this.checkValidations(this.planForm)) {
    //         return;
    //     }
    //     let url = 'employees/tax_residency_information';
    //     let payload = this.cryptoService.encryption(this.taxForm.value);
    //     let body = {
    //         enc_payload: payload
    //     }
    //     let message = `Tax residency information details ${this.messageStep} successfully`;
    //     this.submit(url, body, message);
    // }

    submit(url, body, message) {
        this.mainService.postData(url, body).then(response => {
            if (response.statusCode == 200) {
                this.stopStepper = false;
                let data = this.cryptoService.decryption(response.data);
                console.log('aftersubmit', data)
                if (this.routeType == 'add' && this.currentIndexOfStepper == 0)
                {
                    this.id = data.employee.id;
                    this.Form.get('id').setValue(data.employee.id);
                    this.kinForm.get('employee_id').setValue(data.employee.id);
                    this.planForm.get('employee_id').setValue(data.employee.id);
                    // this.taxForm.get('employee_id').setValue(data.employee.id);
                }
                this.alertService.alert('Success', message).then(res =>{
                    if (this.currentIndexOfStepper == 2)
                    {
                        this.router.navigate(['main/manage_employee']);
                        this.alertService.alert("Success", `Employee profile has been ${this.messageEmp} successfully`);
                    }
                    if (this.routeType == 'add' || this.routeType == 'update' && this.currentIndexOfStepper < 2 && !this.stopStepper) {
                        this.currentIndexOfStepper++;
                    }
                });
                
            }
            else {
                this.stopStepper = true;
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }

    onNext(){
        if(this.currentIndexOfStepper < 2){
            this.currentIndexOfStepper++;
        }
        
    }

    getMatchingGraphData()
    {
        let url = `employees/matching_calender`;
        let payload = {
            initial_matching: this.fixedMatching == 'variable' ? this.planForm.get('initial_matching').value : this.planForm.get('matching').value,
            type: this.planForm.get('type').value,
            months: this.planForm.get('months').value
        }
        let encPayload = this.cryptoService.encryption(payload);
        let body = {
            enc_payload: encPayload
        }
        this.mainService.postData(url, body).then(response => {
            if (response.statusCode == 200)
            {
                let decResponse = this.cryptoService.decryption(response.data);
                this.lineChartLabels = [];
                this.lineChartData = [{data: [], label: null}];
                decResponse.calender.forEach(item => {
                    item.matching = item.matching ? Number(item.matching).toFixed(2) : item.matching;
                    this.lineChartLabels.push(item.date);
                    this.lineChartData[0].data.push(item.matching);
                    this.lineChartData[0].label = item.matching;
                })
            }
            else
            {}
        })
    }

    getGraphDataOnFieldChange()
    {
        if (this.planForm.get('months').value && this.planForm.get('initial_matching').value)
        {
            this.getMatchingGraphData();
        }
    }

    matchingFieldChange()
    {
        if (this.planForm.get('type').value == 'fixed')
        {
            if (this.planForm.get('matching').value && this.planForm.get('matching').valid)
            {
                this.getMatchingGraphData();
            }
        }
        else if (this.planForm.get('type').value == 'variable')
        {
            if ((this.planForm.get('months').value && this.planForm.get('months').valid) && (this.planForm.get('initial_matching').value && this.planForm.get('initial_matching').valid))
            {
                this.getMatchingGraphData();
            }
        }
    }

    matchingType()
    {
        
        if (this.fixedMatching == 'variable')
        {
            this.planForm.get('matching').setValue(null);
            this.planForm.get('matching').clearValidators();
            this.planForm.get('months').setValidators([Validators.required]);
            this.planForm.get('initial_matching').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
            this.planForm.get('matching').updateValueAndValidity();
            this.planForm.get('months').updateValueAndValidity();
            this.planForm.get('initial_matching').updateValueAndValidity();
        }
        else
        {
            this.planForm.get('months').setValue(null);
            this.planForm.get('months').clearValidators();
            this.planForm.get('initial_matching').setValue(null);
            this.planForm.get('initial_matching').clearValidators();
            this.planForm.get('matching').setValidators([Validators.required, Validators.min(0), Validators.max(100)]);
            this.planForm.get('matching').updateValueAndValidity();
            this.planForm.get('months').updateValueAndValidity();
            this.planForm.get('initial_matching').updateValueAndValidity();
        }
        this.getMatchingGraphData();
    }

    onStepChange(event) {
        this.currentIndexOfStepper = event.selectedIndex;
    }

    getFile(event: any) {
        const file = (event.target.files[0] as File);
        this.iqama_image = file;
        if (event.target.files && event.target.files.length > 0) {
            const file = (event.target.files[0] as File);
            const reader = new FileReader();
            reader.onload = e => this.imgToShow = reader.result;
            reader.readAsDataURL(file);
        }
    }

    openDatePicker(picker)
    {
        if (picker == 1)
        {
            this.datePicker1.open();
        }
        else if (picker == 2)
        {
            this.datePicker2.open();
        }
        else if (picker == 3)
        {
            this.datePicker3.open();
        }
        else
        {
            this.datePicker4.open();
        }
    }

    getFieldValue(form, field) {
        if (form.get(field).touched) {
            if (form.get(field).invalid) {
                return true;
            }
        }
        return false;
    }

    checkValidations(form) {
        if (form.invalid) {
            form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getErrorMessage(form, control, fieldName) {
        if (form.get(control).invalid) {
            if (form.get(control).hasError('required')) {
                return this.translateService.instant(fieldName);
            }
            if (form.get(control).hasError('minlength')) {
                if (control == 'phone')
                return this.translateService.instant('Phone_Min_Nine');
                if (control == 'contact_details')
                return this.translateService.instant('Contact_Min_Nine');
            }
            if (form.get(control).hasError('maxlength')) {
                if (control == 'phone')
                return this.translateService.instant('Phone_Max_Nine');
                if (control == 'iqama_id')
                return this.translateService.instant('Iqama_Max_Ten');
                if (control == 'contact_details')
                return this.translateService.instant('Contact_Max_Nine');
            }
            if (form.get(control).hasError('email')) {
                return this.translateService.instant('Email_Format');
            }
            if (form.get(control).hasError('min')) {
                if (control == 'matching')
                return this.translateService.instant('Matching_Min');
                if (control == 'initial_matching')
                return this.translateService.instant('Initial_Matching_Min');
            }
            if (form.get(control).hasError('max')) {
                if (control == 'matching')
                return this.translateService.instant('Matching_Max');
                if (control == 'initial_matching')
                return this.translateService.instant('Initial_Matching_Max');
            }
            if (form.get(control).hasError('pattern')) {
                if (control == 'name')
                return this.translateService.instant('Name_Letter_Only');
                if (control == 'phone')
                return this.translateService.instant('Phone_Num_Only');
                if (control == 'iqama_id')
                return this.translateService.instant('Iqama_Num_Only');
                if (control == 'nationality')
                return this.translateService.instant('Nationality_Letter_Only');
                if (control == 'first_name')
                return this.translateService.instant('First_Name_Letter_Only');
                if (control == 'last_name')
                return this.translateService.instant('Last_Name_Letter_Only');
                if (control == 'contact_details')
                return this.translateService.instant('Contact_Det_Num_Only');
                if (control == 'salary')
                return this.translateService.instant('Salary_Num_Only');
                if (control == 'matching')
                return this.translateService.instant('Matching_Num_Only');
                if (control == 'initial_matching')
                return this.translateService.instant('Initial_Matching_Num_Only');
                if (control == 'months')
                return this.translateService.instant('Months_Num_Only');
            }
        }
    }

    onCancel()
    {
        this.router.navigate(['main/manage_employee']);
    }

    calculateSalaryPercentage()
    {
        if (this.calculationVars.salary)
        {
            let percVar = ((+this.calculationVars.contribution_amount) / (+this.calculationVars.salary)) * 100
            this.calculationVars.contribution_percent = percVar.toFixed(4);
            this.planForm.get('contribution_percent').setValue(this.calculationVars.contribution_percent);
            return this.calculationVars.contribution_percent;
        }
        this.calculationVars.contribution_percent = '0';
        this.planForm.get('contribution_percent').setValue('0');
    }

    calculateSalaryAmount()
    {
        if (this.calculationVars.salary)
        {
            let salVar = ((+this.calculationVars.contribution_percent) / 100) *  (+this.calculationVars.salary);
            this.calculationVars.contribution_amount = salVar.toFixed(4);
            this.planForm.get('contribution_amount').setValue(this.calculationVars.contribution_amount);
            return this.calculationVars.contribution_amount;
        }
        this.calculationVars.contribution_amount = '0';
        this.planForm.get('contribution_amount').setValue('0');
    }

    salaryChange()
    {
        if (!+this.calculationVars.salary)
        {
            this.planForm.get('contribution_percent').setValue('0');
            this.planForm.get('contribution_amount').setValue('0');
            return;
        }
        this.calculateSalaryAmount();
        this.calculateSalaryPercentage();
    }

    onChecked(checked)
    {
        if (checked)
        {
            this.planForm.get('type').clearValidators();
            this.planForm.get('type').setValue(null);
            this.planForm.get('matching').setValue(null);
            this.planForm.get('matching').setErrors(null);
            this.planForm.get('matching').clearValidators();
            this.planForm.get('initial_matching').setValue(null);
            this.planForm.get('initial_matching').clearValidators();
            this.planForm.get('months').setValue(null);
            this.planForm.get('months').clearValidators();
            this.getMatchingGraphData();
            console.log('onChecked', this.planForm.value)
            console.log('onChecked validations', this.planForm.invalid)
        }
        else
        {
            this.planForm.get('type').setValidators([Validators.required]);
            this.planForm.get('type').updateValueAndValidity();
        }
    }
}
