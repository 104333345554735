<mat-card class="card" [fxLayout]="layout" [style.background]="bg ? bg : '#ffffff'">
    <img *ngIf="img" class="icon" style="width: 40px; cursor: pointer;" [src]="img" alt="Small Earning Icon" fxFlexAlign="center">
    <img *ngIf="imgCustom" class="icon-custom" style="width: 46px; cursor: pointer;" [src]="imgCustom" fxFlexAlign="center">
    <mat-icon *ngIf="icon" class="icon" fxFlexAlign="center">{{img}}</mat-icon>
    <div style="width: 100%; padding-inline: 10px;" fxLayout="column" fxFlexAlign="center">
        <h6 *ngIf="topText">{{ topText }}</h6>
        <h5 *ngIf="middleText != 'N/A'" [style.color]="error ? '#EE5D50' : '#05CD99'" style="font-weight: bold;">{{ middleText }}</h5>
        <h5 *ngIf="middleText == 'N/A'" style="font-weight: bold; color: #EE5D50; font-size: 14px;">{{ middleText }}</h5>

        <h5 *ngIf="middleTextNumber != 'N/A'" style="font-weight: bold; color: #05CD99; font-size: 18px;">
            <span class="number">{{ middleTextNumber | number:'1.2-2' | slice:0:-2 }}</span> 
            <span style="font-size: 14px;">{{ middleTextNumber | number:'1.2-2' | slice:-2 }}</span>
        </h5>

        <h5 *ngIf="middleTextNumberFour != 'N/A'" style="font-weight: bold; color: #05CD99; font-size: 18px;">
            <span *ngIf="middleTextAlphabet" style="color: #05CD99;">{{ middleTextAlphabet }}</span> 
            <span class="number">{{ middleTextNumberFour | number:'1.4-4' | slice:0:-4 }}</span> 
            <span style="font-size: 14px;">{{ middleTextNumberFour | number:'1.4-4' | slice:-4 }}</span>
        </h5>

        <h5 *ngIf="middleTextNumberFive != 'N/A'" style="font-weight: bold; color: #05CD99; font-size: 18px;">
            <span class="number">{{ middleTextNumberFive | number:'1.5-5' | slice:0:-5 }}</span> 
            <span style="font-size: 14px;">{{ middleTextNumberFive | number:'1.5-5' | slice:-5 }}</span>
        </h5>

        <h6 style="font-weight: bold;" *ngIf="bottomText"><span class="percentage"></span> {{ bottomText }}</h6>
    </div>
</mat-card>