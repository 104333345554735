import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RoleGuard } from 'src/guards/role-guards/role.guard';
import { ProfileService } from './services/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'ThriftPlan | Employer Portal';
    isEnglish = false;
    subscription: Subscription;
    showTranslationToggle: boolean = true;
    banner = false;

    constructor(
        protected router: Router
        )
    {
        localStorage.removeItem('auth_token');
        if (localStorage.getItem('lang') == 'ar')
        {
            this.isEnglish = false;
        }
        else
        {
            this.isEnglish = true;
        }

        this.subscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if(event.url.includes('main'))
                {
                    this.showTranslationToggle = false;
                }
                if(localStorage.getItem('self_onboard') == '1' && event.urlAfterRedirects.includes('home'))
                {
                    this.banner = true;
                }
                else
                {
                    this.banner = false;
                }
            }
        });
    
    }

    langChange(event)
    {
        
    }

    hideBanner()
    {
        this.banner = false;
    }

    ngOnDestroy()
    {
        this.subscription.unsubscribe();
    }
}
