import { Injectable, NgModule } from '@angular/core';
import { Routes, RouterModule, Resolve } from '@angular/router';
import { AppComponent } from './app.component';

import { AuthGuard } from 'src/guards/auth.guard';
import { MainGuard } from 'src/guards/main.guard';

import { RoleGuard } from 'src/guards/role-guards/role.guard';
import { ProfileService } from './services/profile.service';
import { Observable } from 'rxjs-compat';
import { LoginComponent, ChangePasswordComponent, ForgotPasswordComponent } from './templates/auth-app/index';
import { TermsComponent } from './views/terms_conditions/terms.component';
import { HomeComponent } from './views/home/home.component';
import { ProfileComponent } from './views/profile/profile.component';
import { ManageInvestmentFundsComponent } from './views/investment-funds/manage-investment-funds.component';
import { AddEmployeeGroupComponent } from './views/investment-funds/add-groups/add-employee-group.component';
import { EmployeeComponent } from './views/manage-employee/employee.component';
import { TerminateEmployeeComponent } from './views/manage-employee/terminate-employee/terminate-employee.component';
import { EmployeeFormNewComponent } from './views/manage-employee/employee-form-new/employee-form-new.component';
import { EmployeeCsvComponent } from './views/manage-employee/employee-via-csv/employee-csv/employee-csv.component';
import { ManagePortfolioComponent } from './views/manage-employee/manage-portfolio/manage-portfolio.component';
import { ManageContributionComponent } from './views/contributions/manage-contribution.component';
import { ContributionFormComponent } from './views/contributions/contribution-form/contribution-form.component';
import { ContributionViewComponent } from './views/contributions/contribution-view/contribution-view.component';
import { EmployersManagerComponent } from './views/manage admin/employers-manager.component';
import { EmployersManagerFormNewComponent } from './views/manage admin/employers-manager-form-new/employers-manager-form-new.component';
import { TermsHrPolicyComponent } from './views/terms-hr-policy/terms-hr-policy.component';
import { TermsFormComponent } from './views/terms-hr-policy/terms-form/terms-form.component';
import { TpLogsComponent } from './views/tp-logs/tp-logs.component';
import { TpNotificationsComponent } from './views/tp-notifications/tp-notifications.component';
import { ManageWithdrawlsComponent } from './views/manage-withdrawls/manage-withdrawls.component';
import { TerminateDetailComponent } from './views/terminate-detail/terminate-detail.component';
import { TerminateDetailViewComponent } from './views/terminate-detail/terminate-detail-view/terminate-detail-view.component';
import { AllocateContributionComponent } from './views/allocato-contribution/allocate-contribution.component';
import { RiskChartComponent } from './views/risk-chart/risk-chart.component';
import { ActivityNotificationsComponent } from './views/activity-notifications/activity-notifications.component';
import { Reallocate } from './views/Reallocate/reallocate.component';
import { ReallocateViewComponent } from './views/Reallocate/reallocate-view/reallocate-view.component';
import { TermsGuard } from 'src/guards/terms.guard';
import { MainAppComponent } from './templates/main-app/main-app.component';
import { HRAuditComponent } from './views/terms-hr-policy/audit/hr-audit.component';
import { ManageOrganizationFormComponent } from './views/manage-orgnazation/manage-organization-form.component';
import { ContributionSummaryComponent } from './views/contributions/contribution-summary/contribution-summary.component';
import { ReportsComponent } from './views/reports/reports.component';
import { MaintenanceComponent } from './views/maintenance/maintenance.component';
import { RegisterComponent } from './templates/auth-app/register/register.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';

@Injectable()
export class ProfileResolver implements Resolve<any> {
  constructor(private ps: ProfileService) {
  }

  resolve(): Observable<any> {
    return this.ps.loadProfile();
  }
}

const publicRoutes: Routes = [
	{ path: '', redirectTo: 'login', pathMatch: 'full' },
	{ path: 'login', component: LoginComponent },
	{ path: 'recovery-password', component: ForgotPasswordComponent },
	{ path: 'reset-password', component: ChangePasswordComponent },
	{ path: 'maintenance', component: MaintenanceComponent },
  { path: 'register', component: RegisterComponent },
];

const termsApp: Routes = [
	{ path: '', redirectTo: 'termsandconditions', pathMatch: 'full' },
	{ path: 'termsandconditions', component: TermsComponent },
]

const mainApp: Routes = [
	{ path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: 'home', component: HomeComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'profile', component: ProfileComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage_orgnazation', component: ManageOrganizationFormComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },

	{ path: 'investment-funds', component: ManageInvestmentFundsComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'investment-funds/employee-group/add', component: AddEmployeeGroupComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'investment-funds/employee-group/update/:id', component: AddEmployeeGroupComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },

	{ path: 'manage_employee', component: EmployeeComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage_employee/add/:id', component: EmployeeFormNewComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage_employee/view/:id', component: EmployeeFormNewComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage_employee/update/:id', component: EmployeeFormNewComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage_employee/create_via_csv', component: EmployeeCsvComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage_employee/manage_portfolio/:id', component: ManagePortfolioComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'terminate_employee/:id', component: TerminateEmployeeComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },

	{ path: 'manage-contribution', component: ManageContributionComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage-contribution/add', component: ContributionFormComponent, canActivate: [RoleGuard], data: { roles: ['all'] }},
	{ path: 'manage-contribution/view/:id', component: ContributionViewComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'manage-contribution/view/summary/:id', component: ContributionSummaryComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },

	{ path: 'employer-manager', component: EmployersManagerComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'employer-manager/:type/:id', component: EmployersManagerFormNewComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },

	{ path: 'manage-withdrawls', component: ManageWithdrawlsComponent, canActivate: [RoleGuard], data: { roles: ['employer', 'emp-finance'] }},

	{ path: 'terminate-detail', component: TerminateDetailComponent, canActivate: [RoleGuard], data: { roles: ['employer', 'emp-checker', 'emp-maker','emp-finance'] } },
	{ path: 'terminate-detail-view/:id', component: TerminateDetailViewComponent, canActivate: [RoleGuard], data: { roles: ['employer', 'emp-checker', 'emp-maker','emp-finance'] } },

	{ path: 'allocate-contribution', component: AllocateContributionComponent, canActivate: [RoleGuard], data: { roles: ['all'] }},
	{ path: 'allocate-contribution/fund-detail/:id', component: RiskChartComponent, canActivate: [RoleGuard], data: { roles: ['all'] }},

	{ path: 'notification', component: TpNotificationsComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'tp-logs', component: TpLogsComponent, canActivate: [RoleGuard], data: { roles: ['employer', 'emp-maker'] } },
	{ path: 'activity-notifications', component: ActivityNotificationsComponent, canActivate: [RoleGuard], data: { roles: ['employer'] }},

	{ path: 'reallocate-funds', component: Reallocate, canActivate: [RoleGuard], data: { roles: ['all'] }},
	{ path: 'reallocate-funds/view/:id', component: ReallocateViewComponent, canActivate: [RoleGuard], data: { roles: ['all'] }},

	{ path: 'hr-policy', component: TermsHrPolicyComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'hr-policy/:type/:id', component: TermsFormComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },
	{ path: 'hr-policy/audit/list/view', component: HRAuditComponent, canActivate: [RoleGuard], data: { roles: ['all'] } },

	{ path: 'reports', component: ReportsComponent, canActivate: [RoleGuard], data: { roles: ['all','employer', 'emp-checker', 'emp-maker','emp-finance'] } },
	{ path: 'maintenance', component: MaintenanceComponent },
];

const appRoutes: Routes = [
	{ path: '', redirectTo: '/auth/login', pathMatch: 'full' },
	{ path: 'auth', component: AppComponent, children: publicRoutes, canActivate: [AuthGuard] },
	{ path: 'terms', component: AppComponent, children: termsApp, canActivate: [TermsGuard], resolve: {data: ProfileResolver} },
	{ path: 'main', component: MainAppComponent, children: mainApp, canActivate: [MainGuard] , resolve: {
		data: ProfileResolver
	  }},
	{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { useHash: true })],
	exports: [RouterModule],
	providers: [
		ProfileResolver
	  ]
})
export class AppRoutingModule { }
