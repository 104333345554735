import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { TranslationComponent } from './lib/translation/translation';
import { TranslationService } from './lib/translation/translation.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MainAppComponent } from './templates/main-app/main-app.component';


import { AlertDialog } from './lib/alert-dialog/alert.dialog';
import { MainService } from './services/main.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DecEncService } from './services/dec-enc';
import { AuthService } from './services/auth.service';
import { PaginationService } from './services/pagination.service';
import { ApiLoaderService } from './lib/api-loader/api-loader.service';
import { ApiLoaderInterceptorService } from './lib/api-loader/api-loader.interceptor.service';
import { ApiLoaderComponent } from './lib/api-loader/api-loader.component';
import { ChartsModule } from 'ng2-charts';

import { ChartModule } from 'angular-highcharts';

import { MessageService } from './services/message.service';
import { TokenService } from './lib/token/token.service';
import { DecimalPipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { TranslationNewComponent } from './lib/translation-new/translation-new.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TpPaginationComponent } from './lib/tp-pagination/tp-pagination.component';
import { TableComponent } from './lib/table/table.component';
import { ProfileService } from './services/profile.service';
import { TerminateAlertDialog } from './lib/terminateAlert-dialog/terminateAlert-dialog';
import { TermsFormComponent } from './views/terms-hr-policy/terms-form/terms-form.component';
import { TermsHrPolicyComponent } from './views/terms-hr-policy/terms-hr-policy.component';
import { EmployeeFormNewComponent } from './views/manage-employee/employee-form-new/employee-form-new.component';
import { EditDialogEmployeesComponent } from './views/manage-employee/employee-via-csv/edit-dialog-employees/edit-dialog-employees.component';
import { EmployeeCsvComponent } from './views/manage-employee/employee-via-csv/employee-csv/employee-csv.component';
import { TermsComponent } from './views/terms_conditions/terms.component';
import { ProfileComponent } from './views/profile/profile.component';

import { LoginComponent, ForgotPasswordComponent, ChangePasswordComponent  } from './templates/auth-app/index';
import { ManageOrganizationFormComponent } from './views/manage-orgnazation/manage-organization-form.component';
import { EditDialogComponent } from './views/contributions/edit-dialog/edit-dialog.component';
import { EmployersManagerComponent } from './views/manage admin/employers-manager.component';
import { ContributionFormComponent } from './views/contributions/contribution-form/contribution-form.component';
import { ManageContributionComponent } from './views/contributions/manage-contribution.component';
import { AddEmployeeGroupComponent } from './views/investment-funds/add-groups/add-employee-group.component';
import { ManageInvestmentFundsComponent } from './views/investment-funds/manage-investment-funds.component';
import { ManagePortfolioComponent } from './views/manage-employee/manage-portfolio/manage-portfolio.component';
import { EmployeeComponent } from './views/manage-employee/employee.component';
import { AlertDialogNew } from './lib/alert-dialog-new/alert.dialog.new';
import { EmployersManagerFormNewComponent } from './views/manage admin/employers-manager-form-new/employers-manager-form-new.component';
import { Reallocate } from './views/Reallocate/reallocate.component';
import { CardComponent } from './lib/card/card.component';
import { ReallocateViewComponent } from './views/Reallocate/reallocate-view/reallocate-view.component';
import { SubscriptionReallocateFormDialogComponent } from './views/Reallocate/subscription-reallocate-form-dialog/subscription-reallocate-form-dialog.component';
import { ActivityNotificationsComponent } from './views/activity-notifications/activity-notifications.component';
import { AllocateContributionComponent } from './views/allocato-contribution/allocate-contribution.component';
import { ContributionViewComponent } from './views/contributions/contribution-view/contribution-view.component';
import { ViewSubscriptionFormDialogComponent } from './views/contributions/subscription-form-dialog/view-subscription-form-dialog.component';
import { HomeComponent } from './views/home/home.component';
import { TerminateEmployeeComponent } from './views/manage-employee/terminate-employee/terminate-employee.component';
import { ManageWithdrawlsComponent } from './views/manage-withdrawls/manage-withdrawls.component';
import { RiskChartComponent } from './views/risk-chart/risk-chart.component';
import { SortingComponent } from './views/sorting/sorting.component';
import { TerminateDetailViewComponent } from './views/terminate-detail/terminate-detail-view/terminate-detail-view.component';
import { TerminateDetailComponent } from './views/terminate-detail/terminate-detail.component';
import { TpLogsComponent } from './views/tp-logs/tp-logs.component';
import { TpNotificationsComponent } from './views/tp-notifications/tp-notifications.component';
import { HRAuditComponent } from './views/terms-hr-policy/audit/hr-audit.component';
import { ContributionSummaryComponent } from './views/contributions/contribution-summary/contribution-summary.component';
import { ListCardComponent } from './lib/list-card/list-card.component';
import { ReportsComponent } from './views/reports/reports.component';
import { MaintenanceComponent } from './views/maintenance/maintenance.component';
import { SubscriptionDocumentsDialogComponent } from './views/contributions/subscription-form-dialog/subscription-documents-dialog/subscription-documents-dialog.component';
import { ToastrModule } from 'ngx-toastr';
import { RegisterComponent } from './templates/auth-app/register/register.component';
import { OnBoardCompleteDialogComponent } from './lib/on-board-complete/on-board-complete-dialog.component';
import { InviteEmployeeDialogComponent } from './lib/invite-employee-dialog/invite-employee-dialog.component';
import { InviteEmployeeLoggedComponent } from './views/manage-employee/invite-employee-logged/invite-employee-logged.component';
import { PageNotFoundComponent } from './views/page-not-found/page-not-found.component';
import { RECAPTCHA_LANGUAGE, RecaptchaModule } from 'ng-recaptcha';
import { CongratulationsDialogComponent } from './views/congratulations-dialog/congratulations-dialog.component';
import { WelcomeAfterAcceptDialogComponent } from './views/welcome-after-accept-dialog/welcome-after-accept-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    MainAppComponent,
    TermsComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ChangePasswordComponent,
    TranslationComponent,
    ProfileComponent,
    ManageOrganizationFormComponent,
    EmployeeComponent,
    ManagePortfolioComponent,
    ManageInvestmentFundsComponent,
    AddEmployeeGroupComponent,
    ManageContributionComponent,
    ContributionFormComponent,
    ApiLoaderComponent,
    EmployersManagerComponent,
    ContributionViewComponent,
    ManageWithdrawlsComponent,
    TerminateDetailComponent,
    TerminateDetailViewComponent,
    AllocateContributionComponent,
    ManagePortfolioComponent,
    RiskChartComponent,
    HomeComponent,
    TerminateEmployeeComponent,
    TpNotificationsComponent,
    SortingComponent,
    TpLogsComponent,
    ActivityNotificationsComponent,
    ViewSubscriptionFormDialogComponent,
    SubscriptionReallocateFormDialogComponent,
    ReallocateViewComponent,
    Reallocate,
    CardComponent,
    EditDialogComponent,
    EmployeeCsvComponent,
    EditDialogEmployeesComponent,
    TranslationNewComponent,
    EmployersManagerFormNewComponent,
    EmployeeFormNewComponent,
    TpPaginationComponent,
    TableComponent,
    TermsHrPolicyComponent,
    TermsFormComponent,
    AlertDialog,
    TerminateAlertDialog,
    AlertDialogNew,
    HRAuditComponent,
    ContributionSummaryComponent,
    ListCardComponent,
    ReportsComponent,
    MaintenanceComponent,
    SubscriptionDocumentsDialogComponent,
    InviteEmployeeDialogComponent,
    OnBoardCompleteDialogComponent,
    InviteEmployeeLoggedComponent,
    PageNotFoundComponent,
    CongratulationsDialogComponent,
    WelcomeAfterAcceptDialogComponent
  ],

  entryComponents: [
    AlertDialog,
    TerminateAlertDialog,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    ChartsModule,
    ChartModule,
    NgOtpInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    RecaptchaModule
  ],
  providers: [
    TranslationService,
    TokenService,
    AuthService,
    MainService,
    DecEncService,
    PaginationService,
    ApiLoaderService,
    MessageService,
    DecimalPipe,
    ProfileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiLoaderInterceptorService,
      multi: true
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: localStorage.getItem('lang'),
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
