<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
    <!-- Table -->
    <app-table fxFlex="100" 
        [tableProps]="tableProps"
        (searchData)="searchData($event)" 
        (actionClicked)="actionClicked($event)" 
        [dataSource]="list" 
        [pagination]="pagination" 
        (setPage)="setPage($event)">
    </app-table>
    <!-- Table -->
</div>
