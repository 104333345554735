import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';

@Component({
    selector: 'app-manage-withdrawls',
    templateUrl: './manage-withdrawls.component.html',
    styleUrls: ['./manage-withdrawls.component.scss']
})
export class ManageWithdrawlsComponent implements OnInit {
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    searchTimer: any;
    search: any = '';
    acpt: any = "assets/ic_verified_user_-1.png";
    rejct: any = "assets/ic_cancel_24px.png"
    acpt_dis: any = "assets/ic_verified.png";
    rejct_dis: any = "assets/ic_cancel.png";
    payoffImage: any = "assets/paidoff.png";
    sort_by: any = ''
    sort_order = ''
    sortData: { value: any; key: string; }[];

    tableProps = {
        heading: 'Manage_Withdrawals',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: true,
        headerActions: [],
        rowActions : [
            { label: 'Accept', type:'accept', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-verify.svg' },
            { label: 'Reject', type:'reject', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg' },
            { label: 'Mark as settled with Employee', type:'mark', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/images/table-edit.png' },
            { label: 'Settlement Completed', type:'paidOff', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-verify.svg' },
        ],
        colHeader: {
            id: 'Withdrawal_ID',
            employee_id: 'Employee_ID',
            name: 'Employee_Name',
            amount: 'Requested Withdrawal Amount (SAR)',
            actual_amount: 'Actual Withdrawal Amount (SAR)',
            created_at: 'Created_At',
            status: 'Status',
            type: 'Type',
            actions: 'Actions',
        },
        columnTypes: {
            amount: 'number-4',
            actual_amount: 'number-4',
            status: 'errorColumn',
            created_at: 'date',
            actions: 'actionsMenu',
        },
        filterArray: []
    };

    pagination = {
        page: null,
        pages: null,
        per_page: null,
        count: 0
    }

    constructor(public tr: TranslationService, 
        public route: Router, 
        public dec_enc: DecEncService, 
        protected mainService: MainService, 
        public paginationService: PaginationService, 
        public dialog: MatDialog,
        protected alertService: AlertService) { }

    ngOnInit(): void {
        this.getall(1)
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.sortOrder();
        })
        this.sortOrder();
    }

    sortOrder() {
        this.sortData = [
            {
                value: this.tr.translation.default,
                key: '',
            },
            {
                value: this.tr.translation.by_status,
                key: 'status',
            }
        ]
    }

    setPage(page)
    {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    getall(index: any) {
        let enc_payload = this.dec_enc.encryption({ search: this.search })
        let url = 'withdrawals?per_page=' + this.perPage + '&page=' + index + '&sort_by=' + this.sort_by + '&sort_order=' + this.sort_order;
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                // console.log('result', result);
                this.list = result.withdrawals;
                this.pagination = result.pagination;
                this.afterList();
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.error, this.tr.translation.serverError);
            }
        )
    }

    afterList()
    {
        this.list.forEach(item => {
            if (item.employer_status == 1 && item.bank_status == 1 && item.payoff == '0')
            {
                item['mark'] = true;
            }
            if (item.bank_status == 0 && item.employer_status == 0 && item.status != 2) 
            {
                item['accept'] = true;
                item['reject'] = true;
            }
            if (item.payoff == '1')
            {
                item['paidOff'] = true;
            }

            if(item.type == 'withdrawal')
            {
                item.type = 'Withdrawal'
            }
            else
            {
                item.type = 'Final Withdrawal'
            }
            
            item['name'] = item.employers_employees[0]?.user?.name ? item.employers_employees[0]?.user?.name : 'N/A';
            item['created_at'] = moment(item['created_at']).format('DD-MM-YYYY');
            if (item.employer_status == 1 && item.bank_status == 1) {
                item['status'] = 'Accepted';
            }
            if (item.employer_status == 1 && item.bank_status == 0) {
                item['status'] = 'Waiting For Fund Manager Approval';
            }
            if (item.employer_status == 0 && item.bank_status == 0 && item.status == 0) {
                item['status'] = 'Pending';
            }
            if (item.employer_status == 1 && item.bank_status == 3) {
                item['status'] = 'Under Process';
            }
            if (item.bank_status == 2 && item.employer_status == 2) {
                item['status'] = 'Declined';
            }
            if (item.bank_status == 0 && item.employer_status == 2) {
                item['status'] = 'Declined By Company';
            }
            if (item.bank_status == 2 && item.employer_status == 1) {
                item['status'] = 'Declined By Bank';
            }
            if (item.bank_status == 0 && item.employer_status == 0 && item.status == 2) {
                item['status'] = 'Canceled by Employee';
            }

            // item.actual_amount = item.actual_amount || item.amount;
        });
    }

    acceptReject(id, val) 
    {
        if (val == 1) 
        {
            this.alertService.alertAsk(this.tr.translation.alert, this.tr.translation.questionAskAccept, 'Yes', 'No', false).then(result => {
                if (result == true) 
                {
                    let data = {
                        id: id,
                        employer_status: val
                    }
                    let enc_payload = this.dec_enc.encryption(data);
                    this.mainService.postData('withdrawals/update', { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            this.alertService.alert(this.tr.translation.success, 'Withdrawal request has been sent to the fund manager for approval.', '');
                            this.getall(this.currentPage)
                        }
                        else {
                            if (localStorage.getItem('lang') == 'ar')
                            {
                                this.alertService.alert('Error', res.error.message_ar);
                            }
                            else
                            {
                                this.alertService.alert('Error', res.error.message);
                            }
                        }
                    },
                        error => {
                            this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError, '');
                        }
                    )
                }
            })
        }
        else {
            this.alertService.alertAsk(this.tr.translation.alert, this.tr.translation.questionAskReject, 'Yes', 'No', false).then(result => {
                if (result == true) {
                    let data = {
                        id: id,
                        employer_status: val
                    }
                    let enc_payload = this.dec_enc.encryption(data);
                    this.mainService.postData('withdrawals/update', { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            this.alertService.alert(this.tr.translation.success, 'Withdrawal request has been rejected', '');
                            this.getall(this.currentPage)
                        }
                        else {
                            if (localStorage.getItem('lang') == 'ar')
                            {
                                this.alertService.alert('Error', res.error.message_ar);
                            }
                            else
                            {
                                this.alertService.alert('Error', res.error.message);
                            }
                        }
                    },
                        error => {
                            this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError, '');
                        }
                    )
                }
            })
        }
    }

    payOff(id) {
        this.alertService.alertAsk(this.tr.translation.alert, 'You are about to mark the withdrawal as settled with the employee. This is an irreversible operation. Are you sure you want to mark this withdrawal as settled and paid off?', 'Yes', 'No', false).then(result => {
            if (result == true) {
                let data = {
                    id: id,
                    payoff: "1"
                }
                let enc_payload = this.dec_enc.encryption(data);
                this.mainService.postData('withdrawals/payoff', { enc_payload }).then(res => {
                    if (res.statusCode == 200) {
                        this.alertService.alert(this.tr.translation.success, this.tr.translation.updatedSuccessfully, '');
                        this.getall(this.currentPage)
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar')
                        {
                            this.alertService.alert('Error', res.error.message_ar);
                        }
                        else
                        {
                            this.alertService.alert('Error', res.error.message);
                        }
                    }
                },
                    error => {
                        this.alertService.alert(this.tr.translation.error, this.tr.translation.serverError, '');
                    }
                )
            }
        })
    }

    download() {
        let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
        let cm = dialogRef.componentInstance;
        cm.heading = this.tr.translation.error;
        cm.message = "Please Select dates ";
        cm.cancelButtonText = this.tr.translation.okay;
        cm.methodType = 'date'
        dialogRef.afterClosed().subscribe(result => {});
    }

    searchData(event) {
        this.search = event;

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 800);
    }

    sort(event) {
        if (event == '') {
            this.sort_order = '';
            this.sort_by = '';
        }
        else {
            this.sort_order = 'ASC'
            this.sort_by = event;
        }
        this.getall(1)
    }

    actionClicked(event)
    {
        if (event.action.label == 'Mark as settled with Employee')
        {
            this.payOff(event.element.id);
        }
        if (event.action.label == 'Accept')
        {
            this.acceptReject(event.element.id, '1');
        }
        if (event.action.label == 'Reject')
        {
            this.acceptReject(event.element.id, '2');
        }
    }
}