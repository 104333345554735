import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { appConfig } from 'src/config';

export class actionButton
{
    label: string;
    route?: string = null;
    type: string; // output or else like link etc
    visibility: boolean = true;
    isCustom: boolean= false;
    disabled?: boolean = false;
	color?: string;
    background?: string;
    icon?: string;
    source?: string;
    selected?: boolean = false;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '80px'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],

})

export class TableComponent implements OnInit, OnChanges {

    objectKeys = Object.keys;
    search='';
    @Input() dataSource = [];
    @Input() pagination: any;
    @Output() actionClicked: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitCheckBox: EventEmitter<any> = new EventEmitter<any>();
    @Output() setPage: EventEmitter<any> = new EventEmitter<any>();
    @Output() searchData: EventEmitter<any> = new EventEmitter<any>();
    @Output() emitFilters: EventEmitter<any> = new EventEmitter<any>();
    checkedBoxes = [];
    headerCheckBoxValue: any;
    expandedElement = null;
    allRowsExpanded = false;
    hasDivs: boolean = false;

    @Input() tableProps: {
        heading: string,
        colHeader: any,
        columnTypes: any; // 'number', 'date', actions > 'actionsMenu', 'actionsSeperate', 'columnWarning'
        subColumnTypesWarning?: any, // 'number'
		isExpandable: boolean
        hasSearch: boolean,
        searchLabel: string,
        hasButton: boolean,
        headerActions: actionButton[];
        rowActions: actionButton[];
        hasMoreLinks: false,
        moreActions: actionButton[],
        filterArray: []
        dateFilter: {
            startLable: string,
            endLable: string
            hasDatePicker: boolean,
            type: string // "dateRange","date"
        },
	};

    @Output() outPutHeaders: EventEmitter<any> = new EventEmitter<any>();
    @Output() outDateFilters: EventEmitter<any> = new EventEmitter<any>();

    selection = new SelectionModel<any>(true, []);

    divColor: any;
    //Date Range
    @Input() start: any = null;
    @Input() end: any = null;
    
    Form: FormGroup;
    currentDate = new Date();
 
    constructor(protected router: Router) {
        this.Form = new FormGroup({
            start: new FormControl(this.start),
            end: new FormControl(this.end)
        });
    }

    ngOnInit(): void {
        this.Form.get('start').setValue(this.start);
        this.Form.get('end').setValue(this.end);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.onAllChecked();
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() 
    {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.length;
        return numSelected === numRows;
    }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAllRows() 
    {
        if (this.isAllSelected()) 
        {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.dataSource);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?): string 
    {
        if (!row) 
        {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    checkBox(event, row)
    {
        if (event.checked)
        {
            row.checked = true;
        }
        else
        {
            row.checked = false;
        }
        this.onAllChecked();
    }

    checkBoxAll(checked)
    {
        if (checked)
        {
            this.dataSource.forEach(item => {
                if (!item['dontShowCB'])
                {
                    item.checked = true;
                }
            });
        }
        else
        {
            this.dataSource.forEach(item => {
                item.checked = false;
            });
            this.headerCheckBoxValue = false;
        }
        this.headerCheckBoxValue = true;
        this.onAllChecked();
    }

    onAllChecked()
	{
		let checkedCount = 0;
        if (this.dataSource?.length > 0)
        {
            this.dataSource.forEach(element => {
                if (element['checked'])
                {
                    checkedCount++;
                }
            });
        }
		let dataLength = 0;
        if (this.dataSource?.length > 0)
        {
            this.dataSource.forEach(element => {
                if (!element['dontShowCB'])
                {
                    dataLength++;
                }
            });
        }
		this.headerCheckBoxValue = (dataLength > 0 && dataLength == checkedCount) ? true : false;

        let obj = {
            checkCount: checkedCount,
            checked: false
        }
        this.emitCheckBox.emit(obj);
	}

    isChecked()
    {
        if (this.headerCheckBoxValue)
        return true;
        return false;
    }

    expandAll()
    {
        this.allRowsExpanded = !this.allRowsExpanded;
        this.expandedElement = null;
    }

    setPagination(page)
    {
        this.setPage.emit(page);
    }

    getPaginationText()
	{
		let pagination = 'Total Count: ';
		if (this.pagination.page < this?.pagination?.pages)
		{
			pagination += this.pagination.per_page * this?.pagination?.page + "/" + this?.pagination?.count;
		}
		else if (this?.pagination?.page == this?.pagination?.pages)
		{
			pagination += this?.pagination?.count + "/" + this?.pagination?.count;
		}

		return pagination;
	}

    onRowActionClicked(elem, act, index)
    {
        if (act.label == 'Expand')
        {
            this.expandedElement = this.expandedElement === elem ? null : elem;
        }
        let row = {
            element: elem,
            action: act,
            index: index,
            expanded: this.expandedElement
        }
        this.actionClicked.emit(row);
    }
    
    onSearch()
    {
        this.searchData.emit(this.search);
    }

    onSearchClear(){
        this.search = null;
        this.searchData.emit(this.search);
    }

	onTableHeaderButton(item): void
	{
		if (item.type == "output")
		{
			this.outPutHeaders.emit(item);
		}
		else
		{
			let url = '/main/' + item.route;
            this.router.navigateByUrl(url);
		}
	}

    onClearData(event, type, filter)
    {
    }

    onChangeFilters(filter): void
    {
        this.emitFilters.emit(filter);
    }

    onDateFilters(data)
    {
        this.outDateFilters.emit(data);
    }

    onChangeDateFilter(): void
    {
        if(this.tableProps.dateFilter?.hasDatePicker)
        {
            let data = {
                type: this.tableProps.dateFilter?.type
            };
            if(this.tableProps.dateFilter?.type == 'dateRange' && this.Form.get('start').value && this.Form.get('end').value)
            {
                data['start'] = moment(this.Form.get('start').value).format(appConfig.dateformatServer);
                data['end'] = moment(this.Form.get('end').value).format(appConfig.dateformatServer);
                this.onDateFilters(data);
            }
            else if(this.tableProps.dateFilter?.type == 'date' && this.Form.get('start').value)
            { 
                data['date'] = moment(this.Form.get('start').value).format(appConfig.dateformatServer);
                this.onDateFilters(data);
            }
        }   
    }

    onDateClear(control): void
    {
        this.Form.get(control).reset();
        if(control == 'start' && this.Form.get('end').value == null || control == 'end' && this.Form.get('start').value == null)
        {
            this.Form.reset();
            let data = {
                type: this.tableProps.dateFilter?.type,
                start: null,
                end: null,
            };
            this.onDateFilters(data);
        }
    }

    rowActionsVisibility(ele)
    {
        let show = false;
        let count = 0;
        this.tableProps?.rowActions.forEach(item=>{
            if (item.visibility && ele[item.type]){
                count++;
            }
        })
        if(count > 0){
            show = true;
        }
        return show;
    }
  
}