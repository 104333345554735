import { Component, OnDestroy, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { TerminateAlertDialog } from 'src/app/lib/terminateAlert-dialog/terminateAlert-dialog';
import { AlertService } from 'src/app/services/alert.service';
import * as printJS from 'print-js';

@Component({
    selector: 'app-terminate-employee',
    templateUrl: './terminate-employee.component.html',
    styleUrls: ['./terminate-employee.component.scss']
})
export class TerminateEmployeeComponent implements OnInit, OnDestroy {
    highcharts = Highcharts;
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    tog: any = true;
    employer_contribution: any;
    employee_contribution: any;
    profit: any;
    profitEmee: any;
    vested: any;
    unvested: any;
    fund: any = [];
    dataA: any = [];
    dataB: any = [];
    dataC: any = [];
    low_risk: any;
    medium_risk: any;
    high_risk: any;
    sum_of_fund: number;
    divide: number;
    date: any = new Date().getFullYear();
    dateB: any = new Date().getFullYear();
    sortData: any;
    chartLoaded: boolean = false;
    newlist: any;
    employeeaggregate: any;
    id: any;
    EmployerAssetslist: any = [];
    EmployeeAssetslist: any = [];

    investedEmployer: number;
    investedEmployee: number;
    EmployerVested: number;
    emeeWithdrawalAmount: number;

    tablePropsEmployer = {
        heading: 'Company Portfolio',
        hasSearch: false,
        searchLabel: 'Search_Name_Email',
        hasButton: false,
        headerActions: [],
        rowActions: [],
        colHeader: {
            fund_name: "Portfolio",
            asset_name: "Fund",
            risk_type: "Risk Type",
            company_availble_units: "No of Units",
            company_invested_amount: "Invested"
        },
        columnTypes: {
            company_invested_amount: 'number-4',
            company_availble_units: 'number-5',
        },
        filterArray: []
    };

    tablePropsEmployee = {
        heading: 'Employee Portfolio',
        hasSearch: false,
        searchLabel: 'Search_Name_Email',
        hasButton: false,
        headerActions: [],
        rowActions: [],
        colHeader: {
            fund_name: "Portfolio",
            asset_name: "Fund",
            risk_type: "Risk Type",
            availble_units: "No of Units",
            employee_invested_amount: "Invested"
        },
        columnTypes: {
            employee_invested_amount: 'number-4',
            availble_units: 'number-5',
        },
        filterArray: []
    };

    headersPrintCompanyPortfolio = [
        { colHeader: 'Portfolio', colKey: 'fund_name', colType: '' },
        { colHeader: 'Fund', colKey: 'asset_name', colType: '' },
        { colHeader: 'Risk Type', colKey: 'risk_type', colType: '' },
        { colHeader: 'No of Units', colKey: 'company_availble_units', colType: 'number-5' },
        { colHeader: 'Invested', colKey: 'company_invested_amount', colType: 'number-4' },
      ];

    headersPrintEmployeePortfolio = [
        { colHeader: 'Portfolio', colKey: 'fund_name', colType: '' },
        { colHeader: 'Fund', colKey: 'asset_name', colType: '' },
        { colHeader: 'Risk Type', colKey: 'risk_type', colType: '' },
        { colHeader: 'No of Units', colKey: 'availble_units', colType: 'number-5' },
        { colHeader: 'Invested', colKey: 'employee_invested_amount', colType: 'number-4' },
      ];

    dashboardData: any;
    emp_id: any = null;
    totalEmployeeSettlement = 0;
    isArabic = false;

    constructor(public tr: TranslationService, public route: Router, public dec_enc: DecEncService, protected mainService: MainService,
        public paginationService: PaginationService, public dialog: MatDialog, protected _route: ActivatedRoute,
        protected alertService: AlertService) {
        this._route.params.subscribe(params => {
            this.id = params['id'];
        });
        this.emp_id = localStorage.getItem('employer_id')
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
    }

    ngOnDestroy(): void {
        this.chartLoaded = false;
    }

    ngOnInit(): void {
        this.addYear();
        this.getall();
        this.getAllNew();
    }

    onBack() {
        window.history.back();
    }

    getall() {
        let enc_payload = this.dec_enc.encryption({ employee_id: this.id });
        this.mainService.postData('home/employee/before_terminate', { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                console.log('employee terminate getall', result);
                this.list = result;
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError);
            }
        )
    }

    getAllNew() {
        let enc_payload = this.dec_enc.encryption({ employer_id: this.emp_id, employee_id: this.id })
        let url = 'home/employee/portfolio'
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                this.dashboardData = this.dec_enc.decryption(res.data);
                this.totalEmployeeSettlement = Number(this.dashboardData?.vest_breakdown?.vested) + Number(this.dashboardData?.employee_fund?.invested_amount);
                if (this.dashboardData.employee_assets.length > 0)
                {
                    this.dashboardData.employee_assets.forEach(item => {
                        item.availble_units = !item.availble_units || isNaN(item.availble_units) ? 0 : Number(item.availble_units);
                        item.employee_invested_amount = !item.employee_invested_amount || isNaN(item.employee_invested_amount) ? 0 : Number(item.employee_invested_amount);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() +item['risk_type'].slice(1)  : 'N/A';
                    });
                    this.EmployeeAssetslist = this.dashboardData.employee_assets;
                }
                if (this.dashboardData.company_assets.length > 0)
                {
                    this.dashboardData.company_assets.forEach(item => {
                        item.company_availble_units = !item.company_availble_units || isNaN(item.company_availble_units) ? 0 : Number(item.company_availble_units);
                        item.company_invested_amount = !item.company_invested_amount || isNaN(item.company_invested_amount) ? 0 : Number(item.company_invested_amount);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() +item['risk_type'].slice(1)  : 'N/A';
                    });
                    this.EmployerAssetslist = this.dashboardData.company_assets;
                }
                this.pieChartBrowser();
                this.pieChartBrowserB();
                console.log('portfolio = ', this.dashboardData);
            }
        },
            error => {

                this.alertService.alert('Error', this.tr.translation.serverError, '');
            }
        )
    }

    pieChartBrowser() {
        this.tr.selectedApp.subscribe((state) => {
            if (this.chartLoaded) {
                this.tr.translation = state;
                this.pieChartBrowserTranslation("inService");
            }
        })
        this.pieChartBrowserTranslation("withoutService");
    }

    pieChartBrowserTranslation(xyz) {
        Highcharts.chart('pieChart', {
            chart: {
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                style: {
                    fontFamily: 'sans-serif'
                }
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            tooltip: {
                pointFormat: ' <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    size: '70%',
                    innerSize: '70%'
                }
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                // text: '<p style="font-size: 17px;font-family: sans-serif !important;"><span style="font-weight:900;color:#054792; font-family: sans-serif">SAR ' + this.tr.numberFormatter2(this.employer_contribution + this.profit + this.emrInvested) + '</span></p>',
                text: ''
            },
            series: [{
                name: 'Brands',
                colorByPoint: false,
                type: undefined,
                style: {
                    display: 'none'
                },
                dataLabels:
                {
                    enabled: false,
                    // connectorWidth: 0,
                },
                data: [
                    {
                        name: this.tr.translation.Employer_Contribution,
                        y: Number(this.dashboardData?.summary?.company_contribution),
                        color: '#004390',
                        type: 'pie'
                    }, {
                        name: this.tr.translation.Profit,
                        y: Number(this.dashboardData?.company_fund?.profit),
                        color: '#E80F1F',
                        type: 'pie'
                    }, {
                        name: 'Company Invested',
                        y: Number(this.dashboardData?.company_fund?.invested_amount),
                        color: '#687784',
                        type: 'pie'
                    }
                ]
            }],
        });
    }

    pieChartBrowserB() {
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.pieChartBrowserBTranslation()
        })
        this.pieChartBrowserBTranslation()
    }

    pieChartBrowserBTranslation() {
        Highcharts.chart('pieChartB', {
            chart: {
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '<b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    size: '70%',
                    innerSize: '70%',
                }
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                text: ''
            },
            series: [{
                colorByPoint: true,
                type: undefined,
                dataLabels:
                {
                    enabled: false,
                },
                data: [
                    {
                        name: this.tr.translation.Employee_Contribution,
                        y: Number(this.dashboardData?.summary?.employee_contribution),
                        color: '#004390',
                        type: 'pie'
                    }, {
                        name: this.tr.translation.Profit,
                        y: Number(this.dashboardData?.employee_fund?.profit),
                        color: '#E80F1F',
                        type: 'pie'
                    }, {
                        name: 'Employee Invested',
                        y: Number(this.dashboardData?.termination?.employee_settlement),
                        color: '#687784',
                        type: 'pie'
                    }
                ]
            }]
        });
    }

    addYear() {
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.addYearTranslation();
        })
        this.addYearTranslation();
    }

    addYearTranslation() {
        let d = new Date();
        var year = d.getFullYear();
        this.sortData = [
            {
                value: this.tr.translation.currunt_year,
                key: year
            },
            {
                value: year - 1,
                key: year - 1
            },
            {
                value: year - 2,
                key: year - 2
            },
            {
                value: year - 3,
                key: year - 3
            },
            {
                value: year - 4,
                key: year - 4
            },
            {
                value: year - 5,
                key: year - 5
            }
        ]
    }

    getTerminationCheck() {
        this.alertService.alertAsk("Confirmation", "Are you sure you want to terminate this employee?", "Yes", "No", false).then(res => {
            if (res) {
                let enc_payload = this.dec_enc.encryption({ employee_id: this.id });
                this.mainService.postData('terminations/validate', { enc_payload }).then(res => {
                    if (res.statusCode == 200) {
                        this.TerminateEmployee();
                        return;
                    }
                    let message = '';
                    if (localStorage.getItem('lang') == 'ar') {
                        message = res.error.message_ar;
                    }
                    else {
                        message = res.error.message;
                    }
                    this.alertService.alert("Error", message);
                },
                    error => {
                        this.alertService.alert("Error", this.tr.translation.serverError);
                    })
            }
        })
    }

    TerminateEmployee() {
        let dialogRef = this.dialog.open(TerminateAlertDialog, {
            panelClass: this.isArabic ? 'arabic-lang' : 'english-lang',
            autoFocus: true,
            maxHeight: '90vh',
            width: '40%',
        });
        let cm = dialogRef.componentInstance;
        dialogRef.componentInstance.EmployeeId = this.id;
        // dialogRef.componentInstance.TotalSettlementEmployer = this.investedEmployer;
        dialogRef.componentInstance.TotalSettlementEmployer = this.dashboardData?.termination?.company_settlement;
        // dialogRef.componentInstance.TotalSettlement = (Number(this.employeeaggregate?.total_settelment_Amount));
        dialogRef.componentInstance.TotalSettlement = this.dashboardData?.termination?.total_settlement_amount;
    }

    showPrint = false;
    onPrint(): void {
        this.showPrint = true;
        const printContent = document.getElementById('print');
        printJS({
          printable: printContent.innerHTML,
          type: 'raw-html'
        });
        this.showPrint = false;
    }
}
