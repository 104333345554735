import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TableComponent } from 'src/app/lib/table/table.component';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { EditDialogEmployeesComponent } from '../edit-dialog-employees/edit-dialog-employees.component';

@Component({
    selector: 'app-employee-csv',
    templateUrl: './employee-csv.component.html',
    styleUrls: ['./employee-csv.component.scss']
})
export class EmployeeCsvComponent implements OnInit {

    employees = [];
    filteredEmployees = [];
    allEmployees = [];
    showListing: boolean = false;
    allExpanded = false;
    showErrorSelected = false;
    checkBoxCount = 0;
    hasError = 0;
    isArabic = false;
    pagination = {
        page: null,
        pages: null,
        per_page: null,
        count: 0
    }

    columnTypes = {
        employer_contribution_amount: 'number',
        employee_contribution_amount: 'number',
        created_at: 'date',
        updated_at: 'date',
        error: 'errorColumn',
        actions: 'actionsSeperate',
    }

    colHeader = {
        checkbox: '',
        no: 'No.',
        name: 'Employee Name',
        email: 'Email Address',
        national_id_or_iqama: 'National ID/Iqama',
        phone: 'Phone Number',
        error: 'Error Message',
        actions: 'Actions',
    }

    tablePropsEmp = {
        heading: 'Manage Employees',
        isExpandable: true,
        hasSearch: false,
        hasButton: true,
        headerActions: [
            {
                label: 'Delete Selected',
                type: 'output',
                visibility: false,
                isCustom: false,
            },
            {
                label: 'Expand_All',
                type: 'output',
                visibility: true,
                isCustom: false,
                // icon: 'add',
            },
            {
                label: 'Show_Rows_Errors',
                type: 'output',
                visibility: true,
                isCustom: false,
                // icon: 'add',
            },
            {
                label: 'New_Row',
                type: 'output',
                visibility: true,
                isCustom: false,
                icon: 'add',
            },
        ],
        rowActions: [
            { label: 'Expand', visibility: true, background: '#4318FF0D', source: 'assets/images/arrow-svg.svg' },
            { label: 'Edit', visibility: true, background: '#79BBBF0D', source: 'assets/new-icons/edit.svg' },
            { label: 'Delete', visibility: true, background: '#4318FF0D', source: 'assets/images/table-delete.png' },
        ],
        colHeader: {
            checkbox: '',
            no: 'Number',
            name: 'Employee_Name',
            email: 'Email',
            national_id_or_iqama: 'National_Iqama_ID',
            phone: 'Phone_Number',
            error: 'Error_Message',
            actions: 'Actions',
        },
        columnTypes: {
            email: 'email',
            error: 'errorColumn',
            actions: 'actionsSeperate',
        },
        filterArray: []
    };

    @ViewChild('attach') attach: ElementRef;
    @ViewChild(TableComponent) table: TableComponent;

    // actions = [
    //     { label: 'Expand', visibility: true, background: '#4318FF0D', source: 'assets/images/arrow-svg.svg' },
    //     { label: 'Edit', visibility: true, background: '#79BBBF0D', source: 'assets/images/table-edit.png' },
    //     { label: 'Delete', visibility: true, background: '#4318FF0D', source: 'assets/images/table-delete.png' },
    // ]

    summary = {
        total: 0,
        errors: 0,
        ok: 0
    }

    constructor(protected mainService: MainService,
        protected cryptoService: DecEncService,
        protected alertService: AlertService,
        protected dialog: MatDialog,
        public route: Router,) 
    {
        this.isArabic = localStorage.getItem('lang') == 'ar' ? true : false;
    }

    ngOnInit(): void {
        this.getEmployees();
    }

    uploadCSV() {
        this.attach.nativeElement.click();
    }

    getEmployees() {
        let count = 1;
        if (this.pagination.per_page != null) {
            count = (this.pagination.per_page * (this.pagination.page - 1)) + 1;
        }
        let url = `draft-employees?per_page=20&sort_order=asc&page=${this.pagination.page}`;
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200) {
                let dec = this.cryptoService.decryption(response.data);
                console.log("draft-employees", dec);
                this.pagination = dec.pagination;
                this.employees = dec.draft_employees;
                if (dec.draft_employees.length > 0) {
                    this.hasError = dec.summary.errors;
                    this.showListing = true;
                    for (let item of this.employees) {
                        item['no'] = count;
                        item['error'] = null;
                        let errorFound = false;

                        if (item['error_message']) 
                        {
                            for (let key in item['error_message']) 
                            {
                                errorFound = true;

                                // if (item['error'] == null)
                                // {
                                //     item['error'] = `${item['error_message'][key]}`;
                                // }
                                // else
                                // {
                                //     // with line breaks
                                //     // item['error'] = `${item['error'] } \n - ${item['error_message'][key]}`;
                                //     // without line breaks
                                //     item['error'] = `${item['error'] }  ${item['error_message'][key]}`;
                                // }


                                if (localStorage.getItem('lang') == 'ar') {
                                    if (item['error'] == null) {
                                        item['error'] = item['error_message'][key]['message_ar'];
                                    }
                                    else {
                                        item['error'] = `${item['error']}, ${item['error_message'][key]['message_ar']}`;
                                    }
                                }
                                else {
                                    if (item['error'] == null) {
                                        item['error'] = item['error_message'][key]['message'];
                                    }
                                    else {
                                        item['error'] = `${item['error']}, ${item['error_message'][key]['message']}`;
                                    }
                                }
                            }
                        }

                        if (!errorFound) {
                            // item['error'] = 'Verified';
                            item['error'] = 'No Errors';
                        }
                        count++;
                    }
                }
                else {
                    this.showListing = false;
                }
                this.summary = dec.summary;
                this.afterList();
                this.allEmployees = this.employees.map(object => ({ ...object }))
                // console.log('getEmployees()', dec);
            }
            else {

            }
        })
    }

    getFile(event) {
        if (event.target.files && event.target.files.length) {
            let file = event.target.files[0];
            this.importCSV(file);
        }
    }

    importCSV(event) {
        let url = `draft-employees/import`
        let data = new FormData();
        data.append('csv', event);
        this.mainService.postData(url, data).then(response => {
            if (response.statusCode == 200) {
                // console.log('Successful', response);
                this.getEmployees();
            }
            else {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.error.message);
                }
                // console.log('Failed', response);
            }
        })
    }

    discardContributions() {
        this.alertService.alertAsk('Warning', 'Are you sure you want to discard?', 'Yes', 'No', false).then(res => {
            if (res) {
                let url = `draft-employees/discard`;
                this.mainService.postData(url, {}).then(response => {
                    if (response.statusCode == 200) {
                        let dec = this.cryptoService.decryption(response.error);
                        // console.log('Success', response);
                        this.getEmployees();
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar')
                        {
                            this.alertService.alert('Error', response.error.message_ar);
                        }
                        else
                        {
                            this.alertService.alert('Error', response.error.message);
                        }
                        // console.log('Failure', response)
                    }
                })
            }
        })
    }

    openDialog() {
        const dialogRef = this.dialog.open(EditDialogEmployeesComponent, {
            data: 'New'
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res.event == 'created') {
                this.getEmployees();
                return;
            }
            if (res.event == 'closed') {
                return;
            }
        })
    }

    editDialog(data?) {
        const dialogRef = this.dialog.open(EditDialogEmployeesComponent, {
            data: data,
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res.event == 'updated') {
                this.getEmployees();
                return;
            }
            if (res.event == 'closed') {
                return;
            }
        })
    }

    actionClicked(event) {
        if (event.action.label == 'Edit') {
            this.editDialog(event.element);
        }
        if (event.action.label == 'Delete') {
            this.alertService.alertAsk('Confirmation', 'Are you sure you want to remove?', 'Yes', 'No', false).then(res => {
                if (res) {
                    this.deleteContribution(event.element.id);
                }
            })
        }
        if (event.action.label == 'Expand') {
            if (event.expanded) {
                this.tablePropsEmp.rowActions[0].source = 'assets/images/arrow-up-svg.svg';
            }
            else {
                this.tablePropsEmp.rowActions[0].source = 'assets/images/arrow-svg.svg';
            }
        }
        // console.log('rowActions', event);
    }

    headerButton(event) {
        if (event.label == 'Delete Selected') {
            this.deleteSelected();
        }
        if (event.label == 'Expand_All') {
            this.expandAll();
        }
        if (event.label == 'Show_Rows_Errors') {
            this.filterErrors();
        }
        if (event.label == 'New_Row') {
            this.openDialog();
        }
        // console.log(event);
    }

    deleteContribution(id) {
        let url = `draft-employees/remove?id=${id}`;
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200) {
                this.getEmployees();
            }
            else {
                if (localStorage.getItem('lang') == 'ar')
                {
                    this.alertService.alert('Error', response.error.message_ar);
                }
                else
                {
                    this.alertService.alert('Error', response.error.message);
                }
            }
        })
    }

    deleteSelected() {
        this.alertService.alertAsk('Confirmation', 'Are you sure you want to delete the selected items', 'Yes', 'No', false).then(res => {
            if (res) {
                let ids = [];
                let url = `draft-employees/remove`
                this.employees.forEach(item => {
                    if (item.checked) {
                        ids.push(item.id);
                        if (ids.length == 1) {
                            url = `${url}?id=${item.id}`;
                        }
                        else {
                            url = `${url}&id=${item.id}`;
                        }
                    }
                })
                this.mainService.postData(url, {}).then(response => {
                    if (response.statusCode == 200) {
                        this.getEmployees();
                        this.checkBoxCount = 0;
                        this.showErrorSelected = false;
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar')
                        {
                            this.alertService.alert('Error', response.error.message_ar);
                        }
                        else
                        {
                            this.alertService.alert('Error', response.error.message);
                        }
                    }
                })
            }
        })
    }

    submitEmployees() {
        this.alertService.alertAsk('Confirmation', 'Are you sure you want to submit', 'Yes', 'No', false).then(res => {
            if (res) {
                let url = `draft-employees/generate`;
                this.mainService.postData(url, {}).then(response => {
                    if (response.statusCode == 200) {
                        let dec = this.cryptoService.decryption(response.data);
                        // console.log('submitEmployees() success', dec)
                        this.alertService.alert('Success', 'Employees have been created successfully', '');
                        this.route.navigateByUrl('/main/manage_employee');
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar')
                        {
                            this.alertService.alert('Error', response.error.message_ar);
                        }
                        else
                        {
                            this.alertService.alert('Error', response.error.message);
                        }
                        // console.log('submitEmployees() Fail', response)
                    }
                })
            }
        })
    }

    downloadCSV() {
        let downloadLink = document.createElement('a');
        downloadLink.href = "assets/employee_sample_csv.csv";
        downloadLink.setAttribute('download', 'Download Sample CSV');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        downloadLink.remove();
    }

    getEmployeesForDownload() {
        let url = `draft-employees?per_page=${this.pagination.count}`;
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200) {
                let dec = this.cryptoService.decryption(response.data);
                let arr = dec.draft_employees;
                arr.forEach(item => {
                    delete item['id'];
                    delete item['error_status'];
                    delete item['error_message'];
                    delete item['employer_id'];
                    delete item['updated_at'];
                    delete item['created_at'];

                    item['name-Required'] = item['name'];
                    delete item['name'];
                    if (item['name-Required'].indexOf("\n") != -1)
                    {
                        item['name-Required'] = item['name-Required'].replace(/\n/g, '');
                        // console.log('linebreak found', item['name-Required'])
                    }

                    item['phone-Required'] = item['phone'];
                    delete item['phone'];

                    item['email-Required'] = item['email'];
                    delete item['email'];

                    item['gender-Required'] = item['gender'];
                    delete item['gender'];

                    item['date_of_birth-Required'] = item['date_of_birth'];
                    delete item['date_of_birth'];

                    item['national_id_or_iqama-Required'] = item['national_id_or_iqama'];
                    delete item['national_id_or_iqama'];

                    item['national_id_or_iqama_expiry-Required'] = item['national_id_or_iqama_expiry'];
                    delete item['national_id_or_iqama_expiry'];

                    item['address'] = !item['address'] ? '' : item['address'];
                    item['date_of_birth-Required'] = !item['date_of_birth-Required'] ? '' : item['date_of_birth-Required'];
                    item['designation'] = !item['designation'] ? '' : item['designation'];
                    item['dual_nationality_holder'] = !item['dual_nationality_holder'] ? '' : item['dual_nationality_holder'];
                    item['email-Required'] = !item['email-Required'] ? '' : item['email-Required'];
                    item['gender-Required'] = !item['gender-Required'] ? '' : item['gender-Required'];
                    item['group'] = !item['group'] ? '' : item['group'];
                    item['joining_date'] = !item['joining_date'] ? '' : item['joining_date'];
                    item['name-Required'] = !item['name-Required'] ? '' : item['name-Required'];
                    item['national_id_or_iqama-Required'] = !item['national_id_or_iqama-Required'] ? '' : item['national_id_or_iqama-Required'];
                    item['national_id_or_iqama_expiry-Required'] = !item['national_id_or_iqama_expiry-Required'] ? '' : item['national_id_or_iqama_expiry-Required'];
                    item['nationality'] = !item['nationality'] ? '' : item['nationality'];
                    item['phone-Required'] = !item['phone-Required'] ? '' : item['phone-Required'];
                    item['salary'] = !item['salary'] ? '' : item['salary'];
                })
                // console.log('getEmployeesForDownload()', arr);
                this.downloadFile(arr);
            }
        })
    }

    downloadWhileEdit() {
        let arr = [];

    }

    downloadFile(data, filename = 'data') {
        let csvData = this.ConvertToCSV(data);
        let blob = new Blob(['\ufeff' + csvData], {
            type: 'text/csv;charset=utf-8;'
        });
        let dwldLink = document.createElement("a");
        let url = URL.createObjectURL(blob);
        dwldLink.setAttribute("href", url);
        dwldLink.setAttribute("download", filename + ".csv");
        dwldLink.style.visibility = "hidden";
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }

    ConvertToCSV(objArray) {
        let _array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        str = 'name-Required,phone-Required,email-Required,gender-Required,date_of_birth-Required,address,national_id_or_iqama-Required,national_id_or_iqama_expiry-Required,nationality,joining_date,designation,dual_nationality_holder,group,salary';
        let cols = str.split(',');

        let lines = [];
        lines.push(cols);
        for (let r = 0; r < _array.length; r++) {
            let row = _array[r];
            let line = [];
            cols.forEach(c => {
                if (row[c].includes(','))
                {
                    row[c] = row[c].replaceAll(",", " ");
                }
                line.push(row[c]);
            })
            lines.push(line.join(','));
        }
        str = lines.join('\r\n');
        return str;
    }

    filterErrors() {
        this.showErrorSelected = !this.showErrorSelected;
        if (this.showErrorSelected) {
            this.filteredEmployees = this.employees.filter(item => {
                if (item.error != 'No Errors') {
                    return item;
                }
            })
            this.employees = this.filteredEmployees;
        }
        else {
            this.employees = this.allEmployees;
        }
    }

    afterList() {
        this.employees.forEach(item => {
            item.Edit = true;
            item.Expand = true;
            item.Delete = true;
        })
        // console.log('afterlist', this.employees)
    }

    expandAll() {
        this.allExpanded = !this.allExpanded;
        this.table.expandAll();
    }

    checkBox(event) {
        this.checkBoxCount = event.checkCount;
        if (this.checkBoxCount) {
            this.tablePropsEmp.headerActions[0].visibility = true;
        }
        else {
            this.tablePropsEmp.headerActions[0].visibility = false;
        }
    }

    setPage(page) {
        this.pagination.page = page;
        this.getEmployees();
    }
}
