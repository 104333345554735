import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialog } from 'src/app/lib/alert-dialog/alert.dialog';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-reallocate',
    templateUrl: './reallocate.component.html',
    styleUrls: ['./reallocate.component.scss']
})
export class Reallocate implements OnInit {
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    searchTimer: any;
    search: any = '';
    items: any;
    employer_id: any;
    employee_name: any = ""
    acpt: any = "assets/ic_verified_user.png";
    rejct: any = "assets/ic_cancelpx.png"
    acpt_dis: any = "assets/ic_verified.png";
    rejct_dis: any = "assets/ic_cancel.png";
    employer: any = '';
    sort_by: any = '';
    sort_order = '';
    ApproveRejected: boolean = true;
    role: any;

    sortData: any = [
        {
            value: 'Default',
            key: '',

        },
        {
            value: 'Employer',
            key: 'company_name',

        },

    ]
    sortName: any = 'Sort By Employer'

    tableProps = {
        heading: 'Reallocate_Portfolio',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: false,
        headerActions: [],
        rowActions : [
            // { label: 'View', type: 'view', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
        ],
        colHeader : {
            id: 'Reallocation_id',
            name: 'Employee_Name',
            fromPortfolio: 'From_Portfolio',
            toPortfolio: 'To_Portfolio',
            created_at: 'RequestDate',
            statusToShow: 'Status',
            // actions: 'Actions'
        },
        columnTypes : {
            statusToShow: 'errorColumn',
            // actions: 'actionsMenu',
            created_at: 'date'
        },
        filterArray: []
    };

    pagination = {
        page: null,
        pages: null,
        per_page: null,
        count: 0
    }
    constructor(public tr: TranslationService, public route: Router, protected mainService: MainService, public dec_enc: DecEncService, protected paginationService: PaginationService, public dialog: MatDialog,
        protected alertService: AlertService) {
        this.employee_name = localStorage.getItem('employee_name');
        this.role = localStorage.getItem('role');

        if (this.role == 'admin') {
            this.ApproveRejected = true;
        }
        else {
            this.ApproveRejected = false;
        }
    }

    ngOnInit(): void {
        this.getall(1)
    }

    setPage(page)
    {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    getall(index: any) {
        let enc_payload = this.dec_enc.encryption({ search: this.search })
        // re_allocations_requests?per_page=    old url ha ya farhan na change kia ha backend sa
        let url = 'reallocations?per_page=' + this.perPage + '&page=' + index + '&sort_by=' + this.sort_by + '&sort_order=' + this.sort_order
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.pagination = result.pagination;
                this.list = result.re_allocations;
                this.afterList();
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError, '');
            }
        )
    }

    afterList()
    {
        this.list.forEach(item => {

            item['created_at'] = moment(item['created_at']).format('DD-MM-YYYY');
            item['name'] = item.employers_employee?.user?.name ? item.employers_employee?.user?.name :'N/A';
            item['fromPortfolio'] = item.from_fund?.name ? item.from_fund?.name : 'N/A';
            item['toPortfolio'] = item.fund?.name ? item.fund?.name : 'N/A';

            if (item.status == 1 && item.bank_status == 1)
            {
                item['statusToShow'] = 'Accepted';
                // item['view'] = true;
            }
            if (item.status == 3 && item.bank_status == 3)
            {
                item['statusToShow'] = 'Under-Process';
            }
            if (item.status == 0 && item.bank_status == 0)
            {
                item['statusToShow'] = 'Pending';
            }
            if (item.status == 2 && item.bank_status == 2)
            {
                item['statusToShow'] = 'Declined By Bank';
            }
            if (item.status == 2 && item.bank_status == 0)
            {
                item['statusToShow'] = 'Canceled by Initiator(Employee)';
            }
            // else
            // {
            //     console.log("status to show",item.status,item.bank_status)
            //     item['statusToShow']=  item.status;
            // }
        });
        // console.log('AfterList Reallocate', this.list);
    }

    onFormatDate(date) {
        if (date == null) {
            return 'N/A'
        }

        else {
            var dateFormat = new Date(date)
            // return this.datepipe.transform(dateFormat, 'dd/MM/yyyy');

        }
    }

    searchData(event) {
        this.search = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 800);
    }

    acceptReject(id, val) {

        if (val == 1) {
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = 'Alert';
            cm.message = "Do you want to accept?";
            cm.submitResponceText = 'Yes';
            cm.cancelButtonText = 'No';
            cm.type = 'ask2';
            dialogRef.afterClosed().subscribe(result => {
                if (result == true) {
                    let data = {
                        id: id,
                        admin_status: val
                    }
                    //console.log(data);

                    let enc_payload = this.dec_enc.encryption(data);
                    this.mainService.postData('re_allocations_requests/update', { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            this.getall(this.currentPage)
                        }
                        else if (res.statusCode == 422) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else if (res.error.statusCode == 412) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else if (res.statusCode == 404) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else if (res.statusCode == 406) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                    },
                        error => {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = 'Internal Server error';
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                    )
                }
            })
        }
        else {
            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
            let cm = dialogRef.componentInstance;
            cm.heading = 'Alert';
            cm.message = "Do you want to reject?";
            cm.submitResponceText = 'Yes';
            cm.cancelButtonText = 'No';
            cm.type = 'ask2';
            dialogRef.afterClosed().subscribe(result => {
                if (result == true) {
                    let data = {
                        id: id,
                        admin_status: val
                    }
                    //console.log(data);

                    let enc_payload = this.dec_enc.encryption(data);
                    this.mainService.postData('re_allocations_requests/update', { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            this.getall(this.currentPage)
                        }
                        else if (res.statusCode == 422) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else if (res.error.statusCode == 412) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else if (res.statusCode == 404) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else if (res.statusCode == 406) {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                        else {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = res.error.message;
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                    },
                        error => {
                            let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                            let cm = dialogRef.componentInstance;
                            cm.heading = 'Error';
                            cm.message = 'Internal Server error';
                            cm.cancelButtonText = 'Okay';
                            cm.type = 'error';
                        }
                    )
                }
            })
        }
    }

    sort(event) {

        if (event == '') {
            this.sort_order = '';
            this.sort_by = '';
        }
        else {
            this.sort_order = 'ASC'
            this.sort_by = event;
        }
        this.getall(1)
    }

    view(id) {

        this.route.navigate(['main/reallocate-funds/view/' + id])
    }

    actionClicked(event)
    {
        if (event.action.label == 'View')
        {
            this.view(event.element.id);
        }
    }
}

