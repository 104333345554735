import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import * as printJS from 'print-js';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { appConfig } from 'src/config';

@Component({
    selector: 'app-terminate-detail',
    templateUrl: './terminate-detail.component.html',
    styleUrls: ['./terminate-detail.component.scss']
})
export class TerminateDetailComponent implements OnInit {
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    searchTimer: any;
    search: any = '';
    acpt: any = "assets/ic_verified_user_-1.png";
    rejct: any = "assets/ic_cancel_24px.png"
    acpt_dis: any = "assets/ic_verified.png";
    rejct_dis: any = "assets/ic_cancel.png";
    payoffImage: any = "assets/paidoff.png";
    sort_by: any = ''
    sort_order = ''
    sortData: { value: any; key: string; }[];
    dashboardData: any;
    EmployerAssetslist: any = [];
    EmployeeAssetslist: any = [];

    tableProps = {
        heading: 'Terminate_Employee',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: false,
        headerActions: [],
        rowActions : [
            { label: 'Cancel Termination', type: 'cancelTermination', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg' },
            // { label: 'View', type: 'viewTermination', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
            { label: 'Termination Summary', type: 'terminationSummary', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
        ],
        colHeader : {
            id: 'ID',
            name: 'Employee_Name',
            email: 'Email',
            created_at: 'Created_At',
            statusToShow: 'Status',
            actions: 'Actions'
        },
        columnTypes : {
            created_at: 'date',
            email: 'email',
            statusToShow: 'statusToShow',
            actions: 'actionsSeperate',
        },
        filterArray: []
    };

    pagination = {
        page: null,
        pages: null,
        per_page: null,
        count: 0
    }

    headersPrintCompanyPortfolio = [
        { colHeader: 'Portfolio', colKey: 'fund_name', colType: '' },
        { colHeader: 'Fund', colKey: 'asset_name', colType: '' },
        { colHeader: 'Risk Type', colKey: 'risk_type', colType: '' },
        { colHeader: 'No of Units', colKey: 'company_availble_units', colType: 'number-5' },
        { colHeader: 'Invested', colKey: 'company_invested_amount', colType: 'number-4' },
      ];

    headersPrintEmployeePortfolio = [
        { colHeader: 'Portfolio', colKey: 'fund_name', colType: '' },
        { colHeader: 'Fund', colKey: 'asset_name', colType: '' },
        { colHeader: 'Risk Type', colKey: 'risk_type', colType: '' },
        { colHeader: 'No of Units', colKey: 'availble_units', colType: 'number-5' },
        { colHeader: 'Invested', colKey: 'employee_invested_amount', colType: 'number-4' },
      ];
    constructor(public tr: TranslationService, 
        public route: Router, 
        public dec_enc: DecEncService, 
        protected mainService: MainService, 
        public paginationService: PaginationService,
        protected alertService: AlertService) { }

    ngOnInit(): void {
        this.getall(1)
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.sortOrder();
        })
        this.sortOrder();
    }

    sortOrder() {
        this.sortData = [
            {
                value: this.tr.translation.default,
                key: '',
            },
            {
                value: this.tr.translation.by_status,
                key: 'status',
            }
        ]
    }

    toggle(event, id) {
        //console.log(event.checked);
        //console.log('id==', id);
    }

    setPage(page)
    {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    getall(index: any) {
        let enc_payload = this.dec_enc.encryption({ search: this.search })
        let url = 'terminations?per_page=' + this.perPage + '&page=' + index;
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) 
            {
                let result = this.dec_enc.decryption(res.data);
                console.log('getall', result)
                this.list = result.terminations;
                this.pagination = result.pagination;
                this.afterList();
            }
        }, error => {
                this.alertService.alert('Error', this.tr.translation.serverError);
            })
    }

    afterList(){
        this.list.forEach(item => {
            item['cancelTermination'] = item.status == 0 ? true : false;
            // item['viewTermination'] = true;
            item['terminationSummary'] = true;
            item['name'] = item.employers_employees[0]?.user?.name;
            item['created_at'] = moment(item['created_at']).format(appConfig.dateformatCMS);

            if (item.status == 1 && item.bank_status == 1)
            {
                item['statusToShow'] = 'Terminated and settled';
            }
            if (item.status == 4 && item.bank_status == 1)
            {
                item['statusToShow'] = 'Terminated but not settled';
            }
            if (item.status == 5 && item.bank_status == 1)
            {
                item['statusToShow'] = 'Terminated';
            }
            if (item.employer_status == 1 && item.bank_status == 0)
            {
                item['statusToShow'] = 'Waiting For Fund Manager Approval';
            }
            if (item.status == 0)
            {
                item['statusToShow'] = 'Termination Pending';
            }
            if (item.status == 3 && item.bank_status == 3)
            {
                item['statusToShow'] = 'Termination in Progress';
            }
            if (item.status == 2 && item.employer_status == 2)
            {
                item['statusToShow'] = 'Cancelled';
            }
            if (item.status == 2 && item.bank_status == 2)
            {
                item['statusToShow'] = 'Declined By Bank';
            }
            if (item.status == 2 && item.bank_status == 0 && item.employer_status == 0)
            {
                item['statusToShow'] = 'Cancelled';
            }
        });
    }

    acceptReject(id, val) {
        if (val == 1) {
            this.alertService.alertAsk('Alert', 'Do you want to accept this request?', 'Yes', 'No', false).then(result => {
                if (result == true) {
                    let data = {
                        id: id,
                        employer_status: val
                    }
                    let enc_payload = this.dec_enc.encryption(data);
                    this.mainService.postData('withdrawals/update', { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            this.alertService.alert('Success', 'Withdrawal request has been sent to the fund manager for approval.');
                            this.getall(this.currentPage)
                        }
                        else {
                            if (localStorage.getItem('lang') == 'ar')
                            {
                                this.alertService.alert('Error', res.error.message_ar);
                            }
                            else
                            {
                                this.alertService.alert('Error', res.error.message);
                            }
                        }
                    },
                        error => {
                            this.alertService.alert('Error', this.tr.translation.serverError);
                        }
                    )
                }
            })
        }
        else {
            this.alertService.alertAsk('Alert', 'Do you want to cancel this request?', 'Yes', 'No', false).then(result => {
                if (result == true) {
                    let data = {
                        id: id,
                    }
                    let enc_payload = this.dec_enc.encryption(data);
                    this.mainService.postData('terminations/update', { enc_payload }).then(res => {
                        if (res.statusCode == 200) {
                            this.alertService.alert('Success', 'Termination request has been canceled successfully');
                            this.getall(this.currentPage)
                        }
                        else {
                            if (localStorage.getItem('lang') == 'ar')
                            {
                                this.alertService.alert('Error', res.error.message_ar);
                            }
                            else
                            {
                                this.alertService.alert('Error', res.error.message);
                            }
                        }
                    },
                        error => {
                            this.alertService.alert('Error', this.tr.translation.serverError);
                        }
                    )
                }
            })
        }
    }

    onSearch() {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 200);
    }

    searchData(event) {
        this.search = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 800);
    }
    
    sort(event) {
        if (event == '') {
            this.sort_order = '';
            this.sort_by = '';
        }
        else {
            this.sort_order = 'ASC'
            this.sort_by = event;
        }
        this.getall(1)
    }

    actionClicked(event)
    {
        if (event.action.label == 'Cancel Termination')
        {
            this.acceptReject(event.element.id, '2');
        }
        // if (event.action.label == 'View')
        // {
        //     this.route.navigate([`main/terminate_employee/${event.element.employee_id}`]);
        // }
        if (event.action.label == 'Termination Summary')
        {
            this.printTerminationSummary(event.element.employee_id);
        }
    }

    onPrint(): void {
        const printContent = document.getElementById('print');
        printJS({
          printable: printContent.innerHTML,
          type: 'raw-html'
        });
    }

    printTerminationSummary(id) {
        let enc_payload = this.dec_enc.encryption({ employee_id: id });
        this.mainService.postData('terminations/summary', { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                this.dashboardData = this.dec_enc.decryption(res.data);
                if (this.dashboardData.employee_assets.length > 0)
                {
                    this.dashboardData.employee_assets.forEach(item => {
                        item.availble_units = isNaN(Number(item.availble_units)) ? Number(0).toFixed(5) : Number(item.availble_units).toFixed(5);
                        item.employee_invested_amount = isNaN(Number(item.employee_invested_amount)) ? Number(0).toFixed(4) : Number(item.employee_invested_amount).toFixed(4);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() +item['risk_type'].slice(1)  : 'N/A';
                    });
                    this.EmployeeAssetslist = this.dashboardData.employee_assets;
                }
                if (this.dashboardData.company_assets.length > 0)
                {
                    this.dashboardData.company_assets.forEach(item => {
                        item.company_availble_units = isNaN(Number(item.company_availble_units)) ? Number(0).toFixed(5) : Number(item.company_availble_units).toFixed(5);
                        item.company_invested_amount = isNaN(Number(item.company_invested_amount)) ? Number(0).toFixed(4) : Number(item.company_invested_amount).toFixed(4);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() +item['risk_type'].slice(1)  : 'N/A';
                    });
                    this.EmployerAssetslist = this.dashboardData.company_assets;
                }
                console.log("printTerminationSummary", result);
                setTimeout(() => {
                    this.onPrint();
                }, 800);
            }
            else
            {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', res.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', res.error.message);
                }
            }
        })
    }
}