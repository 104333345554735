import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { Label, BaseChartDirective } from 'ng2-charts';
import * as Highcharts from 'highcharts';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';

@Component({
    selector: 'app-manage-portfolio',
    templateUrl: './manage-portfolio.component.html',
    styleUrls: ['./manage-portfolio.component.scss']
})
export class ManagePortfolioComponent implements OnInit {
    list: any = [];
    totalPages: number;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    currentPage: any = 1;
    count: any;
    employer_contribution: any;
    employee_contribution: any;
    profit: any;
    vested: any;
    unvested: any;
    fund: any;
    id: any;
    emp_id: any;
    total: any;
    items: any;
    Form: any;
    vestedAmountPercent: number;
    unvestedAmountPercent: number;
    employees_stats: any = [];
    totalBalance: number;

    // pie chart settings
    @ViewChild(BaseChartDirective) chart: BaseChartDirective;
    portfolioPieChartLabels: Label[] = [['Company Contribution'], ['Employee Contribution'], 'Profit/Loss'];
    portfolioPieChartData = [
        {
            data: [],
            backgroundColor: ['#213060', '#79BBBF', '#CCCCCC'],
        },
    ];

    vestingPieChartLabels: Label[] = [['Vested'], 'Unvested'];
    vestingPieChartData = [
        {
            data: [],
            backgroundColor: ['#213060', '#79BBBF', '#CCCCCC'],
        },
    ];
    public pieChartLegend = false;
    hideButton: boolean=false;

    constructor(protected _route: ActivatedRoute,
        public tr: TranslationService,
        public route: Router,
        public dec_enc: DecEncService,
        protected mainService: MainService,
        public paginationService: PaginationService,
        public dialog: MatDialog,
        protected formbuilder: FormBuilder,
        protected alertService: AlertService) {
        this._route.params.subscribe(params => {
            this.id = params['id'];

        });
        this.emp_id = localStorage.getItem('employer_id')
        this.Form = this.formbuilder.group({
            vested_amount_percent: ['', [Validators.required, Validators.pattern('^(0|[1-9][0-9]?|100)$')]],
            employee_id: [this.id],
        });
    }

    ngOnInit(): void {
        this.getall()
        // this.pieChartBrowser();
        // this.pieChartBrowserB();

    }

    dashboardData: any;
    employeeTableProps = {
        hasSearch: false,
        hasButton: false,
        headerActions: [],
        rowActions : [],
        colHeader: {
            fund_name: "Portfolio",
            asset_name: "Fund",
            risk_type: "Risk Type",
            availble_units: "No of Units",
            employee_invested_amount: "Invested"
        },
        columnTypes: {
            availble_units: "number-5",
            employee_invested_amount: "number-4"
        },
        filterArray: []
    };

    companyTableProps = {
        hasSearch: false,
        hasButton: false,
        headerActions: [],
        rowActions : [],
        colHeader: {
            fund_name: "Portfolio",
            asset_name: "Fund",
            risk_type: "Risk Type",
            company_availble_units: "No of Units",
            company_invested_amount: "Invested"
        },
        columnTypes: {
            company_availble_units: "number-5",
            company_invested_amount: "number-4"
        },
        filterArray: []
    };
    getall() {
        let enc_payload = this.dec_enc.encryption({ employer_id: this.emp_id, employee_id: this.id })
        let url = 'home/employee/portfolio'
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {


                this.dashboardData = this.dec_enc.decryption(res.data);
                if (this.dashboardData.employee_assets.length > 0)
                {
                    this.dashboardData.employee_assets.forEach(item => {
                        item.availble_units = !item.availble_units || isNaN(item.availble_units) ? 0 : Number(item.availble_units);
                        item.employee_invested_amount = !item.employee_invested_amount || isNaN(item.employee_invested_amount) ? 0 : Number(item.employee_invested_amount);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() +item['risk_type'].slice(1)  : 'N/A';
                    });
                }
                if (this.dashboardData.company_assets.length > 0)
                {
                    this.dashboardData.company_assets.forEach(item => {
                        item.company_availble_units = !item.company_availble_units || isNaN(item.company_availble_units) ? 0 : Number(item.company_availble_units);
                        item.company_invested_amount = !item.company_invested_amount || isNaN(item.company_invested_amount) ? 0 : Number(item.company_invested_amount);
                        item['risk_type'] = item['risk_type'] ? item['risk_type'].charAt(0).toUpperCase() +item['risk_type'].slice(1)  : 'N/A';
                    });
                }
                console.log('portfolio = ', this.dashboardData);
                // let result;
                // let data = res.data

                // result = this.dec_enc.decryption(data);
                // console.log('portfolio = ', result);
                // if (result.employeeDetails.termination_status == "2" && (result.employeeDetails.status == "0" || result.employeeDetails.status == "1"))
                // {
                //     this.hideButton=true;
                // }
                // else
                // {
                //     this.hideButton=false;
                // }

                // this.list = result.employees_aggregate;
                // this.items = result;
                // this.employees_stats = result?.employees_stats;

                // if (this.list !== null) {
                //     this.employee_contribution = Number(this.employees_stats?.contribution_amount)
                //     this.employer_contribution = Number(this.employees_stats?.employer_amount)
                //     this.profit = Number(this.employees_stats?.new_profit)
                //     this.totalBalance = Number(this.employees_stats?.total_balance)
                //     this.vested = Number(this.list?.vested_amount)
                //     this.unvested = Number(this.list?.un_vested_amount)
                //     this.unvestedAmountPercent = Number(this.list?.un_vested_amount_percent)
                //     this.vestedAmountPercent = Number(this.list?.vested_amount_percent)

                //     // console.log('pie chart = ',  this.profit);
                //     this.total = this.employee_contribution + this.employer_contribution + this.profit - Number(this.list?.withdrawals_amount)
                // }
                // // this.pieChartBrowser();
                // // this.pieChartBrowserB();
                this.portfolioBreakdownChart();
                this.vestingChart();
            }

        },
            error => {

                this.alertService.alert('Error', this.tr.translation.serverError, '');
                // let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                // let cm = dialogRef.componentInstance;
                // cm.heading = this.tr.translation.error;
                // cm.message = this.tr.translation.serverError;
                // cm.cancelButtonText = this.tr.translation.okay;
                // cm.type = 'error';
            }
        )
    }
    back() {
        window.history.back();
    }
    pieChartBrowser() {
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.pieChatBrowserCTranslaion();
        })

        this.pieChatBrowserCTranslaion();
    }
    pieChatBrowserCTranslaion() {
        Highcharts.chart('pieChart', {
            chart: {
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            credits: {
                enabled: false
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    size: '60%',
                    innerSize: '70%'
                }
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                text: '<p style="font-size: 17px;font-family: sans-serif !important;"><span style="font-weight:900;color:#054792; font-family: sans-serif">SAR ' + this.tr.numberFormatter2(this.totalBalance) + '</span><br><span><br>' + this.tr.translation.total_portFolio + '</span></p>',
                // text: '<p style="font-size: 17px;font-family: sans-serif !important;"><span style="font-weight:900;color:#054792; font-family: sans-serif">SAR ' + Number(this.list?.total_balance_amount) + '</span> <br> <span style="font-weight: 700;">'+this.tr.translation.total_portFolio+'</span></p>',
            },

            series: [{
                name: 'Brands',
                colorByPoint: true,
                type: undefined,
                data: [
                    {
                        name: this.tr.translation.Employer_Contribution,
                        y: this.employer_contribution,
                        color: '#004390',
                        type: 'pie'

                    }, {
                        name: this.tr.translation.profit,
                        y: this.profit,
                        color: '#E80F1F',
                        type: 'pie'
                    },
                    {
                        name: this.tr.translation.Employee_Contribution,
                        y: this.employee_contribution,
                        color: '#687784',
                        type: 'pie'
                    }
                ]

            }]

        });

    }

    pieChartBrowserB() {
        this.tr.selectedApp.subscribe((state) => {
            this.tr.translation = state;
            this.pieChatBrowserBTranslaion();
        })

        this.pieChatBrowserBTranslaion();
    }
    pieChatBrowserBTranslaion() {
        Highcharts.chart('pieChartB', {
            chart: {
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            credits: {
                enabled: false
            },

            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    size: '60%',
                    innerSize: '70%',

                }
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                // text: '<p style="font-size: 17px;font-family: sans-serif !important;"><span style="font-weight:900;color:#054792; font-family: sans-serif">SAR ' + Number(this.employer_contribution) + '</span>  <span style="font-weight: 700;"><br>'+this.tr.translation.emp_contri+'</span></p>',
                text: '<p style="font-size: 17px;font-family: sans-serif !important;"><span style="font-weight:900;color:#054792; font-family: sans-serif">SAR ' + this.tr.numberFormatter2(Number(this.employer_contribution)) + '</span> <br> <span>' + this.tr.translation.emp_contri + '</span></p>',
            },
            series: [{

                colorByPoint: true,
                type: undefined,

                data: [
                    {

                        name: this.tr.translation.Vestedd,
                        y: this.vested,
                        color: '#08BFCE',
                        type: 'pie'
                    }, {
                        name: this.tr.translation.Unvested,
                        y: this.unvested,
                        color: '#71C9FF',
                        type: 'pie'
                    }
                ]
            }]
        });
    }

    onSubmit() {
        if (this.Form.get('vested_amount_percent').value > 100) {
            this.alertService.alert('Error', 'Percentage cannot be greater than 100.');
            return;
        }
        let url = "employees/shift_amount";
        this.Form.get("employee_id").setValue(this.id);
        let payload = this.dec_enc.encryption(this.Form.value);
        let enc_payload = payload;


        this.mainService.postData(url, { enc_payload }).then(result => {
            if (result.statusCode == 200) {
                this.alertService.alert('Success', this.tr.translation.amountShifted);
                this.Form.reset()
                this.getall()
            }
            else {
                if (localStorage.getItem('lang') == 'ar') {
                    this.alertService.alert('Error', result.error.message_ar);
                }
                else {
                    this.alertService.alert('Error', result.error.message);
                }
            }
        },
            error => {
                this.alertService.alert('Error', this.tr.translation.serverError, '');
                // let dialogRef = this.dialog.open(AlertDialog, { autoFocus: false });
                // let cm = dialogRef.componentInstance;
                // cm.heading = this.tr.translation.error;
                // cm.message = this.tr.translation.serverError;
                // cm.cancelButtonText = this.tr.translation.okay;
                // cm.type = 'error';
            });
    }

    portfolioBreakdownChart() {
        let data = [];
        data.push(this.dashboardData.summary.company_contribution);
        data.push(this.dashboardData.summary.employee_contribution);
        data.push(this.dashboardData.summary.profit);
        this.portfolioPieChartData[0].data = data;
        this.chart.update();
    }

    vestingChart() {
        let data = [];
        data.push(this.dashboardData.summary.total_vested);
        data.push(this.dashboardData.summary.total_unvested);
        this.vestingPieChartData[0].data = data;
        this.chart.update();
    }
}
