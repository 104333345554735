<div>
  <mat-card>
    <div style="font-size: 10px;" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayoutGap="10">
      <div class="tableHeading" fxFlex="22" fxFlex.sm="30" fxFlex.xs="100" fxFlexAlign="center">
        <h2 style="margin: 0; font-weight: bold; color: #213060;">{{tableProps?.heading | translate}} </h2>
      </div>
      <div fxFlex="78" fxFlex.sm="69" fxFlex.xs="100"
        style="text-align: end; justify-content: end; display: flex; align-items: center;">

        <ng-container *ngIf="tableProps?.hasButton">
          <button matTooltip="{{item.label | translate}}" style="display: flex; justify-content: center;"
            *ngFor="let item of tableProps?.headerActions" (click)="onTableHeaderButton(item)"
            [ngStyle]="item.visibility ? {'display':'flex'} : {'display':'none'}" [disabled]="item.disabled"
            class="btn-table-action" mat-raised-button [ngClass]="{'btn-table-action-selected':item.selected}">
            <mat-icon *ngIf="item.icon">{{item.icon}}</mat-icon> <span fxHide.xs fxHide.sm fxHide.md>{{item.label |
                            translate}}</span>
          </button>
        </ng-container>

        <ng-container *ngIf="tableProps?.filterArray.length > 0">
          <ng-container *ngFor="let filter of tableProps?.filterArray">
            <mat-form-field fxFlex="200px" *ngIf="filter.visibility"  class="input-field"
              appearance="outline"  style="margin-inline: 2px;">
              <!-- <span matPrefix>
                                <mat-icon *ngIf="filter.type !== 'clear-filter'" style="height: 20px;">
                                    <span *ngIf="filter.type == 'filter'">filter_list</span>
                                    <span *ngIf="filter.type == 'search'">search</span>
                                    <span *ngIf="filter.type == 'date'">event_note</span>
                                </mat-icon>
                                <mat-icon *ngIf="filter.type === 'clear-filter'" style="height: 20px; cursor: pointer;"
                                    matPrefix>
                                    <span *ngIf="filter.selected"
                                        (click)="onClearData($event, 'filter', filter)">close</span>
                                    <span *ngIf="filter.selected == null || filter.selected == undefined">search</span>
                                </mat-icon>
                            </span> -->
              <mat-label>{{filter.label | translate}}</mat-label>
              <mat-select *ngIf="!filter.toolTip" [(ngModel)]="filter.selected" (selectionChange)="onChangeFilters(filter)">
                <mat-option *ngFor="let option of filter.options" [value]="option?.value">
                  {{option?.label | translate}}
                </mat-option>
              </mat-select>
              <mat-select *ngIf="filter.toolTip" [(ngModel)]="filter.selected" (selectionChange)="onChangeFilters(filter)">
                <mat-option matToolTipDisabled="filter.toolTip" matTooltip="{{option?.label | translate}}" *ngFor="let option of filter.options" [value]="option?.value">
                  {{option?.label | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </ng-container>

        <div *ngIf="tableProps?.filterArray.length > 0" style="width:3px"></div>

        <ng-container *ngIf="tableProps?.dateFilter?.hasDatePicker">
          <form [formGroup]="Form">
            <mat-form-field class="input-field" appearance="outline" fxFlex="200px" style="margin-inline: 2px;">
              <mat-label>{{tableProps?.dateFilter?.startLable | translate}}</mat-label>
              <input readonly matInput [matDatepicker]="start" [max]="currentDate" formControlName="start"
                (dateChange)="onChangeDateFilter()">
              <mat-icon style="cursor: pointer;" *ngIf="Form.get('start').value" matPrefix
                (click)="onDateClear('start')">close
              </mat-icon>
              <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
              <mat-datepicker #start></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="tableProps?.dateFilter?.type == 'dateRange'" class="input-field" appearance="outline"
              fxFlex="200px" style="margin-inline: 2px;">
              <mat-label>{{tableProps?.dateFilter?.endLable | translate}}</mat-label>
              <input readonly matInput [matDatepicker]="end" [min]="Form.get('start').value"
                [disabled]="Form.get('start').value == null" formControlName="end" (dateChange)="onChangeDateFilter()">
              <mat-icon style="cursor: pointer;" *ngIf="Form.get('end').value" matPrefix (click)="onDateClear('end')">
                close
              </mat-icon>
              <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
              <mat-datepicker #end></mat-datepicker>
            </mat-form-field>
          </form>
        </ng-container>

        <div *ngIf="tableProps?.dateFilter?.hasDatePicker" style="width:3px"></div>

        <ng-container *ngIf="tableProps?.hasSearch">
          <mat-form-field fxFlex="200px" style="margin-inline: 2px;" class="input-field" appearance="outline">
            <mat-label>{{tableProps.searchLabel | translate}}</mat-label>
            <input matInput [(ngModel)]="search" (keyup)="onSearch()">
            <mat-icon matPrefix>search</mat-icon>
            <mat-icon style="cursor: pointer;" *ngIf="search" matSuffix (click)="onSearchClear()">close</mat-icon>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="tableProps?.hasMoreLinks">
          <div>
            <button [matMenuTriggerFor]="menuHeader"
              style="border-radius: 10px; background-color: #F7F8F9; transform: scale(0.9);" mat-raised-button>
              <mat-icon>more_horiz</mat-icon>
            </button>
            <mat-menu class="action-menu" #menuHeader="matMenu" xPosition="before" style="padding-inline: 10px;">
              <button class="btn-action" style="outline: none;" mat-menu-item
                *ngFor="let item of tableProps?.rowActions"
                [ngStyle]="item.visibility ? {'display':'flex'} : {'display':'none'}"
                (click)="onTableHeaderButton(item)">
                <mat-icon class="action-icon" *ngIf="item.icon" [style.background-color]="item.background"
                  [style.color]="item.color" style="border-radius: 50%; color: white;">{{item.icon}}</mat-icon>
                <img style="width: 16px;" *ngIf="item.source" [src]="item.source">
                <span style="font-size: 12px;">{{item.label | translate}}</span>
              </button>
            </mat-menu>
          </div>
        </ng-container>
      </div>
    </div>

    <table matSort matSortActive="position" matSortDirection="asc" matSortDisableClear mat-table
      [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8 mt-10">

      <ng-container [matColumnDef]="tableData" *ngFor="let tableData of objectKeys(tableProps?.colHeader)">

        <th mat-header-cell mat-sort-header disableClear *matHeaderCellDef>
          <div style="display: flex; align-items: center; color: #A3AED0;max-width: 130px;">
            <div style="margin-right: 5px;">{{tableProps?.colHeader[tableData] | translate}}</div>
            <mat-icon *ngIf="tableProps?.colHeader[tableData] != ''">expand_more</mat-icon>
          </div>

          <!-- Checkbox for the top column -->
          <mat-checkbox class="example-margin" *ngIf="tableProps?.colHeader[tableData] == ''" color="primary"
            (change)="checkBoxAll($event.checked)" [(ngModel)]="headerCheckBoxValue" [checked]="isChecked()">
          </mat-checkbox>
        </th>

        <!-- Checkboxes for data rows -->
        <ng-container *ngIf="tableProps?.colHeader[tableData] == ''">
          <td style="padding-right: 24px;" mat-cell *matCellDef="let row">
            <mat-checkbox *ngIf="!row['dontShowCB']" class="example-margin" color="primary"
              (click)="$event.stopPropagation()" (change)="checkBox($event, row)" [checked]="row['checked'] == true">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Normal column -->
        <ng-container
          *ngIf="tableProps.columnTypes[tableData] === null || tableProps.columnTypes[tableData] === undefined">
          <td style="font-weight: bold;" mat-cell *matCellDef="let element"> {{element[tableData]}} </td>
        </ng-container>

        <!-- Column with number format -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'number'">
          <td style="font-weight: bold; font-size: 14px;" mat-cell *matCellDef="let element"> 
            <!-- {{element[tableData] | number : '1.2-2'}} -->
            <span>{{element[tableData] | number:'1.2-2' | slice:0:-2}}</span>
            <span style="font-size: 11px;">{{element[tableData] | number:'1.2-2' | slice:-2}}</span>
          </td>
        </ng-container>

        <!-- Column with number format 4 decimal -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'number-4'">
          <td style="font-weight: bold; font-size: 14px;" mat-cell *matCellDef="let element"> 
            <!-- {{element[tableData] | number : '1.4-4'}} -->
            <span>{{element[tableData] | number:'1.4-4' | slice:0:-4}}</span>
            <span style="font-size: 11px;">{{element[tableData] | number:'1.4-4' | slice:-4}}</span>
          </td>
        </ng-container>

        <!-- Column with number format 5 decimal -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'number-5'">
          <td style="font-weight: bold; font-size: 14px;" mat-cell *matCellDef="let element"> 
            <!-- {{element[tableData] | number : '1.5-5'}} -->
            <span>{{element[tableData] | number:'1.5-5' | slice:0:-5}}</span>
            <span style="font-size: 11px;">{{element[tableData] | number:'1.5-5' | slice:-5}}</span>
          </td>
        </ng-container>


        <!-- Column warning  > with subColumnTypesWarning-->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'columnWarning'">
          <td style="font-weight: bold;" mat-cell *matCellDef="let element">
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
            <mat-icon *ngIf="element[tableData] != null && element['columnWarning'+tableData] != null"
              style="font-size: 22px; cursor: pointer; color: #ffc107;" [matTooltip]="element['columnWarning'+tableData]">
              warning</mat-icon>
            <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;max-width: 164px;">
              <ng-container *ngIf="tableProps.subColumnTypesWarning[tableData] === 'number'">
                <!-- {{element[tableData] | number : '1.2-2'}} -->
                
                <span style="font-size: 14px;">{{element[tableData] | number:'1.2-2' | slice:0:-2}}</span>
                <span style="font-size: 11px;">{{element[tableData] | number:'1.2-2' | slice:-2}}</span>
              </ng-container>
              <ng-container *ngIf="tableProps.subColumnTypesWarning[tableData] === 'number-4'">
                <!-- <span style="font-size: 14px;">{{element[tableData] | number : '1.4-4'}}</span> -->

                <span style="font-size: 14px;">{{element[tableData] | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 11px;">{{element[tableData] | number:'1.4-4' | slice:-4}}</span>
              </ng-container>
              <ng-container *ngIf="tableProps.subColumnTypesWarning[tableData] === 'number-5'">
                <!-- {{element[tableData] | number : '1.5-5'}} -->

                <span style="font-size: 14px;">{{element[tableData] | number:'1.5-5' | slice:0:-5}}</span>
                <span style="font-size: 11px;">{{element[tableData] | number:'1.5-5' | slice:-5}}</span>
              </ng-container>
              <ng-container *ngIf="tableProps.subColumnTypesWarning[tableData] !== 'number' && 
              tableProps.subColumnTypesWarning[tableData] !== 'number-4' && 
              tableProps.subColumnTypesWarning[tableData] !== 'number-5'">
                {{element[tableData]}}
              </ng-container>
            </span>
            </div>
          </td>
        </ng-container>

        <!-- Column with translate -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'translate'">
          <td style="font-weight: bold;" mat-cell *matCellDef="let element"> {{element[tableData] |
                        translate}}
          </td>
        </ng-container>

        <!-- Column with profile image -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'image'">
          <td style="font-weight: bold;" class="td-icon" mat-cell *matCellDef="let element">
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
              <img class="table-icon" src="../../assets/Avatar.png">
              <div>{{element[tableData]}}</div>
            </div>
          </td>
        </ng-container>

        <!-- Column with info icon -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'infoColumn'">
          <td style="font-weight: bold;" class="td-icon" mat-cell *matCellDef="let element">
            <div fxLayoutAlign="center center" fxLayoutGap="5px">
              <mat-icon *ngIf="element[tableData] != null" style="font-size: 22px; cursor: pointer;"
                matTooltip="{{element['infoColumn'] | translate}}"
                [ngStyle]="{'color': element[tableData] == 'yes' ? '#05CD99' : '#EE5D50'}">info</mat-icon>
              <span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;max-width: 164px;">{{
                                element[tableData] | translate}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Column with mail icon -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'email'">
          <td class="td-email" mat-cell *matCellDef="let element">
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
              <img class="table-icon" style="height: 11px;" src="../../assets/email.png">
              <div><u>{{element[tableData]}}</u> </div>
            </div>
          </td>
        </ng-container>

        <!-- Column with calender icon -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'date'">
          <td class="td-email" mat-cell *matCellDef="let element">
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
              <img class="table-icon" style="height: 14px; cursor: default;" src="../../assets/images/calendar.png">
              <div>{{element[tableData]}}</div>
            </div>
          </td>
        </ng-container>

        <!-- Column with status color -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'statusToShow'">
          <td class="td-email" mat-cell *matCellDef="let element"
            [ngStyle]="{'color': element[tableData] == 'Pending' ? '#E07B25':element[tableData] == 'Completed' ? '#00C435' : element[tableData] == 'Rejected' ? '#E80F1F' : element[tableData] == 'Under Process' ? '#E07B25' : element[tableData] == 'Declined' ? '#E80F1F' : element[tableData] == 'Termination Pending' ? '#E07B25' : element[tableData] == 'Termination in Progress' ? '#E07B25' : element[tableData] == 'Active' ? '#00C435' : '#E80F1F'}">
            <div *ngIf="element[tableData] != 'Terminated but not settled'"
              style="display:flex; flex-direction: row; justify-content: senter; align-items: center; font-weight: bold; Gap:5px; line-height: 40px;">
              <div style="width:15px; height: 15px; border-radius: 50px;"
                [ngStyle]="{'background': element[tableData] == 'Pending' ? '#E07B25':element[tableData] == 'Completed' ? '#00C435' : element[tableData] == 'Rejected' ? '#E80F1F' : element[tableData] == 'Under Process' ? '#E07B25' : element[tableData] == 'Declined' ? '#E80F1F' : element[tableData] == 'Termination Pending' ? '#E07B25' : element[tableData] == 'Termination in Progress' ? '#E07B25' : '#E80F1F'}">
              </div>{{element[tableData] | translate}}
            </div>
            <div
              style="display:flex; flex-direction: row; justify-content: senter; align-items: center; font-weight: bold; Gap:5px; line-height: 40px;"
              *ngIf="element[tableData] == 'Terminated but not settled'" fxLayoutAlign="start center"
              [matTooltip]="element[tableData] == 'Terminated but not settled' ? 'To complete the termination process of this employee, please navigate to Withdrawal Requests screen and mark the corresponding withdrawal as settled.' : element[tableData]">
              <mat-icon style="width: 18px; height: 18px; font-size: 18px;">info</mat-icon>
              <span>{{element[tableData] | translate}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'statusToPaidOFF'">
          <td class="td-email" style="font-size: 13px;font-weight: bold;" mat-cell *matCellDef="let element"
            [ngStyle]="{'color': element[tableData] == 'Pending' ? '#E07B25':element[tableData] == 'Paid off' ? '#00C435' : '#E07B25'}">
            {{element[tableData] | translate}} </td>
        </ng-container>


        <!-- Column with error indicator -->

        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'errorColumn'">
          <td mat-cell *matCellDef="let element">
            <div fxLayoutAlign="start center" fxLayoutGap="5px">
              <mat-icon *ngIf="element[tableData] != null" class="error-action-icon"
                [ngStyle]="{'background-color': element[tableData] == 'Verified' || element[tableData] == 'No Errors' || element[tableData] == 'Accepted' || element[tableData] == 'Activated' ? '#05CD99' : '#EE5D50'}">
                {{element[tableData] == 'Verified' || element[tableData] == 'No Errors' || element[tableData] == 'Accepted' || element[tableData] == 'Activated' ? 'done'
                                : element[tableData] == 'Terminated but not settled' ? 'info'
                                : 'close'}}</mat-icon>
              <span matTooltipClass="mat-tooltip-class-here"
                [matTooltip]="element[tableData] == 'Terminated but not settled' ? 'To complete the termination process of this employee, please navigate to Withdrawal Requests screen and mark the corresponding withdrawal as settled.' : element[tableData]"
                [matTooltipDisabled]="element[tableData] == 'Verified' || element[tableData] == 'No Errors' || element[tableData] == 'Accepted' || element[tableData] == 'Activated'"
                style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;max-width: 164px;">{{
                                element[tableData] | translate}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Actions Menu column -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'actionsMenu'">
          <td mat-cell *matCellDef="let element; let index = index">
            <mat-icon style="height: auto; width: auto;" [matMenuTriggerFor]="menu" class="actions"
              *ngIf="rowActionsVisibility(element)">
              more_horiz
            </mat-icon>
            <mat-menu class="action-menu" #menu="matMenu" xPosition="before" style="padding-inline: 10px;">
              <button class="btn-action" style="outline: none;" mat-menu-item
                *ngFor="let item of tableProps?.rowActions"
                [ngStyle]="item.visibility && element && (element[item.type] || !item.isCustom) ? {'display':'flex'} : {'display':'none'}"
                (click)="onRowActionClicked(element, item, index)">
                <mat-icon class="btn-action-icon" *ngIf="item.icon" [style.background-color]="item.background"
                  [style.color]="item.color">{{item.icon}}
                </mat-icon>
                <img class="btn-action-image" *ngIf="item.source" [src]="item.source">
                <span class="btn-action-text" [style.color]="item.color">{{item.label |
                                    translate}}</span>
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <!-- Actions Seperate column -->
        <ng-container *ngIf="tableProps.columnTypes[tableData] === 'actionsSeperate'">
          <td mat-cell *matCellDef="let element; let index = index" style="display: flex; margin-left: 5px;">
            <button class="btn-action" *ngFor="let item of tableProps?.rowActions"
              [ngStyle]="item.visibility && element && (element[item.type] || !item.isCustom) ? {'display':'flex'} : {'display':'none'}"
              (click)="onRowActionClicked(element, item, index)" [style.background-color]="item.background">
              <mat-icon class="btn-action-icon" *ngIf="item.icon" [style.background-color]="item.background"
                [style.color]="item.color">{{item.icon}}
              </mat-icon>
              <img class="btn-action-image" *ngIf="item.source" [src]="item.source">
              <span class="btn-action-text" [style.color]="item.color">{{item.label | translate}}</span>
            </button>
          </td>
        </ng-container>

      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container *ngIf="tableProps?.isExpandable" matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="objectKeys(tableProps?.colHeader).length - 1">
          <div class="example-element-detail"
            [@detailExpand]="(element == expandedElement || allRowsExpanded) ? 'expanded' : 'collapsed'">
            <table style="width: 100%;">
              <tr>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'Date of Birth' | translate}}
                </th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'Nationality' | translate}}
                </th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'Gender' | translate}}</th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'ID Expiry Date' | translate}}
                </th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'Address' | translate}}</th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'Designation' | translate}}
                </th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">
                  {{'Dual Nationality' | translate}}
                </th>
                <th style="font-size: 12px; font-weight: 500; color: rgba(0,0,0,.54);">{{'Group' | translate}}</th>
              </tr>
              <mat-divider *ngIf="element == expandedElement"></mat-divider>
              <tr>
                <td style="font-weight: bold; font-size: 11px;">{{element.date_of_birth}}</td>
                <td style="font-weight: bold; font-size: 11px;">{{element.nationality}}</td>
                <td style="font-weight: bold; font-size: 11px;">{{element.gender}}</td>
                <td style="font-weight: bold; font-size: 11px;">{{element.national_id_or_iqama_expiry}}
                </td>
                <td style="font-weight: bold; font-size: 11px;">{{element.address}}</td>
                <td style="font-weight: bold; font-size: 11px;">{{element.designation}}</td>
                <td style="font-weight: bold; font-size: 11px;">{{element.dual_nationality_holder}}</td>
                <td style="font-weight: bold; font-size: 11px;">{{element.group}}</td>
              </tr>
            </table>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="objectKeys(tableProps?.colHeader)"></tr>
      <ng-container *ngIf="!tableProps?.isExpandable">
        <tr mat-row *matRowDef="let row; columns: objectKeys(tableProps?.colHeader);"></tr>
      </ng-container>
      <ng-container *ngIf="tableProps?.isExpandable">
        <tr mat-row *matRowDef="let row; columns: objectKeys(tableProps?.colHeader); let i = dataIndex"
          class="example-element-row" [class.example-expanded-row]="expandedElement === row">
          <!-- (click)="expandedElement = expandedElement === row ? null : row" -->
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </ng-container>
    </table>
    <div class="norecord" *ngIf="dataSource?.length === 0">{{'No_Record_Found' | translate}}</div>
  </mat-card>


  <tp-pagination *ngIf="pagination" class="mt-20" (setPage)="setPagination($event)" [pagination]="pagination">
  </tp-pagination>
</div>
