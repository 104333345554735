<h3 class="form-heading" *ngIf="routeType == 'add'">{{'Create_Employee' | translate}}</h3>
<h3 class="form-heading" *ngIf="routeType == 'update'">{{'Update_Employee' | translate}}</h3>
<h3 class="form-heading" *ngIf="routeType == 'view'">{{'view_Employee' | translate}}</h3>

<mat-horizontal-stepper [selectedIndex]="currentIndexOfStepper" (selectionChange)="onStepChange($event)" [linear]="stopStepper"
    #stepper>
    <mat-step [completed]="false" [stepControl]="Form">
        <form [formGroup]="Form">
            <ng-template matStepLabel>{{'Employee_Basic_Details' | translate}}</ng-template>
            <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Name' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="name">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'name')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'name', 'Name_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'name')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Phone' | translate}}</mat-label>
                    <input dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="phone">
                    <span *ngIf="!isArabic" matPrefix>+966</span>
                    <span dir="ltr" *ngIf="isArabic" matSuffix>+966</span>
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'phone')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'phone', 'Phone_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'phone')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Email' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="email">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'email')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'email', 'Email_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'email')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field class="input-field mt-10" fxFlex="24" fxFlex.sm="46" fxFlex.xs="100"
                    appearance="outline">
                    <mat-label>{{'Gender' | translate}}</mat-label>
                    <mat-select formControlName="gender">
                        <mat-option value="1">{{'male' | translate}}</mat-option>
                        <mat-option value="2">{{'Female' | translate}}</mat-option>
                        <!-- <mat-option value="0">{{'Other' | translate}}</mat-option> -->
                    </mat-select>
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'gender')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'gender', 'Gender_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'gender')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="23.3" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Date_of_Birth' | translate}}</mat-label>
                    <input (click)="openDatePicker(1)" matInput type="text" formControlName="date_of_birth" [matDatepicker]="picker1"
                        [max]="currentDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'date_of_birth')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'date_of_birth', 'DOB_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'date_of_birth')" matTooltipPosition="above"
                        matPrefix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="97" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Address' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="address">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'address')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'address', 'Address_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'address')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'National_Iqama_ID' | translate}}</mat-label>
                    <input dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="iqama_id">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'iqama_id')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'iqama_id', 'Iqama_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'iqama_id')" matTooltipPosition="above" matSuffix>
                        info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Iqama_ID_National_ID_Expiry_Date' | translate}}</mat-label>
                    <input (click)="openDatePicker(2)" matInput type="text" formControlName="iqama_expiry" [matDatepicker]="picker2"
                        [min]="currentDate" readonly />
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'iqama_expiry')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(Form, 'iqama_expiry', 'Iqama_Expiry_Required')"
                        [matTooltipDisabled]="!getFieldValue(Form, 'iqama_expiry')" matTooltipPosition="above"
                        matPrefix>info
                    </mat-icon>
                </mat-form-field>

                <mat-card class="card" fxFlex="48" fxFlex.sm="93.5" fxFlex.xs="97" fxLayout="row" fxLayout.xs="column"
                    style="height: 162px; margin-top: 10px !important; border-radius: 20px;" ngStyle.xs="height:280px;">
                    <input style="display: none;" type="file" #attack (change)="getFile($event)"
                        accept=".png, .jpg, .jpeg">
                    <button [class.upload-view-mode]="routeType == 'view'" [class.upload-view-mode-op]="routeType == 'view' && !imgToShow" mat-button class="upload-button" (click)="attack.click()">
                        <ng-container *ngIf="!imgToShow">
                            <mat-icon>upload</mat-icon>
                            <h2>{{'Upload_Files' | translate}}</h2>
                            <h5>{{'File_Types' | translate}}</h5>
                        </ng-container>
                        <ng-container *ngIf="imgToShow">
                            <img style="width: 100%; height: 100%;" [src]="imgToShow">
                        </ng-container>
                    </button>
                    <span class="button-text" fxFlexOffset="6" ngStyle.xs="margin-left:22px;">
                        <h2>{{'National_Iqama_Scanned' | translate}}</h2>
                    </span>
                </mat-card>

                <div fxLayout="row wrap" fxFlex="48" fxFlex.xs="100">
                    <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Nationality' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="nationality">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'nationality')"
                            #tooltip="matTooltip" [matTooltip]="getErrorMessage(Form, 'nationality', 'Nationality_Required')"
                            [matTooltipDisabled]="!getFieldValue(Form, 'nationality')" matTooltipPosition="above"
                            matSuffix>info
                        </mat-icon>
                    </mat-form-field>

                    <div class="radio-button" fxLayoutGap="10px" fxLayoutAlign="center center" fxFlex="48"
                        fxFlex.xs="100" fxLayout="row">
                        <label>{{'Are_You_Dual_Nationality_Holder' | translate}}</label>
                        <mat-radio-group fxLayoutGap="16" formControlName="are_you_dual_nationality_holder">
                            <mat-radio-button style="margin-right: 10px;" value="yes">{{'yes' | translate}}</mat-radio-button>
                            <mat-radio-button value="no">{{'no' | translate}}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{'Employee_Since' | translate}}</mat-label>
                        <input (click)="openDatePicker(3)" matInput type="text" formControlName="employee_since" [matDatepicker]="picker3"
                            [max]="currentDate" readonly />
                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3></mat-datepicker>
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'employee_since')"
                            #tooltip="matTooltip"
                            [matTooltip]="getErrorMessage(Form, 'employee_since', 'Employee_Since_Required')"
                            [matTooltipDisabled]="!getFieldValue(Form, 'employee_since')" matTooltipPosition="above"
                            matPrefix>info
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{'Position' | translate}}</mat-label>
                        <input autocomplete="off" type="text" matInput formControlName="position">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'position')" #tooltip="matTooltip"
                            [matTooltip]="getErrorMessage(Form, 'position', 'Position_Required')"
                            [matTooltipDisabled]="!getFieldValue(Form, 'position')" matTooltipPosition="above"
                            matSuffix>info
                        </mat-icon>
                    </mat-form-field>
                </div>

            </div>
            <div fxLayoutAlign="space-between center" fxLayoutGap="10px" style="margin: 15px 30px;">
                <div fxFlex="80">
                    <mat-form-field *ngIf="routeType != 'add'" fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{'Comments' | translate}}</mat-label>
                        <input autocomplete="off" type="text" readonly matInput [formControl]="comments">
                    </mat-form-field>
                </div>

                <div style="display: flex; gap: 10px;">
                    <button (click)="onCancel()" style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;" mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="routeType != 'view'" (click)="basicDetailSubmit()" class="btn-submit" mat-raised-button>{{'Next' | translate}}</button>
                    <button *ngIf="routeType == 'view'" (click)="onNext()" class="btn-submit" mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </div>
        </form>
    </mat-step>

    <mat-step completed="false" optional [stepControl]="kinForm">
        <form [formGroup]="kinForm">
            <ng-template matStepLabel>{{'Next_of_Kin_Information' | translate}}</ng-template>

            <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Relationship_Next_Kin' | translate}}</mat-label>
                    <mat-select formControlName="relation_to_nok">
                        <mat-option value="son">{{'son' | translate}}</mat-option>
                        <mat-option value="daughter">{{'daughter' | translate}}</mat-option>
                        <mat-option value="wife">{{'wife' | translate}}</mat-option>
                        <mat-option value="husband">{{'husband' | translate}}</mat-option>
                        <mat-option value="father">{{'father' | translate}}</mat-option>
                        <mat-option value="mother">{{'mother' | translate}}</mat-option>
                        <mat-option value="brother">{{'brother' | translate}}</mat-option>
                        <mat-option value="sister">{{'Sister' | translate}}</mat-option>
                        <mat-option value="other">{{'Other' | translate}}</mat-option>
                    </mat-select>
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(kinForm, 'relation_to_nok')"
                        #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(kinForm, 'relation_to_nok', 'Relation_Kin_Required')"
                        [matTooltipDisabled]="!getFieldValue(kinForm, 'relation_to_nok')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'FirstName' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="first_name">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(kinForm, 'first_name')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(kinForm, 'first_name', 'First_Name_Required')"
                        [matTooltipDisabled]="!getFieldValue(kinForm, 'first_name')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'LastName' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="last_name">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(kinForm, 'last_name')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(kinForm, 'last_name', 'Last_Name_Required')"
                        [matTooltipDisabled]="!getFieldValue(kinForm, 'last_name')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="22" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Contactdetails' | translate}}</mat-label>
                    <input dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="contact_details">
                    <span *ngIf="!isArabic" matPrefix>+966</span>
                    <span dir="ltr" *ngIf="isArabic" matSuffix>+966</span>
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(kinForm, 'contact_details')"
                        #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(kinForm, 'contact_details', 'Contact_Det_Required')"
                        [matTooltipDisabled]="!getFieldValue(kinForm, 'contact_details')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <div class="radio-button" fxFlex="27" fxLayoutGap="10px" fxLayoutAlign="center start" fxFlex.xs="100"
                    fxLayout="column">
                    <label [class.valid-label]="!kinForm.get('power_of_attorney').invalid"
                        [class.invalid-label]="kinForm.get('power_of_attorney').touched && kinForm.get('power_of_attorney').invalid">{{'Power_of_Attorney_Question' | translate}}</label>
                    <mat-radio-group fxLayoutGap="16" formControlName="power_of_attorney">
                        <mat-radio-button style="margin-right: 10px;" value="1">{{'yes' | translate}}</mat-radio-button>
                        <mat-radio-button value="0">{{'no' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>

            <div fxLayoutAlign="space-between center" fxLayoutGap="10px" style="margin: 15px 30px;">
                <div fxFlex="80">
                    <mat-form-field *ngIf="routeType != 'add'" fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{'Comments' | translate}}</mat-label>
                        <input autocomplete="off" type="text" readonly matInput [formControl]="comments">
                    </mat-form-field>
                </div>

                <div style="display: flex; gap: 10px;">
                    <button (click)="onCancel()" style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;" mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="routeType != 'view'" (click)="kinSubmit()" class="btn-submit" mat-raised-button>{{'Next' | translate}}</button>
                    <button *ngIf="routeType == 'view'" (click)="onNext()" class="btn-submit" mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </div>
        </form>
    </mat-step>

    <mat-step completed="false" [stepControl]="planForm">
        <form [formGroup]="planForm">
            <ng-template matStepLabel>{{'Plan_Specific_details' | translate}}</ng-template>

            <div fxLayout="row wrap" fxLayout="row wrap" fxLayoutGap="10px">
                <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Salary' | translate}}</mat-label>
                        <input (input)="salaryChange()" [(ngModel)]="calculationVars.salary" dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="salary">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(planForm, 'salary')"
                            #tooltip="matTooltip"
                            [matTooltip]="getErrorMessage(planForm, 'salary', 'Salary_Required')"
                            [matTooltipDisabled]="!getFieldValue(planForm, 'salary')"
                            matTooltipPosition="above" matSuffix>info
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Monthly Contribution Amount (SAR)' | translate}}</mat-label>
                        <input (input)="calculateSalaryPercentage()" [(ngModel)]="calculationVars.contribution_amount" dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="contribution_amount">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(planForm, 'contribution_amount')"
                            #tooltip="matTooltip"
                            [matTooltip]="getErrorMessage(planForm, 'contribution_amount', 'Monthly_Cont_Required')"
                            [matTooltipDisabled]="!getFieldValue(planForm, 'contribution_amount')"
                            matTooltipPosition="above" matSuffix>info
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'MonthlyContributionAmount' | translate}} %</mat-label>
                        <input (input)="calculateSalaryAmount()" [(ngModel)]="calculationVars.contribution_percent" dir="ltr" style="text-align: left;" autocomplete="off" type="text" matInput formControlName="contribution_percent">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(planForm, 'contribution_percent')"
                            #tooltip="matTooltip"
                            [matTooltip]="getErrorMessage(planForm, 'contribution_percent', 'Monthly Contribution Amount %')"
                            [matTooltipDisabled]="!getFieldValue(planForm, 'contribution_percent')"
                            matTooltipPosition="above" matSuffix>info
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Start_Date' | translate}}</mat-label>
                        <input readonly formControlName="start_date" autocomplete="off" type="text" matInput>
                    </mat-form-field>

                    <mat-checkbox [disabled]="routeType == 'view'" class="example-margin m-checkbox" color="primary"
                        (change)="onChecked($event.checked)" [(ngModel)]="isChecked" [ngModelOptions]="{standalone: true}">
                        {{'Use group settings' | translate}}
                    </mat-checkbox>

                    <mat-form-field fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Matching_Rule' | translate}}</mat-label>
                    <mat-select [disabled]="isChecked" [(ngModel)]="fixedMatching" (selectionChange)="matchingType()" formControlName="type">
                      <mat-option value="fixed">{{'Fixed' | translate}}</mat-option>
                      <mat-option value="variable">{{'Gradually Increasing' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="fixedMatching == 'fixed' && !isChecked" [fxFlex]="fixedMatching == 'fixed' ? '98' : '100'" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Matching' | translate}}</mat-label>
                    <input dir="ltr" style="text-align: left;" (change)="matchingFieldChange()" autocomplete="off" type="text" matInput formControlName="matching">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(planForm, 'matching')"
                        #tooltip="matTooltip" [matTooltip]="getErrorMessage(planForm, 'matching', 'Matching_Per_Required')"
                        [matTooltipDisabled]="!getFieldValue(planForm, 'matching')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>

                    <mat-form-field *ngIf="fixedMatching == 'variable'" fxFlex="100" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Initial_Matching' | translate}}</mat-label>
                        <input dir="ltr" style="text-align: left;" (change)="matchingFieldChange()" autocomplete="off" type="text" matInput formControlName="initial_matching">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(planForm, 'initial_matching')"
                            #tooltip="matTooltip" [matTooltip]="getErrorMessage(planForm, 'initial_matching', 'Matching_Per_Required')"
                            [matTooltipDisabled]="!getFieldValue(planForm, 'initial_matching')" matTooltipPosition="above"
                            matSuffix>info
                        </mat-icon>
                    </mat-form-field>

                    <mat-form-field *ngIf="fixedMatching == 'variable'" fxFlex="98" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                        fxFlexAlign="center" appearance="outline">
                        <mat-label>{{'Months_Till_Hundred_Percent' | translate}}</mat-label>
                        <input dir="ltr" style="text-align: left;" (change)="getGraphDataOnFieldChange()" autocomplete="off" type="text" matInput formControlName="months">
                        <mat-icon class="mat-icon-error" *ngIf="getFieldValue(planForm, 'months')"
                            #tooltip="matTooltip" [matTooltip]="getErrorMessage(planForm, 'months', 'No_of_Month_Required')"
                            [matTooltipDisabled]="!getFieldValue(planForm, 'months')" matTooltipPosition="above"
                            matSuffix>info
                        </mat-icon>
                    </mat-form-field>
                </div>
                <div fxFlex="48" fxLayoutAlign="center center">
                    <canvas baseChart [chartType]="'line'" [datasets]="lineChartData" [labels]="lineChartLabels"
                    [options]="lineChartOptions" [legend]="lineChartLegend" [colors]="lineChartColors" height="100">
                    </canvas>
                </div>
            </div>

            <div fxLayoutAlign="space-between center" fxLayoutGap="10px" style="margin: 15px 30px;">
                <div fxFlex="80">
                    <mat-form-field *ngIf="routeType != 'add'" fxFlex="100" style="width: 100%;" class="input-field mt-10" fxFlexAlign="center"
                        appearance="outline">
                        <mat-label>{{'Comments' | translate}}</mat-label>
                        <input autocomplete="off" type="text" readonly matInput [formControl]="comments">
                    </mat-form-field>
                </div>
                
                <div style="display: flex; gap: 10px;">
                    <button (click)="onCancel()" style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 16px; border-radius: 10px;" mat-raised-button>{{'Cancel' | translate}}</button>
                    <button *ngIf="routeType != 'view'" (click)="planSubmit()" class="btn-submit" mat-raised-button>{{'Save' | translate}}</button>
                    <button *ngIf="routeType == 'view' && currentIndexOfStepper < 2" (click)="onNext()" class="btn-submit" mat-raised-button>{{'Next' | translate}}</button>
                </div>
            </div>
        </form>
    </mat-step>

    <!-- <mat-step completed="false" [stepControl]="taxForm">
        <form [formGroup]="taxForm">
            <ng-template matStepLabel>{{'Tax_Residency_Information' | translate}}</ng-template>

            <div fxLayout="row wrap" fxLayoutGap="10px" class="form-container">
                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'Tax' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="tax">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(taxForm, 'tax')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(taxForm, 'tax', 'Tax')"
                        [matTooltipDisabled]="!getFieldValue(taxForm, 'tax')" matTooltipPosition="above" matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'TaxSSNNo' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="tax_ssn_no">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(taxForm, 'tax_ssn_no')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(taxForm, 'tax_ssn_no', 'Tax SSN No')"
                        [matTooltipDisabled]="!getFieldValue(taxForm, 'tax_ssn_no')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>

                <mat-form-field fxFlex="48" fxFlex.xs="100" style="width: 100%;" class="input-field mt-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>{{'TaxITINNo' | translate}}</mat-label>
                    <input autocomplete="off" type="text" matInput formControlName="tax_itn_no">
                    <mat-icon class="mat-icon-error" *ngIf="getFieldValue(taxForm, 'tax_itn_no')" #tooltip="matTooltip"
                        [matTooltip]="getErrorMessage(taxForm, 'tax_itn_no', 'Tax ITIN No')"
                        [matTooltipDisabled]="!getFieldValue(taxForm, 'tax_itn_no')" matTooltipPosition="above"
                        matSuffix>info
                    </mat-icon>
                </mat-form-field>
            </div>

            <div fxLayoutAlign="end center" fxLayoutGap="10px">
                <button (click)="onCancel()" style="background-color: #E9EAEF; color: white; line-height: 34px; padding: 0 16px; border-radius: 10px;" mat-raised-button>{{'Cancel' | translate}}</button>
                <button *ngIf="routeType != 'view'" (click)="taxSubmit()" class="btn-submit" mat-raised-button>{{'Next' | translate}}</button>
            </div>
        </form>
    </mat-step> -->
</mat-horizontal-stepper>