import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslationService } from 'src/app/lib/translation/translation.service';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';
import { PaginationService } from 'src/app/services/pagination.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
    selector: 'app-manage-contribution',
    templateUrl: './manage-contribution.component.html',
    styleUrls: ['./manage-contribution.component.scss']
})
export class ManageContributionComponent implements OnInit {

    calendar: String = "assets/ic_event_24px.svg";
    isDateDisabled: boolean = true;
    list: any = [];
    totalPages: number;
    Form: FormGroup;
    pages: any;
    perPage: number = 10;
    index: any = 1;
    searchTimer: any;
    search: any = '';
    currentPage: any = 1;
    count: any;
    items: any;
    employer_id: any;
    employee_name: any = "";
    check_contribution: any = false;
    sort_by: any = '';
    sort_order = 'DESC';
    role: any;
    minDate: any;
    start_date: any;
    end_date: any;
    currentDate = new Date();
    sortData: any = [
        {
            value: 'By Date',
            key: 'created_at',
        }
    ]

    tableProps = {
        heading: 'Manage_Contributions',
        hasSearch: true,
        searchLabel: 'Search',
        hasButton: true,
        headerActions: [
            {
                label: 'New Contribution', // Pleae dnt change this tilte > Salah
                // route: 'manage-contribution/add',
                type: 'output',
                visibility: true,
                isCustom: false,
                icon: 'add',
            },
        ],
        rowActions : [
            { label: 'View', type: 'view', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
            { label: 'Contribution Summary', type: 'contributionSummary', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-view.svg' },
            { label: 'Cancel Contribution', type: 'cancel', visibility: true, isCustom: true, background: '#4318FF0D', source: 'assets/table-deactivate.svg' },
        ],
        colHeader: {
            id: 'Contribution_ID',
            contribution_amount: 'Total Contribution Amount (SAR)',
            contribution_period: 'RequestDate',
            month: 'Month',
            statusToShow: 'Status',
            actions: 'Actions'
        },
        columnTypes: {
            actions: 'actionsMenu',
            contribution_amount: 'number-4',
            statusToShow: 'statusToShow',
            contribution_period: 'date',
        },
        filterArray: [],
        dateFilter: {
            hasDatePicker: true,
            type: "dateRange",  // dateRange, date
            startLable: "Start_Date",
            endLable: "End_Date",
        }
    };


    pagination = {
        page: null,
        pages: null,
        per_page: null,
        count: 0
    }

    constructor(public tr: TranslationService,
        public route: Router,
        protected mainService: MainService,
        public dec_enc: DecEncService,
        protected paginationService: PaginationService,
        public dialog: MatDialog,
        protected alertService: AlertService, 
        protected profileService: ProfileService)
        {
        this.employee_name = profileService.user.name;;
        this.role = profileService.user.role;

        if (this.role == 'employer' || this.role == 'emp-maker') {
            this.tableProps.headerActions[0].visibility = true;
        }
        else {
            this.tableProps.headerActions[0].visibility = false;
        }

        this.Form = new FormGroup({
            start: new FormControl(),
            end: new FormControl()
        });

        const currentYear = new Date().getFullYear();
        this.minDate = new Date(currentYear - 74, 0, 1);
    }

    ngOnInit(): void {
        this.Form.controls['start'].valueChanges.subscribe(value => {
            if (value != null) {
                this.isDateDisabled = false
                this.minDate = new Date(value);
            }
        })
        this.checkfundAllocated();
        this.getall(1)
    }

    view(id) {
        this.route.navigate(['main/manage-contribution/view/' + id])
    }

    creatContribution() {
        if (this.check_contribution) {
            this.route.navigate(['main/manage-contribution/add'])
        }
        else {
            this.alertService.alertAsk('Warning', 'For new contribution request, please first select your portfolio.', 'Ok', 'Cancel', false).then(result => {
                if (result)
                {
                    this.route.navigate(['main/allocate-contribution']);
                }
            })
        }
    }

    setPage(page) {
        this.pagination.page = page;
        this.getall(this.pagination.page);
    }

    getall(index: any) {
        // console.log("from_date: this.start_date, to_date: this.end_date", this.start_date, this.end_date)
        let enc_payload = this.dec_enc.encryption({ search: this.search, from_date: this.start_date, to_date: this.end_date });
        let url = 'contributions/csv_list?per_page=' + this.perPage + '&page=' + index
        this.mainService.postData(url, { enc_payload }).then(res => {
            if (res.statusCode == 200) {
                let result = this.dec_enc.decryption(res.data);
                console.log(result);
                this.pagination = result.pagination;
                this.list = result.contributions;
                this.afterList();
            }
        },
            error => {
                this.alertService.alert('Error', 'Server Error');
            }
        )
    }

    afterList() {
        this.list.forEach(item => {
            item['view'] = true;
            item['contributionSummary'] = true;
            item['cancel'] = item.status == 0 && (this.role == 'employer' || this.role == 'emp-maker') ? true : false;
            item['contribution_amount'] = !item['contribution_amount'] || isNaN(item['contribution_amount']) ? 0 : Number(item['contribution_amount']);

            item['contribution_period'] = moment(item['contribution_period']).format('DD-MM-YYYY');
            if (item.status == 0) {
                item['statusToShow'] = 'Pending';
            }
            if (item.status == 1) {
                item['statusToShow'] = 'Completed';
            }
            if (item.status == 2) {
                item['statusToShow'] = 'Rejected';
            }
            if (item.status == 3) {
                item['statusToShow'] = 'Under Process';
            }

            item['month'] = moment(item.contribution_period,'DD-MM-YYYY').format('MMMM');;
        });
    }

    checkfundAllocated() {
        let url = 'funds/allocated'
        this.mainService.postData(url, {}).then(res => {
            if (res.statusCode == 200) {
                let result;
                let data = res.data
                result = this.dec_enc.decryption(data);
                if (result.employer_fund == null) {
                    this.check_contribution = false
                }
                else {
                    this.check_contribution = true
                }
            }
        },
        error => {
            this.alertService.alert('Error', 'Server Error');
        }
        )
    }

    acceptReject(id, val) {
        this.alertService.alertAsk(this.tr.translation.alert, this.tr.translation.questionAskReject, 'Yes', 'No', false).then(result => {
            if (result == true) {
                let data = {
                    id: id,
                }
                let enc_payload = this.dec_enc.encryption(data);
                this.mainService.postData('contributions/update_csv', { enc_payload }).then(res => {
                    if (res.statusCode == 200) {
                        this.alertService.alert('Success', 'Contribution has been rejected successfully');
                        this.getall(this.currentPage);
                    }
                    else {
                        if (localStorage.getItem('lang') == 'ar')
                        {
                            this.alertService.alert('Error', res.error.message_ar);
                        }
                        else
                        {
                            this.alertService.alert('Error', res.error.message);
                        }
                    }
                },
                    error => {
                        this.alertService.alert('Error', this.tr.translation.serverError);
                    }
                )
            }
        })
    }

    actionClicked(event) 
    {
        switch (event.action.label)
        {
            case 'View':
            {
                this.view(event.element.id);
                break;
            }
            case 'Cancel Contribution':
            {
                this.acceptReject(event.element.id, '2');
                break;
            }
            case 'Contribution Summary':
            {
                this.route.navigate([`main/manage-contribution/view/summary/${event.element.id}`]);
                break;
            }
        }
    }

    searchData(event) {
        this.search = event;
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.getall(1);
        }, 800);
    }

    headerButton(event)
    {
        if (event.label == 'New Contribution')
        {
            this.creatContribution();
        }
    }

    onDateFilters(event) {
        if (event.type == "dateRange") {
            this.start_date = event['start'];
            this.end_date = event['end'];
            this.getall(1);
        }
    }
}
