<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <!-- Table -->

  <div fxLayoutAlign="center center">
    <div style="background-color: white; border-radius: 40px; padding: 5px 10px;">
      <div fxLayout="row" fxLayoutAlign="center center" class="lang-switch-container">
        <div (click)="onChangeList('all')" class="lang-switch" [class.lang-switch-active]="companyListing == 'all'">
          {{'All' | translate}}
        </div>
        <div (click)="onChangeList('company')" class="lang-switch" [class.lang-switch-active]="companyListing == 'company'">
          {{'Company' | translate}}
        </div>
        <div (click)="onChangeList('employees')" class="lang-switch" [class.lang-switch-active]="companyListing == 'employees'">{{'Employee' |
                    translate}}</div>
      </div>
    </div>
  </div>


  <div fxLayout="column">
    <div id="print" fxFlex="100"
      style="background: #FFFFFF; border-radius: 10px; padding: 20px; display: flex; flex-direction: column; justify-content: start; align-items: start; margin-top: 10px;">
      <div fxLayout="row" fxLayoutAlign="space-around start" fxLayoutGap="90px">
        <div fxFlex="100">
          <h3 style="font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif; margin-bottom: 10;
                    font-weight: bold;
                    color: #213060;">{{'Transaction Statement' | translate}}</h3>
          <div style=" background: #F5F5F5; border-radius: 9px; padding: 10px 20px;">
            <p style="font-family: 'DM Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 32px;
                      display: flex;
                      align-items: center;
                      letter-spacing: -0.02em;
                      color: #2C547B;">{{'A statement that contains all transactions in and out of the virtual account' | translate}}</p>
          </div>
          <div style="
                    margin-top: 10px;
                    background: #213060;
                      border-radius: 9px; font-family: 'DM Sans';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 21px;
                      align-items: center;
                      text-align: center;
                      letter-spacing: -0.02em;
                      color: #FFFFFF; padding: 10px 20px; text-align: center; cursor: pointer; width: max-content;"
            (click)="onPfd()">{{'Save as PDF' | translate}}</div>
        </div>
        <ng-container *ngIf="showWalletDetails">
          <div fxFlex="100">
            <h3 style="font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif; margin-bottom: 10;
                      font-weight: bold;
                      color: #213060;">Wallet Details</h3>
            <div fxLayout="row wrap">
              <div fxFlex="100" style="margin-bottom: 10px;">
                <mat-form-field
                  style="display: block;position: relative;flex: auto;min-width: 0; width: 250px;border-radius: 9px;"
                  fxFlexAlign="center" class="input-field" appearance="outline">
                  <mat-label>{{'Account_number' | translate}}</mat-label>
                  <input type="text" matInput [(ngModel)]="accountNo" readonly>
                </mat-form-field>
              </div>
              <div fxFlex="100">
                <mat-form-field
                  style="display: block;position: relative;flex: auto;min-width: 0; width: 250px;border-radius: 9px;"
                  fxFlexAlign="center" class="input-field" appearance="outline">
                  <mat-label>{{'IBAN_number' | translate}}</mat-label>
                  <input type="text" matInput [(ngModel)]="iBnNo" readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
        </ng-container>
      </div>


    </div>

    <app-table fxFlex="100" [start]="start_date" [end]="end_date" [tableProps]="tableProps"
      (searchData)="searchData($event)" [dataSource]="list" [pagination]="pagination" (setPage)="setPage($event)"
      (actionClicked)="actionClicked($event)" (outDateFilters)="onDateFilters($event)" (emitFilters)="onFilters($event)"
      style="margin-top: 10px;">
    </app-table>
  </div>
  <!-- Table -->
</div>


<main id="print1" style="display: none;">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
    <div fxFlex="48">
      <section>
        <h3
          style="font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif; margin-bottom: 10; font-weight: bold; color: #213060;">
          Transaction Statement</h3>
        <div style=" background: #F5F5F5; border-radius: 9px; width: 50%; padding: 10px 20px;">
          <p
            style="font-family: 'DM Sans'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 32px; display: flex; align-items: center; letter-spacing: -0.02em; color: #2C547B;">
            A statement that contains all transactions in and out of the virtual account</p>
        </div>
      </section>
    </div>
    <div fxFlex="48">
      <ng-container *ngIf="showWalletDetails">
        <section>
          <h3
            style="font: 500 20px/32px Roboto, 'Helvetica Neue', sans-serif; margin-bottom: 10; font-weight: bold; color: #213060;">
            Wallet details</h3>
          <div style=" background: #F5F5F5; border-radius: 9px; width: 90%; padding: 10px 30px;">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex="48">
                Account No &nbsp;
              </div>
              <div fxFlex="48"
                style="height: 30px;width: 150px;text-align: center;padding:5px ;">
                {{accountNo}}
              </div>
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between center" style="margin-top:20px">
              <div fxFlex="48">
                IBN NO &nbsp;
              </div>
              <div fxFlex="48"
                style="height: 30px;text-align: center;padding:5px ;">
                {{iBnNo}}
              </div>
            </div>
          </div>
        </section>
      </ng-container>
    </div>
  </div>



  <section style="margin-top: 20px;">
    <p><b style="color: #213060;">Type:</b> {{report_of_type}} &nbsp; <ng-container *ngIf="companyListing == 'employees'"><b style="color: #213060;">Employee:</b> {{employee}}</ng-container>
      &nbsp; <b style="color: #213060;">From:</b> {{startForPrint}} &nbsp; <b style="color: #213060;">To:</b>
      {{endForPrint}}</p>
  </section>

  <section style="margin-top: 20px;">
    <table style="width: 100%; border-collapse: collapse;">
      <tr>
        <th style="border: 1px solid black; padding: 5px; color: #213060;" *ngFor="let item of headerPrint">
          {{item.colHeader}}
        </th>
      </tr>
      <ng-container *ngFor="let row of list">
        <tr>
          <ng-container *ngFor="let item of headerPrint">
            <td *ngIf="item.colType == ''" style="border: 1px solid black; padding: 5px;">{{row[item.colKey]}}</td>
            <td *ngIf="item.colType == 'number-4'" style="border: 1px solid black; padding: 5px;">
              <span style="font-size: 16px;">{{row[item.colKey] | number:'1.4-4' | slice:0:-4}}</span>
              <span style="font-size: 12px;">{{row[item.colKey] | number:'1.4-4' | slice:-4}}</span>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </section>
</main>
