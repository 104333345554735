<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">

  <div fxLayoutAlign="end" class="mb-20">
    <button  style="background-color: #213060; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px;"
    (click)="markAsRead()" mat-raised-button>{{'Mark All as Read' |translate}}</button>
  </div>
  <div class="card-main">
    <mat-card class="mb-20">
      <div class="card-set">
        <div fxLayout="row" fxLayoutAlign="space-between stretch">
          <div fxFlex="100">
            <h2 style="margin: 0; font-weight: bold; color: #213060;">{{'Logs' | translate}}</h2>
          </div>
        </div>
        <div  class="mt-20">
        <!-- Notification item -->
        <div class="notif" fxLayout="row wrap" *ngFor="let item of log; let i=index"
          [ngClass]="{'divider': i < log.length && i > 0}">
          <div fxLayout="row" fxFlex="60px">
            <!-- <img class="notif-icon" src="../../../assets/ic_notification.svg"> -->
            <mat-icon class="notif-icon">streetview</mat-icon>
          </div>
          <div class="notif-body" fxLayout="column" fxFlex>
            <p [title]="item.message_log">{{shortTitle(item.message_log)}}</p>
            <span>{{item.created_at | date: 'medium'}}</span>
          </div>
        </div>
        <!-- Notification item -->
      </div>
    </div>
    </mat-card>

    <tp-pagination (setPage)="setPage($event)" [pagination]="pagination"></tp-pagination>
  </div>
</div>