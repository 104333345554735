<h2 style="color: #213060; font-weight: bold;text-align: start;">{{(data == 'New' ? 'Create New Employee' : 'Modify Employee') | translate}}</h2>

<form [formGroup]="Form" style="text-align: start;">
    <!-- <div fxLayout="row wrap" fxLayoutGap="10px" style="font-size: 12px; margin-top: 10px;"> -->
        <div fxLayout="row" fxLayoutGap="10px">
            <p fxFlex="16">{{'Name' | translate}}</p>
            <p fxFlex="16">{{'Phone_Number' | translate}}</p>
            <p fxFlex="16">{{'Email' | translate}}</p>
            <p fxFlex="16">{{'Gender' | translate}}</p>
            <p fxFlex="16">{{'Date_of_Birth' | translate}}</p>
            <p fxFlex="16">{{'Address' | translate}}</p>
        </div>

        <mat-divider></mat-divider>

        <div fxLayoutGap="10px">
            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Name</mat-label> -->
                <input matInput type="text" autocomplete="off" formControlName="name">
                <mat-icon *ngIf="getFieldValue('name')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('name', 'Name')"
                    [matTooltipDisabled]="!getFieldValue('name')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Phone</mat-label> -->
                <input matInput type="text" autocomplete="off" formControlName="phone">
                <span matPrefix>+966</span>
                <mat-icon *ngIf="getFieldValue('phone')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('phone', 'Phone', 12)"
                    [matTooltipDisabled]="!getFieldValue('phone')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Email</mat-label> -->
                <input matInput type="text" autocomplete="off" formControlName="email">
                <mat-icon *ngIf="getFieldValue('email')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('email', 'Email')"
                    [matTooltipDisabled]="!getFieldValue('email')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <mat-select formControlName="gender">
                    <mat-option value="M">M</mat-option>
                    <mat-option value="F">F</mat-option>
                </mat-select>
                <!-- <mat-label>Gender</mat-label> -->
                <mat-icon *ngIf="getFieldValue('gender')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('gender', 'Gender')"
                    [matTooltipDisabled]="!getFieldValue('gender')"
                    matTooltipPosition="above"
                    style="color: #213060;" matPrefix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Date of Birth</mat-label> -->
                <input readonly matInput [matDatepicker]="picker" [max]="maxDOB" formControlName="date_of_birth">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-icon *ngIf="getFieldValue('date_of_birth')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('date_of_birth', 'Date of Birth')"
                    [matTooltipDisabled]="!getFieldValue('date_of_birth')"
                    matTooltipPosition="above"
                    style="color: #213060;" matPrefix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Address</mat-label> -->
                <input matInput type="text" autocomplete="off" formControlName="address">
            </mat-form-field>
        </div>

        <div style="margin-top: 25px;" fxLayout="row" fxLayoutGap="10px">
            <p fxFlex="16">{{'National_Iqama_ID' | translate}}</p>
            <p fxFlex="16">{{'Iqama_Expiry_Date' | translate}}</p>
            <p fxFlex="16">{{'Nationality' | translate}}</p>
            <p fxFlex="16">{{'Employee_Since' | translate}}</p>
            <p fxFlex="16">{{'Designation' | translate}}</p>
            <p fxFlex="16">{{'Are_You_Dual_Nationality_Holder' | translate}}</p>
        </div>

        <mat-divider></mat-divider>

        <div fxLayoutGap="10px">
            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>National ID</mat-label> -->
                <input matInput type="text" autocomplete="off" formControlName="national_id_or_iqama">
                <mat-icon *ngIf="getFieldValue('national_id_or_iqama')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('national_id_or_iqama', 'National ID / Iqama')"
                    [matTooltipDisabled]="!getFieldValue('national_id_or_iqama')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>National ID Expiry</mat-label> -->
                <input readonly matInput [min]="maxDOB" [matDatepicker]="picker1" formControlName="national_id_or_iqama_expiry">
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
                <mat-icon *ngIf="getFieldValue('national_id_or_iqama_expiry')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('national_id_or_iqama_expiry', 'National ID Expiry')"
                    [matTooltipDisabled]="!getFieldValue('national_id_or_iqama_expiry')"
                    matTooltipPosition="above"
                    style="color: #213060;" matPrefix>info
                </mat-icon>
            </mat-form-field>

            <!-- <mat-form-field fxFlex="16" appearance="legacy">
                <mat-select formControlName="nationality">
                    <mat-option *ngFor="let country of countries" [value]="country">{{country}}</mat-option>
                </mat-select>
            </mat-form-field> -->
            <mat-form-field fxFlex="16" appearance="legacy">
                <input matInput type="text" autocomplete="off" formControlName="nationality">
                <mat-icon *ngIf="getFieldValue('nationality')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('nationality', 'Nationality')"
                    [matTooltipDisabled]="!getFieldValue('nationality')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Joining Date</mat-label> -->
                <input readonly matInput [matDatepicker]="picker2" formControlName="joining_date">
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <!-- <mat-label>Designation</mat-label> -->
                <input matInput type="text" autocomplete="off" formControlName="designation">
                <mat-icon *ngIf="getFieldValue('designation')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('designation', 'Designation')"
                    [matTooltipDisabled]="!getFieldValue('designation')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <mat-select formControlName="dual_nationality_holder">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                </mat-select>
                <!-- <mat-label>Dual Nationality Holder</mat-label> -->
            </mat-form-field>
        </div>

        <div style="margin-top: 25px;" fxLayout="row" fxLayoutGap="10px">
            <p fxFlex="16">{{'Employee Group' | translate}}</p>
            <p fxFlex="16">{{'Salary' | translate}}</p>
        </div>

        <mat-divider></mat-divider>

        <div fxLayoutGap="10px">
            <mat-form-field fxFlex="16" appearance="legacy">
                <mat-select formControlName="group">
                    <mat-option *ngFor="let group of groups" [value]="group.name">{{group.name}}</mat-option>
                    <!-- <mat-option value="No">No</mat-option> -->
                </mat-select>
                <!-- <mat-label>Dual Nationality Holder</mat-label> -->
            </mat-form-field>

            <mat-form-field fxFlex="16" appearance="legacy">
                <div style="width: 100%;" [ngClass]="editModes.salary ? 'hide' : 'show'"
                [style.color]="salary || salary == 0 ? 'black' : 'transparent'"
                    (click)="setMode('salary', true)" (focus)="setMode('salary', true)">
                    {{salary || salary == 0 ? (salary | number:'1.4-4') : '0'}}
                </div>

                <input matInput type="text" autocomplete="off" formControlName="salary" 
                    [(ngModel)]="salary" [ngClass]="editModes.salary == true ? 'show' : 'hide-input'"
                    (focusout)="setMode('salary', false, true)" (focus)="setMode('salary', true)">
                <mat-icon *ngIf="getFieldValue('salary')" 
                    #tooltip="matTooltip"
                    [matTooltip]="getErrorMessage('salary', 'Designation')"
                    [matTooltipDisabled]="!getFieldValue('salary')"
                    matTooltipPosition="above"
                    style="color: #213060;" matSuffix>info
                </mat-icon>
            </mat-form-field>
        </div>
        
    <!-- </div> -->
</form>

<div *ngIf="modifyErrorMessage" fxLayout="row" fxLayoutGap="10px" style="margin-top: 10px;">
    
    <!-- <mat-icon class="error-action-icon"  [ngStyle]="{'background-color': element[tableData] == 'Verified' ? '#05CD99' : '#EE5D50'}">{{element[tableData] == 'Verified' ? 'done' : 'close'}}</mat-icon> -->
    
    <mat-icon class="error-action-icon" style="background-color: #EE5D50;">close</mat-icon>
    <p style="margin-bottom: 0;">{{'Error_Message' | translate}}: {{modifyErrorMessage}}</p>
</div>

<div fxLayoutAlign="end center" style="font-size: 12px; margin-top: 10px;">
    <button (click)="closeDialog()" style="background-color: #E9EAEF; color: #213060; line-height: 28px; padding: 0 25px;" mat-raised-button>{{'Cancel' | translate}}</button>
    <button (click)="submit()" style="background-color: #213060; color: white; line-height: 28px; padding: 0 25px; margin-inline: 10px;" mat-raised-button>{{submitButtonText | translate}}</button>
</div>