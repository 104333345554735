<h3 class="form-heading">{{!id ? ('Create_Employees_Group' | translate) : ('Update_Employees_Group' | translate)}}</h3>

<mat-card style="margin: 20px 20px; padding: 16px;">
  <h3 style="margin-left: 0; font-weight: bold; color: #213060;">{{'Group_Details' | translate}}</h3>

  <form [formGroup]="Form">
    <div fxLayout="row wrap" fxLayoutGap="10px">
      <div fxFlex="48" fxLayout="column" fxLayoutGap="5px" class="form-container">
        <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center"
          appearance="outline" style="width: 100%;">
          <mat-label>{{'Group_Name' | translate}}</mat-label>
          <input autocomplete="off" type="text" matInput formControlName="name">
        </mat-form-field>
        <mat-form-field *ngIf="switchOfListings.portfolio" class="input-field" fxFlex="100" fxFlex.sm="46"
          fxFlex.xs="100" appearance="outline" style="width: 100%;">
          <mat-label>{{'default_portfolio' | translate}}</mat-label>
          <mat-select formControlName="default_fund_id">
            <mat-option *ngFor="let item of lstAssignedFunds" [value]="item.id">{{item.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="100" fxFlex.xs="100" class="input-field" fxFlexAlign="center"
          appearance="outline" style="width: 100%;">
          <mat-label>{{'Monthly Contribution Amount (SAR)' | translate}}</mat-label>
          <input formControlName="matching_amount" autocomplete="off" type="text" matInput>
        </mat-form-field>
        <mat-form-field class="input-field" fxFlex="100" fxFlex.sm="46" fxFlex.xs="100" appearance="outline" style="width: 100%;">
          <mat-label>{{'Matching_Rule' | translate}}</mat-label>
          <mat-select (selectionChange)="matchingType()" [(ngModel)]="fixedMatching" formControlName="type">
            <mat-option value="fixed">{{'Fixed' | translate}}</mat-option>
            <mat-option value="variable">{{'Gradually Increasing' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <mat-form-field *ngIf="fixedMatching == 'fixed'" fxFlex="100" fxFlex.xs="100" class="input-field mr-10"
                    fxFlexAlign="center" appearance="outline">
                    <mat-label>Matching (%)</mat-label>
                    <input (change)="matchingFieldChange()" formControlName="matching" autocomplete="off" type="text" matInput>
                </mat-form-field> -->
        <mat-form-field *ngIf="fixedMatching == 'fixed'" fxFlex="100" fxFlex.xs="100"
          class="input-field" fxFlexAlign="center" appearance="outline" style="width: 100%;">
          <mat-label>{{'Matching' | translate}}</mat-label>
          <input (change)="matchingFieldChange()" formControlName="initial_matching" autocomplete="off" type="text"
            matInput>
          <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'initial_matching')" #tooltip="matTooltip"
            [matTooltip]="getErrorMessage(Form, 'initial_matching', 'Matching (%)')"
            [matTooltipDisabled]="!getFieldValue(Form, 'initial_matching')" matTooltipPosition="above" matSuffix>info
          </mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="fixedMatching == 'variable'" fxFlex="100" fxFlex.xs="100"
          class="input-field" fxFlexAlign="center" appearance="outline" style="width: 100%;">
          <mat-label>{{'Initial_Matching' | translate}}</mat-label>
          <input (change)="matchingFieldChange()" formControlName="initial_matching" autocomplete="off" type="text"
            matInput>
          <mat-icon class="mat-icon-error" *ngIf="getFieldValue(Form, 'initial_matching')" #tooltip="matTooltip"
            [matTooltip]="getErrorMessage(Form, 'initial_matching', 'Initial Matching (%)')"
            [matTooltipDisabled]="!getFieldValue(Form, 'initial_matching')" matTooltipPosition="above" matSuffix>info
          </mat-icon>
        </mat-form-field>
        <mat-form-field *ngIf="fixedMatching == 'variable'" fxFlex="100" fxFlex.xs="100"
          class="input-field" fxFlexAlign="center" appearance="outline" style="width: 100%;">
          <mat-label>{{'Months_Till_Hundred_Percent' | translate}}</mat-label>
          <input (change)="matchingFieldChange()" formControlName="months" autocomplete="off" type="text" matInput>
        </mat-form-field>
      </div>
      <div fxFlex="48" fxLayoutAlign="center center" style="margin-top: 20px;">
        <canvas baseChart [chartType]="'line'" [datasets]="lineChartData" [labels]="lineChartLabels"
          [options]="lineChartOptions" [legend]="lineChartLegend" [colors]="lineChartColors" height="100">
        </canvas>
      </div>
    </div>
  </form>
</mat-card>

<!-- <mat-card *ngIf="id" style="margin: 20px 20px; padding: 16px;">
    <h3 style="margin-left: 0; font-weight: bold; color: #213060;">Matching Details</h3>
    <form [formGroup]="matchingForm">
        <div>
            <div fxFlex="48" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign="space-between" class="form-container">
                
            </div>
            
            
        </div>

        <div fxLayoutAlign="end center" style="margin-top: 10px;">
            <button (click)="matchingSubmit()" class="btn-submit" mat-raised-button>Submit</button>
        </div>
    </form>
</mat-card> -->

<!-- toggle -->
<div fxLayoutAlign="center center" style="margin-top: 20px;">
  <div style="background-color: white; border-radius: 40px; padding: 5px 10px;">
    <div fxLayout="row" fxLayoutAlign="center center" class="lang-switch-container">
      <div (click)="changeButton('portfolio')" class="lang-switch"
        [class.lang-switch-active]="switchOfListings.portfolio">{{'Link_Portfolio' | translate}}</div>
      <div (click)="changeButton('employees')" class="lang-switch"
        [class.lang-switch-active]="switchOfListings.employees">{{'Link_Employees' | translate}}</div>
    </div>
  </div>
</div>

<ng-container *ngIf="switchOfListings.portfolio">
  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsPortfolioTop" [dataSource]="lstAssignedFunds"
      (actionClicked)="actionClicked($event)"
      [pagination]="paginationFundAssigned" (setPage)="setPageFundAssigned($event)" (searchData)="onSearchAssigned($event)">
    </app-table>
  </div>

  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsPortfolioBottom" (outPutHeaders)="headerButton($event)"
      [dataSource]="lstFunds" (emitCheckBox)="checkBox($event)" (actionClicked)="actionClicked($event)"
      [pagination]="paginationFund" (setPage)="setPageFund($event)" (searchData)="onSearchAll($event)">
    </app-table>
  </div>
</ng-container>

<ng-container *ngIf="switchOfListings.employees">

  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsEmpTop" [dataSource]="lstAssignedEmployees"
      (actionClicked)="actionClicked($event)"
      [pagination]="paginationEmployeeAssigned" (setPage)="setPageEmployeeAssigned($event)" (searchData)="onSearchAssigned($event)">
    </app-table>
  </div>

  <div style="margin: 20px 10px; padding: 16px;">
    <app-table #table fxFlex="100" [tableProps]="tablePropsEmpBottom" (outPutHeaders)="headerButton($event)"
      [dataSource]="lstEmployees" (emitCheckBox)="checkBox($event)" (actionClicked)="actionClicked($event)"
      [pagination]="paginationEmployee" (setPage)="setPageEmployee($event)" (searchData)="onSearchAll($event)">
    </app-table>
  </div>
</ng-container>

<div style="margin: 10px 25px;" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    <button (click)="back()"
      style="background-color: #E9EAEF; color: #213060; line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none;"
      mat-raised-button>{{'Cancel' | translate}}</button>
    <button (click)="addGroup()"
      style="background-color: #213060; color: white; line-height: 34px; padding: 0 25px; border-radius: 10px; outline: none;"
      mat-raised-button>{{'Submit' | translate}}</button>
  </div>


