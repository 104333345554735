<div class="main pt-1 mrl-20 mt-30 mb-20" ngClass.sm="mrl-10" ngClass.xs="mrl-10">
  <!-- Table -->
    <app-table fxFlex="100" [tableProps]="tableProps"
      (searchData)="searchData($event)" [dataSource]="list" [pagination]="pagination" (setPage)="setPage($event)" (actionClicked)="actionClicked($event)">
    </app-table>
    <!-- Table -->
</div>

<!-- Print Section -->
<div style="display: none;">
  <div id="print">

      <!-- Top Heading ThriftPlan -->
      <section fxLayout="row" fxLayoutAlign="center">
          <h4>ThriftPlan</h4>
      </section>
      
      <!-- Employee Details -->
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
          <div fxFlex="60">
              <h2 style="font-weight: bold;">{{'Termination Summary' | translate}}</h2>
          </div>
      </div>

      <!-- Employee Details -->
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
          <div fxFlex="60">
              <h3>{{'Employee Detail' | translate}}</h3>
          </div>
      </div>
      <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Name' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Email' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Phone_Number' | translate}}</th>
          </tr>
          <tr>
              <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.name ? dashboardData?.employee?.name : 'N/A'}}</td>
              <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.email ? dashboardData?.employee?.email : 'N/A'}}</td>
              <td style="border: 1px solid #5a5959;">{{ dashboardData?.employee?.phone ? dashboardData?.employee?.phone : 'N/A'}}</td>
          </tr>
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Designation' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Employee Joining Date' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Employee Group' | translate}}</th>
          </tr>
          <tr>
              <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.employees_identification?.position ? dashboardData?.employee?.employees_identification?.position :'N/A'}}</td>
              <td style="border: 1px solid #5a5959;">{{dashboardData?.employee?.employees_identification?.employee_since ? dashboardData?.employee?.employees_identification?.employee_since :'N/A'}}</td>
              <td style="border: 1px solid #5a5959;">{{ dashboardData?.employee?.employees_identification?.employee_group ? dashboardData?.employee?.employees_identification?.employee_group : 'N/A'}}</td>
          </tr>
      </table>

      <!-- Settlement Summary -->
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
          <div fxFlex="60">
              <h3 style="margin-bottom: 0;">{{'Settlement_Summary' | translate}}</h3>
              <h5 style="margin-top: 0;">{{'Based_on_Current_Nav_Prices' | translate}}</h5>
          </div>
      </div>
      <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Total_Settlement_Amount' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Total Employee Settlement' | translate}}</th>
          </tr>
          <tr>
            <td style="border: 1px solid #5a5959;">
                <!-- {{dashboardData?.termination?.total_settlement_amount ? (dashboardData?.termination?.total_settlement_amount | number: '1.4-4') : '0.0000'}} -->

                <span style="font-size: 16px;">{{dashboardData?.termination?.total_settlement_amount | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.termination?.total_settlement_amount | number:'1.4-4' | slice:-4}}</span>
            </td>
              <td style="border: 1px solid #5a5959;">
                <!-- {{ dashboardData?.termination?.total_employee_settlement ? (dashboardData?.termination?.total_employee_settlement | number : '1.4-4') : '0.0000' }} -->

                <span style="font-size: 16px;">{{dashboardData?.termination?.total_employee_settlement | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.termination?.total_employee_settlement | number:'1.4-4' | slice:-4}}</span>
            </td>
          </tr>
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Company Settlement' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Employee Settlement' | translate}}</th>
          </tr>
          <tr>
              <td style="border: 1px solid #5a5959;">
                <!-- {{ dashboardData?.termination?.company_settlement ? (dashboardData?.termination?.company_settlement | number : '1.4-4') : '0.00' }} -->

                <span style="font-size: 16px;">{{dashboardData?.termination?.company_settlement | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.termination?.company_settlement | number:'1.4-4' | slice:-4}}</span>
            </td>
              <td style="border: 1px solid #5a5959;">
                <!-- {{ dashboardData?.termination?.employee_settlement ? (dashboardData?.termination?.employee_settlement | number : '1.4-4') : '0.0000' }} -->

                <span style="font-size: 16px;">{{dashboardData?.termination?.employee_settlement | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.termination?.employee_settlement | number:'1.4-4' | slice:-4}}</span>
            </td>
          </tr>
      </table>

      <!-- Company Portfolio Breakdown -->
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
          <div fxFlex="60">
              <h3 style="margin-bottom: 10px;">{{'Employer Portfolio Breakdown' | translate}}</h3>
          </div>
      </div>
      <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Employer_Contribution' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Vested Amount' | translate}}</th>
          </tr>
          <tr>
            <td style="border: 1px solid #5a5959;">
                <!-- {{dashboardData?.summary?.company_contribution ? (dashboardData?.summary?.company_contribution | number: '1.4-4') : '0.0000'}} -->

                <span style="font-size: 16px;">{{dashboardData?.summary?.company_contribution | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.summary?.company_contribution | number:'1.4-4' | slice:-4}}</span>
            </td>
              <td style="border: 1px solid #5a5959;">
                <!-- {{ dashboardData?.vest_breakdown?.vested ? (dashboardData?.vest_breakdown?.vested | number : '1.4-4') : '0.0000' }} -->

                <span style="font-size: 16px;">{{dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.vest_breakdown?.vested | number:'1.4-4' | slice:-4}}</span>
            </td>
          </tr>
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Company Invested' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Profit' | translate}}</th>
          </tr>
          <tr>
              <td style="border: 1px solid #5a5959;">
                <!-- {{ dashboardData?.company_fund?.invested_amount ? (dashboardData?.company_fund?.invested_amount | number : '1.4-4') : '0.00' }} -->

                <span style="font-size: 16px;">{{dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.company_fund?.invested_amount | number:'1.4-4' | slice:-4}}</span>
            </td>
              <td style="border: 1px solid #5a5959;">
                <!-- {{dashboardData?.company_fund?.profit ? (dashboardData?.company_fund?.profit | number: '1.4-4') : '0.0000'}} -->

                <span style="font-size: 16px;">{{dashboardData?.company_fund?.profit | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.company_fund?.profit | number:'1.4-4' | slice:-4}}</span>
            </td>
          </tr>
      </table>

      <!-- Employee Portfolio Breakdown -->
      <div fxLayout="row" fxLayoutAlign="space-between center" style="position: relative;z-index: 9999;">
          <div fxFlex="60">
              <h3 style="margin-bottom: 10px;">{{'Employee Portfolio Breakdown' | translate}}</h3>
          </div>
      </div>
      <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Employee_Contribution' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Withdrawal_Amount' | translate}}</th>
          </tr>
          <tr>
              <td style="border: 1px solid #5a5959;">
                <!-- {{dashboardData?.summary?.employee_contribution ? (dashboardData?.summary?.employee_contribution | number: '1.4-4') : '0.0000'}} -->
                
                <span style="font-size: 16px;">{{dashboardData?.summary?.employee_contribution | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.summary?.employee_contribution | number:'1.4-4' | slice:-4}}</span>
            </td>
              <td style="border: 1px solid #5a5959;">
                <!-- {{dashboardData?.employee_fund?.withdrawn ? (dashboardData?.employee_fund?.withdrawn | number: '1.4-4') : '0.0000'}} -->

                <span style="font-size: 16px;">{{dashboardData?.employee_fund?.withdrawn | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.employee_fund?.withdrawn | number:'1.4-4' | slice:-4}}</span>
            </td>
          </tr>
          <tr>
              <th style="border: 1px solid #5a5959;">{{'Employee Invested' | translate}}</th>
              <th style="border: 1px solid #5a5959;">{{'Profit' | translate}}</th>
          </tr>
          <tr>
              <td style="border: 1px solid #5a5959;">
                <!-- {{ dashboardData?.employee_fund?.invested_amount ? (dashboardData?.employee_fund?.invested_amount | number : '1.4-4') : '0.0000' }} -->

                <span style="font-size: 16px;">{{dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.employee_fund?.invested_amount | number:'1.4-4' | slice:-4}}</span>
            </td>
              <td style="border: 1px solid #5a5959;">
                <!-- {{dashboardData?.employee_fund?.profit ? (dashboardData?.employee_fund?.profit | number: '1.4-4') : '0.0000' }} -->

                <span style="font-size: 16px;">{{dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:0:-4}}</span>
                <span style="font-size: 12px;">{{dashboardData?.employee_fund?.profit | number:'1.4-4' | slice:-4}}</span>
            </td>
          </tr>
      </table>

      <!-- Company Portfolio -->
      <div>
          <h3 style="margin-bottom: 10px;">{{'Company Portfolio' | translate}}</h3>
      </div>
      <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
          <tr>
              <th *ngFor="let col of headersPrintCompanyPortfolio" style="border: 1px solid #5a5959;">{{col.colHeader | translate}}</th>
          </tr>
          <tr *ngFor="let row of EmployerAssetslist; let i = index">
            <ng-container *ngFor="let col of headersPrintCompanyPortfolio; let p = index">
                <td *ngIf="col.colType == ''" style="border: 1px solid #5a5959;">{{row[col.colKey]}}</td>
                <td *ngIf="col.colType == 'number'" style="border: 1px solid #5a5959;">
                    <!-- {{row[col.colKey]}} -->

                    <span style="font-size: 16px;">{{row[col.colKey] | number:'1.2-2' | slice:0:-2}}</span>
                    <span style="font-size: 12px;">{{row[col.colKey] | number:'1.2-2' | slice:-2}}</span>
                </td>
                <td *ngIf="col.colType == 'number-4'" style="border: 1px solid #5a5959;">
                    <!-- {{row[col.colKey]}} -->

                    <span style="font-size: 16px;">{{row[col.colKey] | number:'1.4-4' | slice:0:-4}}</span>
                    <span style="font-size: 12px;">{{row[col.colKey] | number:'1.4-4' | slice:-4}}</span>
                </td>
                <td *ngIf="col.colType == 'number-5'" style="border: 1px solid #5a5959;">
                    <!-- {{row[col.colKey]}} -->

                    <span style="font-size: 16px;">{{row[col.colKey] | number:'1.5-5' | slice:0:-5}}</span>
                    <span style="font-size: 12px;">{{row[col.colKey] | number:'1.5-5' | slice:-5}}</span>
                </td>
            </ng-container>
              
          </tr>
      </table>

      <!-- Employee Portfolio -->
      <div>
          <h3 style="margin-bottom: 10px;">{{'Employee Portfolio' | translate}}</h3>
      </div>
      <table style="width: 100%; border: 1px solid #5a5959; border-collapse: collapse; text-align: left;">
          <tr>
              <th *ngFor="let col of headersPrintEmployeePortfolio" style="border: 1px solid #5a5959;">{{col.colHeader | translate}}</th>
          </tr>
          <tr *ngFor="let row of EmployeeAssetslist; let i = index">
            <ng-container *ngFor="let col of headersPrintEmployeePortfolio; let p = index">
                <td *ngIf="col.colType == ''" style="border: 1px solid #5a5959;">{{row[col.colKey]}}</td>
                <td *ngIf="col.colType == 'number'" style="border: 1px solid #5a5959;">
                    <!-- {{row[col.colKey]}} -->

                    <span style="font-size: 16px;">{{row[col.colKey] | number:'1.2-2' | slice:0:-2}}</span>
                    <span style="font-size: 12px;">{{row[col.colKey] | number:'1.2-2' | slice:-2}}</span>
                </td>
                <td *ngIf="col.colType == 'number-4'" style="border: 1px solid #5a5959;">
                    <!-- {{row[col.colKey]}} -->

                    <span style="font-size: 16px;">{{row[col.colKey] | number:'1.4-4' | slice:0:-4}}</span>
                    <span style="font-size: 12px;">{{row[col.colKey] | number:'1.4-4' | slice:-4}}</span>
                </td>
                <td *ngIf="col.colType == 'number-5'" style="border: 1px solid #5a5959;">
                    <!-- {{row[col.colKey]}} -->

                    <span style="font-size: 16px;">{{row[col.colKey] | number:'1.5-5' | slice:0:-5}}</span>
                    <span style="font-size: 12px;">{{row[col.colKey] | number:'1.5-5' | slice:-5}}</span>
                </td>
            </ng-container>
              
          </tr>
      </table>
  </div>
</div>