import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AlertService } from 'src/app/services/alert.service';
import { DecEncService } from 'src/app/services/dec-enc';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-edit-dialog-employees',
  templateUrl: './edit-dialog-employees.component.html',
  styleUrls: ['./edit-dialog-employees.component.scss']
})
export class EditDialogEmployeesComponent implements OnInit {

    Form: FormGroup;
    modifyErrorMessage = null;
    maxDOB = null;
    groups = [];
    submitButtonText = 'Submit';

    editModes = {
        salary: false,
    }
    salary: number = 0;

    // countries = [
    //     'Australia',
    //     'Bangladesh',
    //     'Oman',
    //     'Qatar',
    //     'UAE',
    //     'Germany',
    //     'Saudi Arabia',
    //     'Pakistan',
    // ]

    constructor(protected formbuilder: FormBuilder,
        protected dialogRef: MatDialogRef<EditDialogEmployeesComponent>, 
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected cryptoService: DecEncService,
        protected mainService: MainService,
        protected alertService: AlertService,
    ) 
    {
        dialogRef.disableClose = true;
        this.initForm();
        this.maxDOB = new Date();
        if (this.data != 'New')
        {
            this.submitButtonText = 'Update';
            // console.log('data', this.data);
            if(this.data.phone){
                if (this.data.phone.length == 12 && this.data.phone.startsWith('966'))
                {
                    this.data.phone = this.data.phone.slice(3);
                }
            }
        }
    }

    ngOnInit(): void 
    {
        if (this.data != 'New')
        {
            this.Form.patchValue(this.data);
            this.adjustDates();
            this.checkErrorOnEdit();
            // console.log('DetailApi', this.Form.value);
        }
        this.getGroups();
    }

    checkErrorOnEdit()
    {
        if (this.data.error_message)
        {
            for (let key in this.data.error_message)
            {
                if (localStorage.getItem('lang') == 'ar') {
                    if (this.modifyErrorMessage == null) {
                        this.modifyErrorMessage = this.data.error_message[key]['message_ar'];
                    }
                    else {
                        this.modifyErrorMessage = `${this.modifyErrorMessage}, ${this.data.error_message[key]['message_ar']}`;
                    }
                }
                else {
                    if (this.modifyErrorMessage == null) {
                        this.modifyErrorMessage = this.data.error_message[key]['message'];
                    }
                    else {
                        this.modifyErrorMessage = `${this.modifyErrorMessage}, ${this.data.error_message[key]['message']}`;
                    }
                }
            }
        }
    }

    adjustDates()
    {
        if (this.data.date_of_birth)
        {
            let initial = this.data.date_of_birth.split(/\//);
            let d = moment( [ initial[1], initial[0], initial[2] ].join('/')).format('YYYY-MM-DD');
            this.Form.get('date_of_birth').setValue(d);
        }
        if (this.data.national_id_or_iqama_expiry)
        {
            let initial = this.data.national_id_or_iqama_expiry.split(/\//);
            let d = moment( [ initial[1], initial[0], initial[2] ].join('/')).format('YYYY-MM-DD');
            this.Form.get('national_id_or_iqama_expiry').setValue(d);
        }
        if (this.data.joining_date)
        {
            let initial = this.data.joining_date.split(/\//);
            let d = moment( [ initial[1], initial[0], initial[2] ].join('/')).format('YYYY-MM-DD');
            this.Form.get('joining_date').setValue(d);
        }
    }

    initForm()
    {
        if (this.data == 'New')
        {
            this.Form = this.formbuilder.group({
                name: new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
                phone: new FormControl(null, [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
                email: new FormControl(null, [Validators.required, Validators.email]),
                gender: new FormControl(null, [Validators.required]),
                date_of_birth: new FormControl(null, [Validators.required]),
                address: new FormControl(null, Validators.maxLength(150)),
                national_id_or_iqama: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]),
                national_id_or_iqama_expiry: new FormControl(null, [Validators.required]),
                nationality: new FormControl(null , Validators.pattern('^[a-zA-Z ]*$')),
                joining_date: new FormControl(null),
                designation: new FormControl(null, Validators.pattern('^[a-zA-Z ]*$')),
                dual_nationality_holder: new FormControl(null),
                salary: new FormControl(null),
                group: new FormControl(null),
            });
        }
        else
        {
            this.Form = this.formbuilder.group({
                id: new FormControl(null),
                employer_id: new FormControl(null),
                name: new FormControl(null, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]),
                phone: new FormControl(null, [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]),
                email: new FormControl(null, [Validators.required, Validators.email]),
                gender: new FormControl(null, [Validators.required]),
                date_of_birth: new FormControl(null, [Validators.required]),
                address: new FormControl(null, Validators.maxLength(150)),
                national_id_or_iqama: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.min(0)]),
                national_id_or_iqama_expiry: new FormControl(null, [Validators.required]),
                nationality: new FormControl(null, Validators.pattern('^[a-zA-Z ]*$')),
                joining_date: new FormControl(null),
                designation: new FormControl(null, Validators.pattern('^[a-zA-Z ]*$')),
                dual_nationality_holder: new FormControl(null),
                salary: new FormControl(null),
                group: new FormControl(null),
            });
        }
    }

    closeDialog()
    {
        if (this.data != 'New')
        {
            this.data.phone = `966${this.data.phone}`;
        }
        this.dialogRef.close({event: 'closed'});
    }

    submit()
    {
        this.recordModifications();
        if (!this.checkValidations())
        {
            return;
        }
        if (this.data == 'New')
        {
            this.newRecord();
        }
        else
        {
            this.modifyRecord();
        }
    }

    newRecord()
    {
        this.modifyErrorMessage = null;

        
        let encrypted = this.cryptoService.encryption(this.recordModifications());
        let obj = {
            enc_payload: encrypted
        }
        // console.log('payload', this.recordModifications());
        let url = `draft-employees/insert`;
        this.mainService.postData(url, obj).then(response => {
            if (response.statusCode == 200)
            {
                this.modifyErrorMessage = null;
                let dec = this.cryptoService.decryption(response.data);
                // console.log('modifyRecord success', dec);
                this.alertService.alert('Success', 'Record added successfully', '');
                this.dialogRef.close({event: 'created'});
            }
            else
            {
                let decError = this.cryptoService.decryption(response.error.error);

                if (response.error.message)
                {
                    for (let key in response.error.message)
                    {
                        if (localStorage.getItem('lang') == 'ar') 
                        {
                            this.modifyErrorMessage = response.error.message_ar;
                        }
                        else 
                        {
                            this.modifyErrorMessage = response.error.message;
                        }
                    }
                }
                else
                {
                    this.modifyErrorMessage = 'Error';
                }
                // console.log('newRecord', decError, response.message);
            }
        })
    }

    recordModifications()
    {
        let payload = this.Form.getRawValue()
        payload.date_of_birth = moment(payload.date_of_birth).format('DD/MM/YYYY');
        payload.national_id_or_iqama_expiry = moment(payload.national_id_or_iqama_expiry).format('DD/MM/YYYY');
        if (payload.joining_date)
        {
            payload.joining_date = moment(payload.joining_date).format('DD/MM/YYYY');
        }
        payload.phone = `966${payload.phone}`;
        return payload;
    }

    modifyRecord()
    {
        this.modifyErrorMessage = null;
        let encrypted = this.cryptoService.encryption(this.recordModifications());
        // console.log('modifyRecord payload', this.recordModifications());
        let obj = {
            enc_payload: encrypted
        }
        let url = `draft-employees/modify`;
        this.mainService.postData(url, obj).then(response => {
            if (response.statusCode == 200)
            {
                this.modifyErrorMessage = null;
                let dec = this.cryptoService.decryption(response.data);
                // console.log('modifyRecord success', dec);
                this.alertService.alert('Success', 'Record modified successfully', '');
                this.dialogRef.close({event: 'updated'});
            }
            else
            {
                let decError = this.cryptoService.decryption(response.error.error);
                if (response.error.message)
                {
                    for (let key in response.error.message)
                    {
                        if (localStorage.getItem('lang') == 'ar') 
                        {
                            this.modifyErrorMessage = response.error.message_ar;
                        }
                        else 
                        {
                            this.modifyErrorMessage = response.error.message;
                        }
                    }
                }
                else
                {
                    this.modifyErrorMessage = 'Error';
                }

                // console.log('modifyRecord', decError, response);
            }
        })
    }

    checkValidations() 
    {
        if (this.Form.invalid) 
        {
            this.Form.markAllAsTouched();
            return false;
        }
        return true;
    }

    getFieldValue(field) 
    {
        if (this.Form.get(field).touched) 
        {
            if (this.Form.get(field).invalid)
            {
                return true;
            }
        }
        return false;
    }

    getErrorMessage(control, fieldName, minMaxValue?)
    {
        if (this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('required'))
            {
                return `${fieldName} is a required field.`
            }
        }
        if (control == 'phone' && this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('minlength') || this.Form.get(control).hasError('maxlength'))
            {
                return `${fieldName} should be equal to 12 digits e.g., 966123456789.`
            }
            if(this.Form.get(control).hasError('pattern'))
            {
                return `${fieldName} is in incorrect format. It should be in numbers only.`
            }
        }
        if ((control == 'name' || control == 'designation') && this.Form.get(control).invalid)
        {
            if(this.Form.get(control).hasError('pattern'))
            {
                return `${fieldName} is in incorrect format. It should be text only.`
            }
        }
        if ((control == 'name' || control == 'nationality') && this.Form.get(control).invalid)
        {
            if(this.Form.get(control).hasError('pattern'))
            {
                return `${fieldName} is in incorrect format. It should be text only.`
            }
        }
        if (control == 'email' && this.Form.get(control).invalid)
        {
            if(this.Form.get(control).hasError('email'))
            {
                return `${fieldName} is in incorrect format. It should be in valid email format.`
            }
        }
        if (control == 'address' && this.Form.get(control).invalid)
        {
            if(this.Form.get(control).hasError('maxlength'))
            {
                return `${fieldName} field exceeds the limit. It should not be more than 150 characters.`
            }
        }
        if (control == 'national_id_or_iqama' && this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('minlength') || this.Form.get(control).hasError('maxlength'))
            {
                return `${fieldName} should be equal to 10 digits only.`
            }
            if(this.Form.get(control).hasError('pattern'))
            {
                return `${fieldName} is in incorrect format. It should be in numbers only.`
            }
        }
        if (control == 'national_id_or_iqama' && this.Form.get(control).invalid)
        {
            if (this.Form.get(control).hasError('minlength') || this.Form.get(control).hasError('maxlength'))
            {
                return `${fieldName} should be equal to 10 digits only.`
            }
            if(this.Form.get(control).hasError('pattern'))
            {
                return `${fieldName} is in incorrect format. It should be in numbers only.`
            }
        }
        
    }

    getGroups()
    {
        let url = `funds_groups`;
        this.mainService.postData(url, {}).then(response => {
            if (response.statusCode == 200)
            {
                let dec = this.cryptoService.decryption(response.data);
                this.groups = dec.funds_groups;
                // console.log('getGroups', this.groups);
            }
            else
            {

            }
        })
    }

    setMode(control, mode, focusOut?) {
        this.editModes[control] = mode;
        if (focusOut && !this.salary) {
            this.salary = 0;
        }
    }
}
